import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultDropdownData } from "../../modules/Demand/components/Demand-Form";
import {
  cancelDemandFormService,
  closeDemandByRmgService,
  convertDemandFormService,
  createJdUsingAIService,
  demandOnHoldOrReleaseService,
  getActiveProposalsService,
  getCancellationReasonsService,
  getDemandCreationDataService,
  getDemandHistoryService,
  getEmployeeForProposalService,
  getExternalFulfillmentService,
  getProposalHistoryService,
  getRejectionReasonsService,
  getRmgReasonsService,
  initialSaveSubmitDemandCreationFormService,
  proposeSelectedEmployeeService,
  saveSubmitDemandCreationFormService,
  updateActiveProposalStatusService,
} from "../../services/demand";
import { bulkDemandApproveOrRejectService } from "../../services/open-demand";
import { ReduxApiService } from "../../views/enums";
import { IDemandState } from "../../views/interfaces";

const initialState: IDemandState = {
  loading: 0,
  dropdownData: defaultDropdownData,
  saveDemandData: null,
  formSubmitted: false,
  closeDemandDrawer: false,
  demandApproveOrReject: false,
  activeProposals: [],
  proposalList: [],
  demandHistory: [],
  proposalHistory: [],
  rejectionReasonList: [],
  cancellationReasonList: [],
  externalFulfillment: [],
  jobDescription: "",
  rmgReasonList: [],
  openDemandPermission: {
    "f-cancel-demand": { read: false, write: false },
    "f-close-demand-by-rmg": { read: false, write: false },
    "f-edit-save-demand": { read: false, write: false },
    "f-hold-demand": { read: false, write: false },
    "f-release-hold-demand": { read: false, write: false },
    "f-create-open-demand": { read: false, write: false },
    "f-approve-demand": { read: false, write: false },
    "f-reject-demand": { read: false, write: false },
  },
};

export const getDemandCreationData = createAsyncThunk(
  ReduxApiService.getDemandCreationData,
  async (params: any) => {
    const result = await getDemandCreationDataService(params);

    return result;
  }
);

export const convertDemandForm = createAsyncThunk(
  ReduxApiService.convertDemandForm,
  async (body: any) => {
    const result = await convertDemandFormService(body);
    return result;
  }
);

export const cancelDemandForm = createAsyncThunk(
  ReduxApiService.cancelDemandForm,
  async (body: any) => {
    const result = await cancelDemandFormService(body);
    return result;
  }
);
export const closeDemandByRmg = createAsyncThunk(
  ReduxApiService.closeByRmg,
  async (body: any) => {
    const result = await closeDemandByRmgService(body);
    return result;
  }
);

export const saveSubmitDemandCreationForm = createAsyncThunk(
  ReduxApiService.saveSubmitDemandCreationForm,
  async (body: any) => {
    if (body.meta.initiateSaveOrSubmit) {
      return await initialSaveSubmitDemandCreationFormService(body);
    } else {
      return await saveSubmitDemandCreationFormService(body);
    }
  }
);

export const createJdUsingAI = createAsyncThunk(
  ReduxApiService.createJdUsingAI,
  async (body: any) => {
    const result = await createJdUsingAIService(body);
    return result;
  }
);

export const getDemandHistory = createAsyncThunk(
  ReduxApiService.getDemandHistory,
  async (params: any) => {
    const result = await getDemandHistoryService(params);
    return result;
  }
);

export const getActiveProposals = createAsyncThunk(
  ReduxApiService.getActiveProposals,
  async (params: any) => {
    const result = await getActiveProposalsService(params);
    return result;
  }
);

export const getProposalHistory = createAsyncThunk(
  ReduxApiService.getProposalHistory,
  async (params: any) => {
    const result = await getProposalHistoryService(params);
    return result;
  }
);

export const getRejectionReasons = createAsyncThunk(
  ReduxApiService.getRejectionReasons,
  async () => {
    const result = await getRejectionReasonsService();
    return result;
  }
);

export const getCancellationReasons = createAsyncThunk(
  ReduxApiService.getCancellationReasons,
  async () => {
    const result = await getCancellationReasonsService();
    return result;
  }
);
export const getRmgReasons = createAsyncThunk(
  ReduxApiService.getRmgReasons,
  async () => {
    const result = await getRmgReasonsService();
    return result;
  }
);

export const getEmployeeForProposal = createAsyncThunk(
  ReduxApiService.getEmployeeForProposal,
  async (params: any) => {
    const result = await getEmployeeForProposalService(params);
    return result;
  }
);

export const proposeSelectedEmployee = createAsyncThunk(
  ReduxApiService.proposeSelectedEmployee,
  async (body: any) => {
    const result = await proposeSelectedEmployeeService(body);
    return result;
  }
);

export const updateActiveProposalStatus = createAsyncThunk(
  ReduxApiService.updateActiveProposalStatus,
  async (body: any) => {
    const result = await updateActiveProposalStatusService(body);
    return result;
  }
);

export const getExternalFulfillment = createAsyncThunk(
  ReduxApiService.getExternalFulfillment,
  async (body: any) => {
    const result = await getExternalFulfillmentService(body);
    return result;
  }
);

export const demandOnHoldOrRelease = createAsyncThunk(
  ReduxApiService.onHoldOrRelease,
  async (request: { body: any }) => {
    const result = await demandOnHoldOrReleaseService(request.body);
    return result;
  }
);

export const demandApproveOrReject = createAsyncThunk(
  ReduxApiService.demandApproveOrReject,
  async (request: { body: any }) => {
    const result = await bulkDemandApproveOrRejectService(request.body);
    return result;
  }
);

const demandSlice = createSlice({
  name: "draftDemand",
  initialState,
  reducers: {
    setCloseDrawer(state, action) {
      state.closeDemandDrawer = action.payload;
    },
    resetCloseDrawer(state) {
      state.closeDemandDrawer = initialState.closeDemandDrawer;
    },
    resetFormSubmitted(state) {
      state.formSubmitted = initialState.formSubmitted;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDemandCreationData.pending, (state, action) => {
        state.loading += 1;
        state.dropdownData = initialState.dropdownData;
        state.saveDemandData = initialState.saveDemandData;
        state.closeDemandDrawer = initialState.closeDemandDrawer;
        state.openDemandPermission = initialState.openDemandPermission;
      })
      .addCase(getDemandCreationData.rejected, (state, action) => {
        state.loading -= 1;
        state.dropdownData = initialState.dropdownData;
        state.saveDemandData = initialState.saveDemandData;
        state.closeDemandDrawer = true;
        state.openDemandPermission = initialState.openDemandPermission;
      })
      .addCase(getDemandCreationData.fulfilled, (state, action) => {
        state.loading -= 1;
        state.dropdownData = action.payload
          ? action.payload.data.dropdown
          : initialState.dropdownData;
        state.saveDemandData = action.payload
          ? action.payload.data.savedDemand
          : null;
        state.closeDemandDrawer = initialState.closeDemandDrawer;
        state.openDemandPermission = action.payload.permission
          ? {
              ...initialState.openDemandPermission,
              ...action.payload.permission,
            }
          : initialState.openDemandPermission;
      })
      .addCase(saveSubmitDemandCreationForm.pending, (state, action) => {
        state.loading += 1;
        state.formSubmitted = initialState.formSubmitted;
      })
      .addCase(saveSubmitDemandCreationForm.rejected, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = initialState.formSubmitted;
      })
      .addCase(saveSubmitDemandCreationForm.fulfilled, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = true;
        state.closeDemandDrawer = true;
      })
      .addCase(getDemandHistory.pending, (state, action) => {
        state.loading += 1;
        state.demandHistory = initialState.demandHistory;
      })
      .addCase(getDemandHistory.rejected, (state, action) => {
        state.loading -= 1;
        state.demandHistory = initialState.demandHistory;
      })
      .addCase(getDemandHistory.fulfilled, (state, action) => {
        state.loading -= 1;
        state.demandHistory = action.payload;
      })
      .addCase(getActiveProposals.pending, (state, action) => {
        state.loading += 1;
        state.activeProposals = initialState.activeProposals;
      })
      .addCase(getActiveProposals.rejected, (state, action) => {
        state.loading -= 1;
        state.activeProposals = initialState.activeProposals;
      })
      .addCase(getActiveProposals.fulfilled, (state, action) => {
        state.loading -= 1;
        state.activeProposals = action.payload;
      })
      .addCase(getEmployeeForProposal.pending, (state, action) => {
        state.loading += 1;
        state.proposalList = initialState.proposalList;
      })
      .addCase(getEmployeeForProposal.rejected, (state, action) => {
        state.loading -= 1;
        state.proposalList = initialState.proposalList;
      })
      .addCase(getEmployeeForProposal.fulfilled, (state, action) => {
        state.loading -= 1;
        state.proposalList = action.payload;
      })
      .addCase(proposeSelectedEmployee.pending, (state, action) => {
        state.loading += 1;
        state.formSubmitted = initialState.formSubmitted;
      })
      .addCase(proposeSelectedEmployee.rejected, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = initialState.formSubmitted;
      })
      .addCase(proposeSelectedEmployee.fulfilled, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = true;
      })
      .addCase(updateActiveProposalStatus.pending, (state, action) => {
        state.loading += 1;
        state.formSubmitted = initialState.formSubmitted;
      })
      .addCase(updateActiveProposalStatus.rejected, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = initialState.formSubmitted;
      })
      .addCase(updateActiveProposalStatus.fulfilled, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = true;
      })
      .addCase(convertDemandForm.pending, (state, action) => {
        state.loading += 1;
        state.formSubmitted = initialState.formSubmitted;
      })
      .addCase(convertDemandForm.rejected, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = initialState.formSubmitted;
      })
      .addCase(convertDemandForm.fulfilled, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = true;
        state.closeDemandDrawer = true;
      })
      .addCase(cancelDemandForm.pending, (state, action) => {
        state.loading += 1;
        state.formSubmitted = initialState.formSubmitted;
        state.closeDemandDrawer = initialState.closeDemandDrawer;
      })
      .addCase(cancelDemandForm.rejected, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = initialState.formSubmitted;
        state.closeDemandDrawer = initialState.closeDemandDrawer;
      })
      .addCase(cancelDemandForm.fulfilled, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = true;
        state.closeDemandDrawer = true;
      })
      .addCase(getProposalHistory.pending, (state, action) => {
        state.loading += 1;
        state.proposalHistory = initialState.proposalHistory;
      })
      .addCase(getProposalHistory.rejected, (state, action) => {
        state.loading -= 1;
        state.proposalHistory = initialState.proposalHistory;
      })
      .addCase(getProposalHistory.fulfilled, (state, action) => {
        state.loading -= 1;
        state.proposalHistory = action.payload;
      })
      .addCase(getRejectionReasons.pending, (state, action) => {
        state.loading += 1;
        state.rejectionReasonList = initialState.rejectionReasonList;
      })
      .addCase(getRejectionReasons.rejected, (state, action) => {
        state.loading -= 1;
        state.rejectionReasonList = initialState.rejectionReasonList;
      })
      .addCase(getRejectionReasons.fulfilled, (state, action) => {
        state.loading -= 1;
        state.rejectionReasonList = action.payload;
      })
      .addCase(getCancellationReasons.pending, (state, action) => {
        state.loading += 1;
        state.cancellationReasonList = initialState.cancellationReasonList;
      })
      .addCase(getCancellationReasons.rejected, (state, action) => {
        state.loading -= 1;
        state.cancellationReasonList = initialState.cancellationReasonList;
      })
      .addCase(getCancellationReasons.fulfilled, (state, action) => {
        state.loading -= 1;
        state.cancellationReasonList = action.payload.reasons;
      })
      .addCase(getRmgReasons.pending, (state, action) => {
        state.loading += 1;
        state.rmgReasonList = initialState.rmgReasonList;
      })
      .addCase(getRmgReasons.rejected, (state, action) => {
        state.loading -= 1;
        state.rmgReasonList = initialState.rmgReasonList;
      })
      .addCase(getRmgReasons.fulfilled, (state, action) => {
        state.loading -= 1;
        state.rmgReasonList = action.payload;
      })
      .addCase(getExternalFulfillment.pending, (state, action) => {
        state.loading += 1;
        state.externalFulfillment = initialState.externalFulfillment;
      })
      .addCase(getExternalFulfillment.rejected, (state, action) => {
        state.loading -= 1;
        state.externalFulfillment = initialState.externalFulfillment;
      })
      .addCase(getExternalFulfillment.fulfilled, (state, action) => {
        state.loading -= 1;
        state.externalFulfillment = action.payload;
      })
      .addCase(createJdUsingAI.pending, (state, action) => {
        state.loading += 1;
        state.jobDescription = initialState.jobDescription;
      })
      .addCase(createJdUsingAI.rejected, (state, action) => {
        state.loading -= 1;
        state.jobDescription = initialState.jobDescription;
      })
      .addCase(createJdUsingAI.fulfilled, (state, action) => {
        state.loading -= 1;
        state.jobDescription = action.payload;
      })
      .addCase(closeDemandByRmg.pending, (state, action) => {
        state.loading += 1;
        state.formSubmitted = initialState.formSubmitted;
        state.closeDemandDrawer = initialState.closeDemandDrawer;
      })
      .addCase(closeDemandByRmg.rejected, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = initialState.formSubmitted;
        state.closeDemandDrawer = initialState.closeDemandDrawer;
      })
      .addCase(closeDemandByRmg.fulfilled, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = true;
        state.closeDemandDrawer = true;
      })
      .addCase(demandOnHoldOrRelease.pending, (state, action) => {
        state.loading += 1;
        state.formSubmitted = initialState.formSubmitted;
        state.closeDemandDrawer = initialState.closeDemandDrawer;
      })
      .addCase(demandOnHoldOrRelease.rejected, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = initialState.formSubmitted;
        state.closeDemandDrawer = initialState.closeDemandDrawer;
      })
      .addCase(demandOnHoldOrRelease.fulfilled, (state, action) => {
        state.loading -= 1;
        state.formSubmitted = true;
        state.closeDemandDrawer = true;
      })
      .addCase(demandApproveOrReject.pending, (state, action) => {
        state.loading += 1;
        state.demandApproveOrReject = initialState.demandApproveOrReject;
        state.closeDemandDrawer = initialState.closeDemandDrawer;
      })
      .addCase(demandApproveOrReject.rejected, (state, action) => {
        state.loading -= 1;
        state.demandApproveOrReject = initialState.demandApproveOrReject;
        state.closeDemandDrawer = initialState.closeDemandDrawer;
      })
      .addCase(demandApproveOrReject.fulfilled, (state, action) => {
        state.loading -= 1;
        state.demandApproveOrReject = true;
        state.closeDemandDrawer = true;
      });
  },
});

export const { setCloseDrawer, resetCloseDrawer, resetFormSubmitted } =
  demandSlice.actions;
export const demandReducer = demandSlice.reducer;
