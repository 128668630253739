import React from "react";

import { Popover as AntdPopover, PopoverProps as AntdPopoverProps } from "antd";
import { RenderFunction } from "antd/es/_util/getRenderPropValue";
import "./Popover.css";

export type { AntdPopoverProps };

export interface PopoverProps {
  content: AntdPopoverProps["content"] | RenderFunction;
  title?: AntdPopoverProps["title"];
  trigger?: AntdPopoverProps["trigger"];
  placement?: AntdPopoverProps["placement"];
  arrow?: AntdPopoverProps["arrow"];
  children?: React.ReactNode;
}

export const Popover: React.FC<PopoverProps> = ({
  content,
  title,
  trigger = "hover",
  placement = "right",
  arrow = { pointAtCenter: true },
  children,
}) => {
  return (
    <AntdPopover
      content={content}
      title={title}
      trigger={trigger}
      placement={placement}
      arrow={arrow}
    >
      {children}
    </AntdPopover>
  );
};

