import { ApiRoutesConfig, axiosInstance } from ".";

export const getDemandCreationDataService = async (params: any) => {
  try {
    const response = await axiosInstance.get(
      ApiRoutesConfig.getDemandCreationData,
      {
        params,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const convertDemandFormService = async (body: any) => {
  try {
    const response = await axiosInstance.put(
      ApiRoutesConfig.convertDemandForm,
      body
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const cancelDemandFormService = async (body: any) => {
  try {
    const response = await axiosInstance.put(
      ApiRoutesConfig.cancelDemandForm,
      body
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const closeDemandByRmgService = async (body: any) => {
  try {
    const response = await axiosInstance.put(ApiRoutesConfig.closeByRmg, body);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const initialSaveSubmitDemandCreationFormService = async (body: any) => {
  try {
    const response = await axiosInstance.post(
      ApiRoutesConfig.saveSubmitDemandCreationForm,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveSubmitDemandCreationFormService = async (body: any) => {
  try {
    const response = await axiosInstance.put(
      ApiRoutesConfig.saveSubmitDemandCreationForm,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDemandHistoryService = async (params: any) => {
  try {
    const response = await axiosInstance.get(ApiRoutesConfig.getDemandHistory, {
      params,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getActiveProposalsService = async (params: any) => {
  try {
    const response = await axiosInstance.get(
      ApiRoutesConfig.getActiveProposals,
      {
        params,
      }
    );
    return response.data.data.map((item: any) => {
      return {
        ...item,
        key: item.fulfillmentId.toString(),
      };
    });
  } catch (error) {
    throw error;
  }
};

export const getEmployeeForProposalService = async (params: any) => {
  try {
    const response = await axiosInstance.get(
      ApiRoutesConfig.getEmployeeForProposal,
      {
        params,
      }
    );
    return response.data.data.map((item: any, idx: number) => {
      return {
        ...item,
        key: (idx + 1).toString(),
      };
    });
  } catch (error) {
    throw error;
  }
};

export const proposeSelectedEmployeeService = async (body: any) => {
  try {
    const response = await axiosInstance.post(
      ApiRoutesConfig.proposeSelectedEmployee,
      body
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const updateActiveProposalStatusService = async (body: any) => {
  try {
    const response = await axiosInstance.put(
      ApiRoutesConfig.updateActiveProposalStatus,
      body
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getProposalHistoryService = async (params: any) => {
  try {
    const response = await axiosInstance.get(
      ApiRoutesConfig.getProposalHistory,
      {
        params,
      }
    );
    return response.data.data.map((item: any, idx: number) => {
      return {
        ...item,
        key: (idx + 1).toString(),
      };
    });
  } catch (error) {
    throw error;
  }
};

export const getRejectionReasonsService = async () => {
  try {
    const response = await axiosInstance.get(
      ApiRoutesConfig.getRejectionReasons
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getCancellationReasonsService = async () => {
  try {
    const response = await axiosInstance.get(
      ApiRoutesConfig.getCancellationReasons
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const getRmgReasonsService = async () => {
  try {
    const response = await axiosInstance.get(ApiRoutesConfig.getRmgReasons);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getExternalFulfillmentService = async (params: any) => {
  try {
    const response = await axiosInstance.get(
      ApiRoutesConfig.getExternalFulfillment,
      {
        params,
      }
    );
    return response.data.data.map((item: any, idx: number) => {
      return {
        ...item,
        key: (idx + 1).toString(),
      };
    });
  } catch (error) {
    throw error;
  }
};

export const createJdUsingAIService = async (body: any) => {
  try {
    const response = await axiosInstance.post(
      ApiRoutesConfig.createJdUsingAI,
      body
    );
    return response.data.jobDescription;
  } catch (error) {
    throw error;
  }
};

export const demandOnHoldOrReleaseService = async (body: any) => {
  try {
    const response = await axiosInstance.post(
      ApiRoutesConfig.onHoldOrRelease,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
