import { ApiRoutesConfig, axiosInstance } from ".";

export const getEmployeeProfileService = async () => {
  try {
    const response = await axiosInstance.get(
      ApiRoutesConfig.getEmployeeProfile
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
