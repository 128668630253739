import { ApiRoutesConfig, axiosInstance } from ".";
import { getFilterOptions } from "../utils";

export const getEmployeeTableDataService = async (body: any, params: any) => {
  try {
    const response = await axiosInstance.post(
      ApiRoutesConfig.getEmployeeTableData,
      body,
      {
        params,
      }
    );
    return {
      ...response.data.data,
      tableData: response.data.data.tableData.map((item: any) => {
        return {
          ...item,
          key: item.employee.id.toString(),
          children: item.children?.map((child: any, _idx: number) => {
            return {
              ...child,
              key: `${item.employee.id}-${_idx + 1}`,
            };
          }),
        };
      }),
    };
  } catch (error) {
    throw error;
  }
};

export const getEmployeeFilterDataService = async () => {
  try {
    const response = await axiosInstance.get(
      ApiRoutesConfig.getEmployeeFilterData
    );
    for (const [key, value] of Object.entries(response.data.data.filter)) {
      response.data.data.filter[key] = getFilterOptions(value);
    }
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const exportEmployeeTableDataService = async (
  body: any,
  params: any
) => {
  try {
    const response = await axiosInstance.post(
      ApiRoutesConfig.exportEmployeeTableData,
      body,
      {
        params,
        responseType: "blob",
      }
    );
    if (response && response.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `employees.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      return "success";
    }
    return "failed";
  } catch (error) {
    throw error;
  }
};
