import React, { CSSProperties, ReactNode } from "react";

import type { DatePickerProps as AntdDatePickerProps, GetProps } from "antd";
import { DatePicker as AntdDatePicker } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { SVGIcon } from "../../components";
import { DateFormat_DDMMMYYYY } from "../../views/constants";
import "./Date-Picker.css";

type RangePickerProps = GetProps<typeof AntdDatePicker.RangePicker>;
export interface DatePickerProps {
  placeholder?: string | string[];
  size?: SizeType;
  defaultValue?: any;
  minDate?: any;
  maxDate?: any;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
  value?: any;
  onChange?: AntdDatePickerProps["onChange"] | RangePickerProps["onChange"];

  popupStyle?: CSSProperties;
  suffixIcon?: ReactNode;
  format?: AntdDatePickerProps["format"];
  showRange?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  placeholder = "Date Picker",
  size = "large",
  defaultValue = undefined,
  minDate = undefined,
  maxDate = undefined,
  disabled = false,
  id,
  value,
  onChange,
  className,
  style = { width: "100%" },
  popupStyle,
  suffixIcon = <SVGIcon SVGElementIcon={CalendarIcon} />,
  format = DateFormat_DDMMMYYYY,
  showRange = false,
}) => {
  const { RangePicker } = AntdDatePicker;
  return showRange ? (
    <RangePicker
      id={id}
      value={value}
      className={className}
      onChange={onChange as RangePickerProps["onChange"]}
      placeholder={placeholder as [string, string]}
      size={size}
      defaultValue={defaultValue}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      style={style}
      format={format}
      popupStyle={popupStyle}
      suffixIcon={disabled ? <></> : suffixIcon}
    />
  ) : (
    <AntdDatePicker
      id={id}
      value={value}
      className={className}
      placeholder={placeholder as string}
      onChange={onChange as AntdDatePickerProps["onChange"]}
      size={size}
      defaultValue={defaultValue}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      style={style}
      format={format}
      popupStyle={popupStyle}
      suffixIcon={disabled ? <></> : suffixIcon}
    />
  );
};
