import React, { CSSProperties, ReactNode, useId } from "react";

import { SizeType } from "antd/es/config-provider/SizeContext";
import AntdSelect from "antd/es/select";
import { ReactComponent as DownIconComponent } from "../../assets/down.svg";
import { ReactComponent as SearchIconComponent } from "../../assets/search.svg";
import { ILabelValue } from "../../views/interfaces";
import { Checkbox } from "../Checkbox";
import { Tooltip } from "../Tooltip";
import "./Select.css";

export interface SelectProps {
  placeholder: ReactNode | string;
  options: ILabelValue[];
  defaultValue?: any;
  disabled?: boolean;
  size?: SizeType;
  multiple?: boolean;
  allowClear?: boolean;
  className?: string;
  id?: string;
  style?: CSSProperties;
  optionRender?: "select" | "checkbox";
  showSearch?: boolean;
  value?: any;
  onChange?: (value: any, option: any) => void;
  labelInValue?: boolean;
  maxTagCount?: number | "responsive";
}

export const Select: React.FC<SelectProps> = ({
  placeholder = "Select",
  options = [],
  defaultValue = null,
  disabled = false,
  size = "large",
  multiple = false,
  allowClear = false,
  id,
  className,
  style,
  optionRender = "select",
  showSearch = true,
  value,
  onChange,
  labelInValue = true,
  maxTagCount = "responsive",
}) => {
  const selectId = useId();
  const [fieldClicked, setFieldClicked] = React.useState<boolean>(false);

  const onChangeAction = (value: any, option: ILabelValue | ILabelValue[]) => {
    onChange?.(value, option);
  };

  const onSelect = (val: string) => {};

  const onDeselect = (val: string) => {};

  const onSearch = (value: string) => {};

  const filterOption = (input: string, option: any) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onCheckboxChange = (label: any, value: string, checked: boolean) => {};

  return (
    <AntdSelect
      labelInValue={labelInValue}
      id={id ?? selectId}
      showSearch={showSearch}
      mode={multiple ? "multiple" : undefined}
      placeholder={placeholder}
      onSearch={onSearch}
      onSelect={onSelect}
      onClick={() => setFieldClicked(!fieldClicked)}
      onBlur={() => setFieldClicked(false)}
      options={options}
      optionFilterProp="children"
      filterOption={filterOption}
      defaultValue={defaultValue}
      disabled={disabled}
      size={size}
      allowClear={allowClear}
      className={className}
      style={style}
      maxTagCount={maxTagCount}
      value={value}
      onChange={onChangeAction}
      maxTagPlaceholder={(omittedValues) => (
        <Tooltip title={omittedValues.map(({ label }) => label).join(", ")}>
          <span>{omittedValues.length} +</span>
        </Tooltip>
      )}
      suffixIcon={
        disabled ? (
          <></>
        ) : fieldClicked ? (
          <SearchIconComponent />
        ) : (
          <DownIconComponent />
        )
      }
      optionRender={
        optionRender == "checkbox"
          ? (option) => (
              <Checkbox
                label={option.label as string}
                value={option.value as string}
                checked={value?.includes(option.value)}
                action={onCheckboxChange}
              />
            )
          : undefined
      }
    />
  );
};
