import React from "react";
import { Button } from "../../lib";
import {
  EDemandSubStatusExternal,
  EDemandSubStatusInternal,
  EFulFillmentStatus,
} from "../../views/enums";
import "./Confirmation.css";

export interface ConfirmationProps {
  data?: any;
  setModalOpen: any;
  openCancel: any;
}
export const Confirmation: React.FC<ConfirmationProps> = ({
  data,
  setModalOpen,
  openCancel,
}: ConfirmationProps) => {
  const details = {
    clientInterview:
      "This demand has active proposals in Client Interview stage. Are you sure, you want to cancel?",
    riw: "This demand has identified candidates in RIW. On cancellation, the candidates will be released from RIW. Are you sure, you want to cancel?",
    external:
      "This demand  has been identified for External fulfillment. On cancellation, the external requisition will be closed.",
    activeProposal:
      "All progress made in this demand along with any active proposal will be lost. Are you sure, you want to cancel?",
  };

  const { fulfillmentType, subStatus } = data.demandStatus;

  const getContent = () => {
    if (
      fulfillmentType == EFulFillmentStatus.Internal &&
      subStatus == EDemandSubStatusInternal.Client_interview
    ) {
      return details.clientInterview;
    } else if (
      fulfillmentType == EFulFillmentStatus.Internal &&
      subStatus == EDemandSubStatusInternal.Pending_RIW
    ) {
      return details.riw;
    } else if (fulfillmentType == EFulFillmentStatus.External) {
      return details.external;
    } else if (
      fulfillmentType == EFulFillmentStatus.External &&
      subStatus == EDemandSubStatusExternal.Pending_Approval
    ) {
      return details.activeProposal;
    }
    return "";
  };
  return (
    <div className="confirmation-container">
      <div className="confirmation-details">
        <p>{getContent()}</p>
      </div>
      <div className="confirmation-footer">
        <Button onClick={() => setModalOpen(false)}>No,Keep it Active</Button>
        <Button type="primary" onClick={() => openCancel(true)}>
          Yes, Cancel it
        </Button>
      </div>
    </div>
  );
};
