import React, { Fragment, useMemo, useState } from 'react';

import { Flex } from 'antd';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { Button, Form, Input } from '../../lib';
import { getCareerCompassProfileSummary } from '../../redux/slice/career-path';
import './Profile-Summary.css';

export interface ProfileSummaryProps {
  onClose: () => void;
  defaultValue?: string;
}

export const ProfileSummary: React.FC<ProfileSummaryProps> = ({
  onClose,
  defaultValue = '',
}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const keyPoints = useMemo(() => {
    return [
      'What story from your past shaped who you are today?',
      'How do you define success, and what does it look like for you?',
      'What’s a unique skill or perspective you bring to your work?',
      'In what ways do you hope to make an impact in your current role?',
      'What motivates you to get out of bed and create each day?',
      'Share a fun or surprising fact about yourself that sparks curiosity.',
    ];
  }, []);

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const handleSubmit = () => {
    form
      .validateFields({ validateOnly: true })
      .then((values) => {
        const payload = {
          profile_summary: values.summary,
        };
        dispatch(getCareerCompassProfileSummary(payload));
        setDisableSubmit(false);
      })
      .catch((errorInfo) => {
        setDisableSubmit(false);
      });
    onClose();
  };
  const handleCancel = () => {
    form.resetFields();
    onClose();
  };
  const handleFormChange = (allValues: any) => {
    setDisableSubmit(!allValues.summary);
  };
  return (
    <Fragment>
      <div className="edit-summary-container">
        <p className="text-wrapper-1">
          Write about your experience, skills, and career goals. It's your
          chance to tell your professional story, highlighting your unique
          strengths and accomplishments.
        </p>
        <Form
          form={form}
          onValuesChange={handleFormChange}
          initialValues={{ summary: defaultValue }}
        >
          <div className="edit-summary">
            <Form.Item
              name="summary"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input
                htmlType="textarea"
                style={{
                  borderColor: '#E6E7E8',
                  height: '150px',
                  resize: 'none',
                }}
                value={defaultValue}
                placeholder="Keep it concise (2-3 short paragraphs)."
                showCount={true}
                max={500}
              />
            </Form.Item>
          </div>

          <div className="edit-summary-footer">
            <Flex gap={16}>
              <Form.Item>
                <Button style={{ marginLeft: 8 }} onClick={handleCancel}>
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  disabled={disableSubmit}
                >
                  Submit
                </Button>
              </Form.Item>
            </Flex>
          </div>
        </Form>
        <div className="enhance-profile-container">
          <p className="text-wrapper-2">
            Here are some questions to enhance your profile:
          </p>
          <ul>
            {keyPoints.map((text: string) => (
              <li className="text-wrapper-3">{text}</li>
            ))}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};
