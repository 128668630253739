import React, { Fragment, FunctionComponent } from "react";

import { Avatar } from "../../lib";
import { SVGIcon } from "../SVG-Icon";
import "./Sidebar-Icon.css";

export interface SidebarIconProps {
  isSvgIcon: boolean;
  isActive: boolean;
  SVGIconElement?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  src?: string;
  avatarInitials?: string;
}

export const SidebarIcon: React.FC<SidebarIconProps> = ({
  isSvgIcon = true,
  isActive = false,
  SVGIconElement = undefined,
  src = undefined,
  avatarInitials = "",
}) => {
  return (
    <Fragment>
      {isSvgIcon ? (
        <SVGIcon
          SVGElementIcon={SVGIconElement}
          active={isActive}
          color="#929497"
          colorActive="#fe5000"
        />
      ) : src ? (
        <Avatar size={"small"} src={<img src={src} alt="avatar" />}></Avatar>
      ) : (
        <Avatar size={"small"}>{avatarInitials}</Avatar>
      )}
    </Fragment>
  );
};
