import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getActedDemandCardMappingService,
  getAllocationWithIn60DaysCardMappingService,
  getBanchCardMappingService,
  getCardDataService,
  getDraftDemandCardMappingService,
  getFilterDataService,
  getInternalActionCardMappingService,
  getNotActedDemandCardMappingService,
  getPortfolioCardMappingService,
  getUiFilterItemsService,
} from "../../services/dashboard";
import { ReduxApiService } from "../../views/enums";
import {
  IDashboardCard,
  IDashboardFilter,
  IDashboardState,
} from "../../views/interfaces";

export const defaultFilterData: IDashboardFilter = {
  account: [],
  vertical: [],
  practice: [],
};

export const defaultCardData: IDashboardCard[] = [
  getNotActedDemandCardMappingService({}, {}),
  getActedDemandCardMappingService({}, {}),
  getBanchCardMappingService({}, {}),
  getAllocationWithIn60DaysCardMappingService({}, {}),
  getDraftDemandCardMappingService({}, {}),
  getInternalActionCardMappingService({}, {}),
  getPortfolioCardMappingService({}, {}),
];

const initialState: IDashboardState = {
  loading: 0,
  filterConfig: [],
  filterData: defaultFilterData,
  cardData: defaultCardData,
};

export const getFilterData = createAsyncThunk(
  ReduxApiService.getFilterData,
  async () => {
    const result = await getFilterDataService();
    return result;
  }
);

export const getUiFilterItems = createAsyncThunk(
  ReduxApiService.getUiFilterItems,
  async (params: any) => {
    const result = await getUiFilterItemsService(params);
    return result;
  }
);

export const getCardData = createAsyncThunk(
  ReduxApiService.getCardData,
  async (args: { body: any; params: any }) => {
    const result = await getCardDataService(args.body, args.params);
    return result;
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFilterData.pending, (state, action) => {
        state.loading += 1;
        state.filterData = initialState.filterData;
      })
      .addCase(getFilterData.rejected, (state, action) => {
        state.loading -= 1;
        state.filterData = initialState.filterData;
      })
      .addCase(getFilterData.fulfilled, (state, action) => {
        state.loading -= 1;
        state.filterData = action.payload?.data;
      })
      .addCase(getCardData.pending, (state, action) => {
        state.loading += 1;
        state.cardData = initialState.cardData;
      })
      .addCase(getCardData.rejected, (state, action) => {
        state.loading -= 1;
        state.cardData = initialState.cardData;
      })
      .addCase(getCardData.fulfilled, (state, action) => {
        state.loading -= 1;
        state.cardData = action.payload
          ? (action.payload as IDashboardCard[])
          : initialState.cardData;
      })
      .addCase(getUiFilterItems.pending, (state, action) => {
        state.loading += 1;
        state.filterConfig = initialState.filterConfig;
      })
      .addCase(getUiFilterItems.rejected, (state, action) => {
        state.loading -= 1;
        state.filterConfig = initialState.filterConfig;
      })
      .addCase(getUiFilterItems.fulfilled, (state, action) => {
        state.loading -= 1;
        state.filterConfig = action.payload;
      });
  },
});

export const dashboardReducer = dashboardSlice.reducer;
