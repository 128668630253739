import React, { useEffect, useState } from "react";

import { ReactComponent as BookmarkComponent } from "../../../../assets/bookmark.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/search.svg";
import { SVGIcon } from "../../../../components/SVG-Icon";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import { Button, Checkbox, SearchBar } from "../../../../lib";
import { getEmployeeForProposal } from "../../../../redux/slice/demand";
import { IProposalEmployeeList } from "../../../../views/interfaces";
import { ProposeCandidateItem } from "../Propose-Candidate-Item";
import "./Propose-Candidate.css";

export interface ProposeCandidateProps {
  alreadyProposed: IProposalEmployeeList[];
  onClose?: any;
  maxProposeCandidates?: number;
}

export const ProposeCandidate: React.FC<ProposeCandidateProps> = ({
  alreadyProposed,
  onClose,
  maxProposeCandidates = 3,
}) => {
  const dispatch = useAppDispatch();
  const { proposalList, activeProposals } = useAppSelector(
    (state) => state.demand
  );
  const [searchString, setSearchString] = useState<string | null>(null);
  const [selectedEmployee, setSelectedEmployee] =
    useState<IProposalEmployeeList[]>(alreadyProposed);

  useEffect(() => {
    dispatch(getEmployeeForProposal({ q: searchString }));
  }, [searchString, dispatch]);

  const closeComponent = (state: boolean = false) => {
    if (state) {
      onClose(selectedEmployee);
    } else {
      setSelectedEmployee([]);
      onClose([]);
    }
  };

  const notActiveProposalList = proposalList.filter(
    (item) =>
      !activeProposals
        .map((val) => val.candidateNameLevelRole.internalId)
        .includes(item.internalId)
  );

  const onToggleCheckbox = (label: any, value: string, checked: boolean) => {
    setSelectedEmployee((prev: IProposalEmployeeList[]) => {
      if (checked) {
        const employee = proposalList.find(
          (emp) => emp.employeeId == parseInt(value, 10)
        );
        return employee ? [...prev, employee] : [...prev];
      } else {
        return prev.filter((emp) => emp.employeeId != parseInt(value, 10));
      }
    });
  };

  return (
    <div className="propose-candidate">
      <SearchBar
        placeHolder="Search Employees"
        property="default"
        size="large"
        rootStyle={{ width: "100%" }}
        id="proposed-candidate-employee-input"
        rightIcon={SearchIcon}
        setSearchString={setSearchString}
      />
      <div className="proposed-candidate-content">
        <div className="proposed-candidate-header">
          You can propose maximum of 3 employees for a demand fulfillment.
        </div>
        <div className="proposed-candidate-list">
          {notActiveProposalList.map((employee, idx) => (
            <Checkbox
              key={idx}
              label={<ProposeCandidateItem {...(employee as any)} />}
              value={employee.employeeId.toString()}
              checked={
                selectedEmployee.findIndex(
                  (emp) => emp.employeeId === employee.employeeId
                ) >= 0
              }
              disabled={
                selectedEmployee.length >= maxProposeCandidates &&
                selectedEmployee.findIndex(
                  (emp) => emp.employeeId === employee.employeeId
                ) === -1
              }
              action={onToggleCheckbox}
            />
          ))}
        </div>
        <div className="proposed-candidate-footer">
          <SVGIcon
            SVGElementIcon={BookmarkComponent}
            height={15}
            width={10}
            active={true}
          />
          <span>Propose for open demands</span>
        </div>
      </div>
      <div className="proposed-candidate-action">
        <Button
          htmlType="button"
          style={{ width: "388px" }}
          onClick={() => closeComponent(false)}
        >
          Close
        </Button>
        <Button
          htmlType="button"
          type="primary"
          style={{ width: "388px" }}
          disabled={selectedEmployee.length === 0}
          onClick={() => closeComponent(true)}
        >
          Add
        </Button>
      </div>
    </div>
  );
};
