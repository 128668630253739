import { ApiRoutesConfig, axiosCareerCompassInstance } from '.';
import { ILmsCourses } from '../views/interfaces';

export const getLmsCoursesService = async (params: any) => {
  try {
    const response = await axiosCareerCompassInstance.get(
      ApiRoutesConfig.getLmsCourses,
      { params }
    );
    const parser = new DOMParser();
    return response.data.data.map((item: ILmsCourses) => {
      return {
        ...item,
        imageUrl: item.imageUrl
          ? parser.parseFromString(item.imageUrl, 'text/html').body.innerHTML
          : '',
      };
    });
  } catch (error) {
    throw error;
  }
};
