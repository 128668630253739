import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getReportDataService } from "../../services/reports";
import { ReduxApiService } from "../../views/enums";
import { IReportState } from "../../views/interfaces";

const initialState: IReportState = {
  loading: 0,
  reportStatus: false,
};

export const downloadReportData = createAsyncThunk(
  ReduxApiService.openDemandReport,
  async (url: string) => {
    const result = await getReportDataService(url);
    return result;
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadReportData.pending, (state, action) => {
        state.loading += 1;
      })
      .addCase(downloadReportData.rejected, (state, action) => {
        state.loading -= 1;
      })
      .addCase(downloadReportData.fulfilled, (state, action) => {
        state.loading -= 1;
      });
  },
});

export const reportReducer = reportSlice.reducer;
