import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  bulkDemandApproveOrRejectService,
  bulkFulfillmentUpdateService,
  exportOpenTableDataService,
  getOpenTableDataService,
} from "../../services/open-demand";
import { IOpenDemandFilter, IOpenDemandState } from "../../views/interfaces";
import { ReduxApiService } from "./../../views/enums";

export const defaultFilterData: IOpenDemandFilter = {
  account: [],
  vertical: [],
  practice: [],
  projectOpportunity: [],
  location: [],
  legalEntity: [],
  specialization: [],
  demandStatus: [],
  supplyRisk: [],
  level: [],
  role: [],
};

const initialState: IOpenDemandState = {
  loading: 0,
  filterData: defaultFilterData,
  sortData: [],
  tableData: [],
  totalCount: 0,
  bulkUpdateActionStatus: false,
  bulkDemandApproveOrRejectStatus: false,
  reloadOpenDemand: false,
};

export const getOpenTableData = createAsyncThunk(
  ReduxApiService.getOpenTableData,
  async (request: { body: any; params: any }, thunkAPI) => {
    const result = await getOpenTableDataService(request.body, request.params);
    return result;
  }
);

export const exportOpenTableData = createAsyncThunk(
  ReduxApiService.exportOpenTableData,
  async (request: { body: any; params?: any }) => {
    const result = await exportOpenTableDataService(
      request.body,
      request.params
    );
    return result;
  }
);

export const bulkDemandApproveOrReject = createAsyncThunk(
  ReduxApiService.demandApproveOrReject,
  async (request: { body: any }) => {
    const result = await bulkDemandApproveOrRejectService(request.body);
    return result;
  }
);

export const bulkFulfillmentUpdate = createAsyncThunk(
  ReduxApiService.bulkFulfillmentUpdate,
  async (request: { body: any }) => {
    const result = await bulkFulfillmentUpdateService(request.body);
    return result;
  }
);

const openDemandSlice = createSlice({
  name: "openDemand",
  initialState,
  reducers: {
    resetBulkUpdateActionStatus(state) {
      state.bulkUpdateActionStatus = initialState.bulkUpdateActionStatus;
    },
    resetbulkDemandApproveOrRejectStatus(state) {
      state.bulkDemandApproveOrRejectStatus =
        initialState.bulkDemandApproveOrRejectStatus;
    },
    setReloadOpenDemand(state, action) {
      state.reloadOpenDemand = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOpenTableData.pending, (state, action) => {
        state.loading += 1;
        state.tableData = initialState.tableData;
        state.totalCount = initialState.totalCount;
        state.filterData = initialState.filterData;
        state.sortData = initialState.sortData;
      })
      .addCase(getOpenTableData.rejected, (state, action) => {
        state.loading -= 1;
        state.tableData = initialState.tableData;
        state.totalCount = initialState.totalCount;
        state.filterData = initialState.filterData;
        state.sortData = initialState.sortData;
      })
      .addCase(getOpenTableData.fulfilled, (state, action) => {
        state.loading -= 1;
        state.tableData = action.payload.tableData;
        state.totalCount = action.payload.totalCount;
        state.filterData = action.payload.filter;
        state.sortData = action.payload.sort;
      })
      .addCase(bulkFulfillmentUpdate.pending, (state, action) => {
        state.loading += 1;
        state.bulkUpdateActionStatus = initialState.bulkUpdateActionStatus;
      })
      .addCase(bulkFulfillmentUpdate.rejected, (state, action) => {
        state.loading -= 1;
        state.bulkUpdateActionStatus = initialState.bulkUpdateActionStatus;
      })
      .addCase(bulkFulfillmentUpdate.fulfilled, (state, action) => {
        state.loading -= 1;
        state.bulkUpdateActionStatus = true;
      })
      .addCase(bulkDemandApproveOrReject.pending, (state, action) => {
        state.loading += 1;
        state.bulkDemandApproveOrRejectStatus =
          initialState.bulkDemandApproveOrRejectStatus;
      })
      .addCase(bulkDemandApproveOrReject.rejected, (state, action) => {
        state.loading -= 1;
        state.bulkDemandApproveOrRejectStatus =
          initialState.bulkDemandApproveOrRejectStatus;
      })
      .addCase(bulkDemandApproveOrReject.fulfilled, (state, action) => {
        state.loading -= 1;
        state.bulkDemandApproveOrRejectStatus = true;
      });
  },
});

export const {
  resetBulkUpdateActionStatus,
  resetbulkDemandApproveOrRejectStatus,
  setReloadOpenDemand,
} = openDemandSlice.actions;
export const openDemandReducer = openDemandSlice.reducer;
