import { setNotification } from "../redux/slice/app";
import {
  EFulFillmentStatus,
  EProposalStatus,
  ReduxApiService,
} from "../views/enums";
import { INotify, TAlert } from "../views/interfaces";

export const toastNotificationList: {
  functionName: string;
  message: any;
  description: any;
}[] = [
  {
    functionName: ReduxApiService.saveSubmitDemandCreationForm,
    message: {
      submit: "Demand Creation Successful",
      save: "Demand Modified",
      fulfillment: "Fulfillment Type Identified",
    },
    description: {
      // "Demand [#id] has been successfully created and submitted for RM's action.",
      // "The following demands have been successfully created and submitted for RM’s action.",
      submit: [
        "The fulfillment type for demand [#id] has been identified as External",
        "The following demands have been successfully created and identified as External.",
      ],
      save: "Changes to demand [#id] have been saved successfully.",
      fulfillment: {
        external:
          "The following demands have been identified as [#fulfillmentType] and are pending Manager's approval.",
        internal:
          "The fulfillment type for demand [#id] has been identified as [#fulfillmentType].",
      },
    },
  },
  {
    functionName: ReduxApiService.convertDemandForm,
    message: "Fulfillment Type Identified",
    description: {
      external:
        "The following demands have been identified as [#fulfillmentType] and are pending Manager's approval.",
      internal:
        "The fulfillment type for demand [#id] has been identified as [#fulfillmentType].",
    },
  },
  {
    functionName: ReduxApiService.bulkFulfillmentUpdate,
    message: "Fulfillment Type Identified",
    description: {
      external:
        "Demand(s) have been identified for [#fulfillmentType] fulfillment and pending manager's approval.",
      internal:
        "Demand(s) have been identified for [#fulfillmentType] fulfillment.",
    },
  },
  {
    functionName: ReduxApiService.cancelDemandForm,
    message: "Demand Cancelled",
    description: "Demand [#id] has been successfully cancelled.",
  },
  {
    functionName: ReduxApiService.exportDraftTableData,
    message: "Draft Demand Report Downloaded",
    description: "The draft demand report has been successfully downloaded.",
  },
  {
    functionName: ReduxApiService.exportOpenTableData,
    message: "Open Demand Report Downloaded",
    description: "The open demand report has been successfully downloaded.",
  },
  {
    functionName: ReduxApiService.exportEmployeeTableData,
    message: "Employee Report Downloaded",
    description: "The employee report has been successfully downloaded.",
  },
  {
    functionName: ReduxApiService.proposeSelectedEmployee,
    message: "Candidate Proposal Successful",
    description:
      "The following candidate(s) have been proposed for demand [#id] and are ready for HM screening.",
  },
  {
    functionName: ReduxApiService.updateActiveProposalStatus,
    message: {
      [EProposalStatus.MoveToHMInterview]:
        "Candidate[s] Shortlisted - HM Interview",
      [EProposalStatus.WithdrawProposal]: "Candidate Withdrawl",
      [EProposalStatus.MoveToClientInterview]:
        "Candidate[s] Shortlisted - Client Interview",
      Rejected: "Candidate[s] Rejected",
      [EProposalStatus.RequestForRIW]: "[#name] | Request for RIW",
      [EProposalStatus.MoveToRIW]: "[#name] | RIW Request Approved",
    },
    description: {
      [EProposalStatus.MoveToHMInterview]:
        "The following candidate(s) have been successfully shortlisted for the HM Interview for demand [#id].",
      [EProposalStatus.WithdrawProposal]:
        "The following candidate(s) have been withdrawal for demand [#id].",
      [EProposalStatus.MoveToClientInterview]:
        "The following candidate(s) have been successfully shortlisted for the Client Interview for demand [#id].",
      Rejected:
        "The following candidate(s) have been rejected for demand [#id].",
      [EProposalStatus.RequestForRIW]:
        "The following candidate has been requested for RIW for demand [#id].",
      [EProposalStatus.MoveToRIW]:
        "The following candidate has been marked as RIW for demand [#id].",
    },
  },
  {
    functionName: ReduxApiService.demandApproveOrReject,
    message: {
      success: "Fulfillment Type Identified",
      failed: "External Fulfillment - Action Failed",
    },
    description: {
      approve: "Demand(s) have been approved for external fulfillment.",
      reject: `Demand(s) have been rejected for external fulfillment and moved to "Not Acted" status. `,
      failed:
        "The approval action for the following demand is failed, Please retry",
    },
  },
  {
    functionName: ReduxApiService.onHoldOrRelease,
    message: {
      onHold: "Demand On Hold",
      release: "Demand Release",
    },
    description: {
      onHold: [
        "This demand [#id] has been put on Hold.",
        "Current fullfilment Type: [#fulfillmentType]",
      ],
      release: [
        "This demand [#id] has been released.",
        "Current fulfilment Type: [#fulfillmentType]",
      ],
    },
  },
];
export const generateToastNotificationContentService = (
  idx: number,
  action: any
): INotify | INotify[] => {
  switch (action.type.split("/")[0] as ReduxApiService) {
    case ReduxApiService.saveSubmitDemandCreationForm: {
      const ids: string[] = action.payload.data?.id.map(
        (item: any) => item.defreqId
      );
      let message = action.meta.arg.meta.fulfillmentUpdate
        ? toastNotificationList[idx].message.fulfillment
        : action.meta.arg.meta.submit
        ? toastNotificationList[idx].message.submit
        : toastNotificationList[idx].message.save;
      let description = action.meta.arg.meta.fulfillmentUpdate
        ? action.meta.arg.fulfillmentType?.id == EFulFillmentStatus.External
          ? toastNotificationList[idx].description.fulfillment.external
          : toastNotificationList[idx].description.fulfillment.internal
        : action.meta.arg.meta.submit
        ? ids.length > 1
          ? toastNotificationList[idx].description.submit[1]
          : toastNotificationList[idx].description.submit[0]
        : toastNotificationList[idx].description.save;
      description = description
        ?.replace("[#id]", ids[0])
        ?.replace(
          "[#fulfillmentType]",
          action.meta.arg.fulfillmentType?.label ?? ""
        );
      return {
        show: true,
        key: action.type,
        message: ids.length == 1 ? `${ids[0]} | ${message}` : message,
        description: {
          content: description,
          ids: ids.length > 1 ? ids : [],
        },
      };
    }
    case ReduxApiService.bulkFulfillmentUpdate: {
      let description =
        action.meta.arg.body.status == EFulFillmentStatus.External
          ? toastNotificationList[idx].description.external
          : toastNotificationList[idx].description.internal;
      description = description?.replace(
        "[#fulfillmentType]",
        action.meta.arg.body.newStatus
      );
      return {
        show: true,
        key: action.type,
        message: toastNotificationList[idx].message,
        description: {
          content: description,
          ids: action.meta.arg.body.defreqList.map((item: any) => item.id),
        },
      };
    }
    case ReduxApiService.convertDemandForm: {
      const description = toastNotificationList[idx].description?.replace(
        "[#id]",
        action.meta.arg.body.demandId
      );
      return {
        show: true,
        key: action.type,
        message: toastNotificationList[idx].message,
        description: { content: description },
      };
    }
    case ReduxApiService.proposeSelectedEmployee: {
      const description = toastNotificationList[idx].description?.replace(
        "[#id]",
        action.meta.arg.defreqId
      );
      return {
        show: true,
        key: action.type,
        message: toastNotificationList[idx].message,
        description: { content: description },
      };
    }
    case ReduxApiService.demandApproveOrReject: {
      const { data } = action.payload || {};
      const { body } = action.meta.arg || {};
      const successList = data?.successList || [];
      const failedList = data?.failedList?.map((item: any) => item.id) || [];

      const successDescription = body.is_approved
        ? toastNotificationList[idx].description.approve
        : toastNotificationList[idx].description.reject;

      const failedDescription = toastNotificationList[idx].description.failed;

      const notifications: INotify[] = [];

      if (successList.length > 0) {
        notifications.push({
          show: true,
          key: `ApproveReject-${successList?.join("|")}`,
          message: toastNotificationList[idx].message.success,
          description: {
            content: successDescription,
            ids: successList,
          },
        });
      }
      if (failedList.length > 0) {
        notifications.push({
          type: "error" as TAlert,
          show: true,
          key: `ApproveReject-${failedList?.join("|")}`,
          message: toastNotificationList[idx].message.failed,
          description: {
            content: failedDescription,
            ids: failedList,
          },
        });
      }
      return notifications;
    }
    case ReduxApiService.updateActiveProposalStatus: {
      const status = action.meta.arg.status;
      let message = toastNotificationList[idx].message[status];
      let description = toastNotificationList[idx].description[status];
      if (
        status === EProposalStatus.MoveToRIW ||
        status === EProposalStatus.RequestForRIW
      ) {
        message = message.replace("[#name]", action.meta.arg.name);
      }
      description = description.replace("[#id]", action.meta.arg.defreqId);
      return {
        show: true,
        key: action.type,
        message: message,
        description: { content: description },
      };
    }
    case ReduxApiService.exportDraftTableData: {
      const description = toastNotificationList[idx].description?.replace(
        "[#id]",
        action.meta.arg.defreqId
      );
      return {
        show: true,
        key: action.type,
        message: toastNotificationList[idx].message,
        description: { content: description },
      };
    }
    case ReduxApiService.exportOpenTableData: {
      const description = toastNotificationList[idx].description?.replace(
        "[#id]",
        action.meta.arg.defreqId
      );
      return {
        show: true,
        key: action.type,
        message: toastNotificationList[idx].message,
        description: { content: description },
      };
    }
    case ReduxApiService.cancelDemandForm: {
      const description = toastNotificationList[idx].description?.replace(
        "[#id]",
        action.meta.arg.defreqId
      );
      return {
        show: true,
        key: action.type,
        message: toastNotificationList[idx].message,
        description: { content: description },
      };
    }
    case ReduxApiService.onHoldOrRelease: {
      const description = toastNotificationList[idx].description[
        action.meta.arg.body.hold ? "onHold" : "release"
      ]?.map((item: string) => {
        return item
          ?.replace("[#id]", action.meta.arg.body.defreqId)
          ?.replace("[#fulfillmentType]", action.meta.arg.body.fulfillmentType);
      });
      return {
        show: true,
        key: action.type,
        message: `${action.meta.arg.body.defreqId} | ${
          toastNotificationList[idx].message[
            action.meta.arg.body.hold ? "onHold" : "release"
          ]
        }`,
        description: { content: description },
      };
    }
    default: {
      return {
        show: false,
        key: "default",
        message: "Action Notification",
        description: { content: "Action Successfully Completed." },
      };
    }
  }
};

export const triggerNotificationService = (store: any, action: any) => {
  const idx = toastNotificationList.findIndex(
    (item) => item.functionName === action.type.split("/")[0]
  );
  if (idx > -1) {
    const notifications = generateToastNotificationContentService(idx, action);
    if (Array.isArray(notifications)) {
      notifications.forEach((notification, index) => {
        setTimeout(() => {
          store.dispatch(setNotification(notification));
        }, index * 1000);
      });
    } else {
      store.dispatch(setNotification(notifications));
    }
  }
};
