/**
 * Decodes HTML entities in a string back to their original characters.
 * @param {string} input - The input string to decode.
 * @returns {string} - The decoded string.
 */
const decodeString = (input: string): string => {
  const replacements: { [key: string]: string } = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#x27;": "'",
    "&#x2F;": "/",
  };

  return input.replace(
    /&(amp|lt|gt|quot|#x27|#x2F);/g,
    (match) => replacements[match]
  );
};

/**
 * Recursively decodes HTML entities in various data types.
 * @param {any} input - The input data to decode.
 * @returns {any} - The decoded data.
 * @example
 * const encodedString = '&lt;script&gt;alert("XSS")&lt;/script&gt;';
 * const decodedString = decodeOutput(encodedString);
 * console.log(decodedString); // <script>alert("XSS")</script>
 *
 * const encodedArray = ['&lt;div&gt;', '&lt;span&gt;Test&lt;/span&gt;'];
 * const decodedArray = decodeOutput(encodedArray);
 * console.log(decodedArray); // ['<div>', '<span>Test</span>']
 *
 * const encodedObject = { key1: '&lt;div&gt;', key2: ['&lt;span&gt;'] };
 * const decodedObject = decodeOutput(encodedObject);
 * console.log(decodedObject); // { key1: '<div>', key2: ['<span>'] }
 */
export const decodeOutput = (input: any): any => {
  if (typeof input === "string") {
    return decodeString(input);
  }

  if (Array.isArray(input)) {
    return input.map((item) => decodeOutput(item));
  }

  if (typeof input === "object" && input !== null) {
    const decodedObj: { [key: string]: any } = {};
    Object.keys(input).forEach((key) => {
      decodedObj[key] = decodeOutput(input[key]);
    });

    return decodedObj;
  }

  return input;
};
