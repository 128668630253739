import React, { Fragment, useCallback, useState } from "react";

import { ReactComponent as CornerUpLeftIcon } from "../../../../assets/corner-up-left.svg";
import { ReactComponent as TableActionIcon } from "../../../../assets/more-vertical.svg";
import { ReactComponent as MoveIcon } from "../../../../assets/move.svg";
import { ReactComponent as UserCheckIcon } from "../../../../assets/user-check.svg";
import { ReactComponent as XIcon } from "../../../../assets/x.svg";
import { RejectCancelReason } from "../../../../components/Reject-Cancel-Reason";
import { SVGIcon } from "../../../../components/SVG-Icon";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import { Drawer, Tag, Tooltip } from "../../../../lib";
import { AntdMenuProps, Dropdown } from "../../../../lib/Dropdown";
import {
  AntdTableProps,
  Table,
  TableParams,
  defaultPagination,
} from "../../../../lib/Table";
import { updateActiveProposalStatus } from "../../../../redux/slice/demand";
import { EFulFillmentAction } from "../../../../views/enums";
import {
  IActiveProposalsTable,
  IDemandMeta,
} from "../../../../views/interfaces";
import "./Active-Proposal.css";

export interface ActiveProposalProps {
  data: IActiveProposalsTable[];
  meta: IDemandMeta;
}

export const ActiveProposal: React.FC<ActiveProposalProps> = ({
  data,
  meta,
}) => {
  const dispatch = useAppDispatch();
  const [fulfillment, setFulfillment] = useState<{
    record: IActiveProposalsTable;
    actionId: number;
  } | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const updateActiveCandidateStatus = useCallback(
    (record: IActiveProposalsTable, action: any) => {
      setFulfillment({ record, actionId: action.value as number });
      if (action.type === EFulFillmentAction.reject) {
        setOpen(true);
      } else {
        dispatch(
          updateActiveProposalStatus({
            fulfillmentId: record.fulfillmentId,
            employeeInternalId: record.candidateNameLevelRole.internalId,
            actionId: action.value,
            preStatus: record.status.value,
            rejectionReason: null,
            status: action.label,
            defreqId: meta.defreqId,
          })
        );
      }
    },
    [dispatch, meta.defreqId]
  );

  const onSelectedRejectionReason = (reason: string | null) => {
    dispatch(
      updateActiveProposalStatus({
        fulfillmentId: fulfillment?.record.fulfillmentId,
        employeeInternalId:
          fulfillment?.record?.candidateNameLevelRole.internalId,
        actionId: fulfillment?.actionId,
        preStatus: fulfillment?.record?.status.value,
        rejectionReason: reason,
        status: "Rejected",
        defreqId: meta.defreqId,
        employee: fulfillment?.record?.candidateNameLevelRole,
      })
    );
  };

  const dropdownItems = useCallback(
    (
      record: IActiveProposalsTable,
      nextAction: IActiveProposalsTable["nextAction"]
    ) => {
      return nextAction.map((action, idx) => {
        return {
          key: (idx + 1).toString(),
          label: (
            <div
              className="proposed-action-dropdown-item"
              onClick={() => updateActiveCandidateStatus(record, action)}
            >
              {EFulFillmentAction.select == action.type ? (
                <SVGIcon SVGElementIcon={UserCheckIcon} active={true} />
              ) : EFulFillmentAction.move == action.type ? (
                <SVGIcon SVGElementIcon={MoveIcon} active={true} />
              ) : EFulFillmentAction.reject == action.type ? (
                <SVGIcon SVGElementIcon={XIcon} active={true} />
              ) : EFulFillmentAction.withdraw == action.type ? (
                <SVGIcon SVGElementIcon={CornerUpLeftIcon} active={true} />
              ) : (
                <SVGIcon SVGElementIcon={MoveIcon} active={true} />
              )}
              <span>{action.label}</span>
            </div>
          ),
        };
      }) as AntdMenuProps["items"];
    },
    [updateActiveCandidateStatus]
  );

  const tableColumns: AntdTableProps<IActiveProposalsTable>["columns"] = [
    {
      title: "Candidate Name/Level/Role",
      dataIndex: "candidateNameLevelRole",
      key: "candidateNameLevelRole",
      sorter: false,
      render: ({ employeeId, name, level, role }) => (
        <>
          <span>
            <span style={{ fontWeight: 600 }}>{employeeId}</span> -{" "}
            <Tooltip
              title={name}
              placement="bottomRight"
              arrow={{ pointAtCenter: true }}
            >
              {name}
            </Tooltip>
          </span>
          <br />
          <span>
            {level} | {role}
          </span>
        </>
      ),
    },
    {
      title: "Candidate Status",
      dataIndex: "status",
      key: "status",
      sorter: false,
      render: ({ label, value }) => (
        <span>
          <Tag color="#ECFDF3" textColor="#027A48">
            {label}
          </Tag>
        </span>
      ),
    },
    {
      title: "Current Allocation",
      dataIndex: "currentAllocation",
      key: "currentAllocation",
      sorter: false,
      render: ({ project, percentage }) => (
        <>
          <span style={{ fontWeight: 600 }}>{project}</span>
          <br />
          <span>{percentage} %</span>
        </>
      ),
    },
    {
      title: "Reporting Manager",
      dataIndex: "reportingManager",
      key: "reportingManager",
      sorter: false,
      render: ({ name, role }) => (
        <>
          <Tooltip title={name} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{name}</span>
          </Tooltip>
          <br />
          <Tooltip title={role} placement="bottomRight">
            <span>{role}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "nextAction",
      key: "nextAction",
      sorter: false,
      width: 50,
      render: (_, record) => {
        return (
          <Dropdown
            placement="bottomRight"
            menu={{
              items: dropdownItems(record, record.nextAction),
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SVGIcon SVGElementIcon={TableActionIcon} color="#59595A" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: defaultPagination,
  });

  return (
    <Fragment>
      <div className="active-candidate-table">
        <Table
          columns={tableColumns}
          dataSource={data}
          tableParams={tableParams}
          setTableParams={setTableParams}
          totalCount={data.length}
          showPagination={false}
        />
      </div>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        title="Reason for Rejection"
        children={
          <RejectCancelReason
            type="reject"
            fulfillment={fulfillment?.record ?? null}
            onClose={(reason) => {
              setOpen(false);
              onSelectedRejectionReason(reason);
            }}
          />
        }
        placement="bottom"
        rootClassName="rejection-reason-drawer"
        destroyOnClose={true}
      />
    </Fragment>
  );
};
