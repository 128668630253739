import React, { CSSProperties, ReactNode } from "react";

import AntdTag from "antd/es/tag";
import "./Tag.css";

export interface TagProps {
  children: ReactNode | string;
  color?: string;
  textColor?: string;
  bordered?: boolean;
  closeIcon?: ReactNode;
  className?: "rounded";
  style?: CSSProperties;
  title?: string;
}

export const Tag: React.FC<TagProps> = ({
  children = "Tag",
  color = "#fe5000",
  textColor = "#ffffff",
  bordered = false,
  closeIcon = undefined,
  className = "rounded",
  style,
  title,
}) => {
  const onClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
  };

  const tagCustomStyle: CSSProperties = {
    color: textColor,
    ...style,
  };

  return (
    <AntdTag
      className={className}
      bordered={bordered}
      closeIcon={closeIcon}
      onClose={onClose}
      color={color}
      style={tagCustomStyle}
      title={title}
    >
      {children}
    </AntdTag>
  );
};
