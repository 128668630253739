import { EFilterCode, EModuleCode, RouterUrl } from "./enums";

export const DateFormat_DDMMMYYYY = "DD MMM YYYY";
export const DateFormat_MMMDYYYY_WithTimeZone = "MMM D, YYYY h:mm A [GMT] Z";

export const DashboardFilterCodeList = <string[]>[
  EFilterCode.DASHBOARD_ACCOUNT,
  EFilterCode.DASHBOARD_VERTICAL,
  EFilterCode.DASHBOARD_PRACTICE,
];

export const RouterModuleUrl: {
  [key: string]: { url: RouterUrl; moduleCode: EModuleCode; label: string };
} = {
  [RouterUrl.dashboard]: {
    url: RouterUrl.dashboard,
    moduleCode: EModuleCode.M_DASHBOARD,
    label: "Dashboard",
  },
  [RouterUrl.openDemands]: {
    url: RouterUrl.openDemands,
    moduleCode: EModuleCode.M_OPEN_DEMANDS,
    label: "Open Demands",
  },
  [RouterUrl.draftDemands]: {
    url: RouterUrl.draftDemands,
    moduleCode: EModuleCode.M_DRAFT_DEMANDS,
    label: "Draft Demands",
  },
  [RouterUrl.allEmployees]: {
    url: RouterUrl.allEmployees,
    moduleCode: EModuleCode.M_EMPLOYEE,
    label: "All Employees",
  },
  [RouterUrl.projectsOpportunities]: {
    url: RouterUrl.projectsOpportunities,
    moduleCode: EModuleCode.M_OPPORTUNITY_PROJECT,
    label: "Projects & Opportunities",
  },
  [RouterUrl.reports]: {
    url: RouterUrl.reports,
    moduleCode: EModuleCode.M_REPORTS,
    label: "Reports",
  },
  [RouterUrl.careerCompass]: {
    url: RouterUrl.careerCompass,
    moduleCode: EModuleCode.M_CAREER_COMPASS,
    label: "Career Compass",
  },
  [RouterUrl.careerPath]: {
    url: RouterUrl.careerPath,
    moduleCode: EModuleCode.M_CAREER_COMPASS,
    label: "Career Path",
  },
  [RouterUrl.skillProfile]: {
    url: RouterUrl.skillProfile,
    moduleCode: EModuleCode.M_CAREER_COMPASS,
    label: "Skill Profile",
  },
  [RouterUrl.competencyDevelopment]: {
    url: RouterUrl.competencyDevelopment,
    moduleCode: EModuleCode.M_CAREER_COMPASS,
    label: "Competency Development",
  },
  [RouterUrl.internalMobility]: {
    url: RouterUrl.internalMobility,
    moduleCode: EModuleCode.M_CAREER_COMPASS,
    label: "Internal Mobility",
  },
};
