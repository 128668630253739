import { IReportsCard } from "../views/interfaces";
import { ApiRoutesConfig, axiosInstance } from ".";
import { ReduxApiService } from "../views/enums";

export const getOpenDemandReports = (): IReportsCard => {
  return {
    title: "Demand Report ",
    children: [
      {
        title: "Open Demand Report",
      },
    ],
  };
};

// export const getSkillsAndSpecialization = (): IReportsCard => {
//   return {
//     title: "Skills and Specialization",
//     children: [
//       {
//         title: "Download Skills Report",
//       },
//       {
//         title: "Download Specialization Report",
//       },
//     ],
//   };
// };

const getApiUrlForReportType = (reportType: string) => {
  switch (reportType) {
    case ReduxApiService.openDemandReport:
      return ApiRoutesConfig[ReduxApiService.openDemandReport];
    //   case ReduxApiService.skills:
    //     return ApiRoutesConfig[ReduxApiService.skills];
    default:
      throw new Error("Invalid report type");
  }
};

export const getReportDataService = async (url: string) => {
  const apiUrl = getApiUrlForReportType(url);
  try {
    const response = await axiosInstance.get(apiUrl, { responseType: "blob" });
    if (response && response.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `open_demands_report.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      return "success";
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const getReportsService = () => {
  return [getOpenDemandReports()];
};
