import React from "react";
import { useAppSelector } from "../../hooks/redux-hooks";
import { RootState } from "../../redux/store";
import "./Header.css";

export interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const { headerTitle } = useAppSelector((state: RootState) => state.app);

  return (
    <header className="header">
      <div className="title">{headerTitle}</div>
    </header>
  );
};
