import React, {
  CSSProperties,
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { message, notification } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as CheckCircleComponent } from '../../assets/check-circle.svg';
import { ReactComponent as CloseComponent } from '../../assets/x.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { Tabs } from '../../lib';
import { resetMessage, resetNotification } from '../../redux/slice/app';
import { EModuleCode, RouterUrl } from '../../views/enums';
import { IMsg, INotify } from '../../views/interfaces';
import { Header } from '../Header';
import { Loader } from '../Loader';
import { NoAccess } from '../No-Access';
import { Notification } from '../Notification';
import { Sidebar } from '../Sidebar';
import { SVGIcon } from '../SVG-Icon';
import './Layout.css';

export interface LayoutProps {}

export const Layout: React.FC<LayoutProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>('0');
  const { sidebarCollapsed, loading, notify, msg, activeModuleCode, profile } =
    useAppSelector((state) => state.app);

  const customStyle: CSSProperties = {
    cursor: loading > 0 ? 'wait' : 'default',
    pointerEvents: loading > 0 ? 'none' : 'auto',
  };

  const [notificationApi, notificationContextHolder] =
    notification.useNotification({
      stack: {
        threshold: 3,
      },
    });
  const [messageApi, messageContextHolder] = message.useMessage({
    maxCount: 3,
  });

  const showNotification = useCallback(
    (notify: INotify) => {
      notificationApi.open({
        ...notify,
        description: Notification(notify.description),
        icon:
          notify.type === 'error' ? (
            <SVGIcon
              SVGElementIcon={CloseComponent}
              color='#fe5000'
              cursorPointer={false}
            ></SVGIcon>
          ) : (
            <SVGIcon
              SVGElementIcon={CheckCircleComponent}
              cursorPointer={false}
            ></SVGIcon>
          ),
        closeIcon: <SVGIcon SVGElementIcon={CloseComponent}></SVGIcon>,
        onClose: () => dispatch(resetNotification()),
        pauseOnHover: true,
        showProgress: false,
      });
    },
    [notificationApi, dispatch]
  );

  const showMessage = useCallback(
    (msg: IMsg) => {
      messageApi.open({
        ...msg,
        onClose: () => dispatch(resetMessage()),
      });
    },
    [messageApi, dispatch]
  );

  useEffect(() => {
    if (notify.show) {
      showNotification(notify);
    }
    if (msg.show) {
      showMessage(msg);
    }
  }, [notify, msg, showNotification, showMessage, dispatch]);

  const tabItems = useMemo(() => {
    return [
      {
        key: '1',
        label: 'Explore Career Paths',
        path: RouterUrl.careerPath,
      },
      // {
      //   key: "2",
      //   label: "Skill Profile",
      //   path: RouterUrl.skillProfile,
      // },
      {
        key: '3',
        label: 'Enhance Your Skills',
        path: RouterUrl.competencyDevelopment,
      },
      // {
      //   key: '4',
      //   label: 'Internal Mobility',
      //   path: RouterUrl.internalMobility,
      //   disabled: true,
      // },
    ] as {
      key: string;
      label: string;
      path: RouterUrl;
      disabled?: boolean;
    }[];
  }, []);

  const defaultTab = useMemo(() => {
    return tabItems[0]; // update this to change default tab
  }, [tabItems]);

  const handleTabChange = useCallback(
    (key: string) => {
      const tab =
        tabItems.find((item) => item.key == key)?.path ?? defaultTab.path;
      navigate('/' + tab);
    },
    [navigate, tabItems, defaultTab]
  );

  useEffect(() => {
    const path = location.pathname.split('/').filter((item) => item.length > 0);
    const tab =
      tabItems.find((item) => item.path == path[path.length - 1])?.key ??
      defaultTab.key;
    setActiveTab(tab);
  }, [location.pathname, tabItems, defaultTab, navigate]);

  return (
    <Fragment>
      {notificationContextHolder}
      {messageContextHolder}
      <div className='container' style={customStyle}>
        <Sidebar />
        <div
          className={['layout', sidebarCollapsed ? 'collapsed' : '']
            .join(' ')
            .trim()}
        >
          {activeModuleCode != EModuleCode.M_CAREER_COMPASS && <Header />}
          <main className='main'>
            {profile?.moduleList?.length == 0 ? (
              <NoAccess />
            ) : activeModuleCode == EModuleCode.M_CAREER_COMPASS ? (
              <div className='career-compass'>
                <div className='career-compass-header'>
                  <span className='name-text'>
                    Hi {profile.name}, Good Day!{' '}
                  </span>
                  <span>🎉</span>
                </div>
                <Tabs
                  items={tabItems}
                  activeTab={activeTab}
                  setActiveTab={handleTabChange}
                  className='career-compass-tabs'
                  id='career-compass'
                />
              </div>
            ) : (
              <></>
            )}
            <Suspense fallback={<Loader loading={loading} />}>
              <Outlet />
            </Suspense>
          </main>
        </div>
      </div>
      <Loader loading={loading} />
    </Fragment>
  );
};
