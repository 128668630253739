import React, { CSSProperties, Fragment } from "react";

import { ReactComponent as leftIconComponent } from "../../assets/left.svg";
import { ReactComponent as darkSearchIconComponent } from "../../assets/search-dark.svg";
import { ReactComponent as SearchIconComponent } from "../../assets/search.svg";
import { ReactComponent as xIconComponent } from "../../assets/x.svg";
import { SVGIcon } from "../../components";
import { SearchBar } from "../Search-Bar";
import "./Drawer-Header.css";

export interface DrawerHeaderProps {
  headerLabel: string;
  showSearch?: boolean;
  enableSearch: boolean;
  setEnableSearch: (enable: boolean) => void;
  enableClose?: boolean;
  showCloseButtonOnLeft?: boolean;
  closeDrawer?: any;
  setSearchString?: any;
  style?: CSSProperties;
  className?: string;
}

export const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  headerLabel,
  showSearch = false,
  enableSearch = false,
  setEnableSearch,
  enableClose = true,
  showCloseButtonOnLeft = false,
  closeDrawer,
  setSearchString,
  style,
  className,
}) => {
  const toggleHeaderSearch = (state: boolean) => {
    setEnableSearch(state);
  };

  return (
    <Fragment>
      <div className="drawer-header" style={style}>
        {enableClose && showCloseButtonOnLeft && (
          <div className="drawer-header-close">
            <SVGIcon SVGElementIcon={leftIconComponent} onClick={closeDrawer} />
          </div>
        )}
        {showSearch && enableSearch ? (
          <SearchBar
            property="hover"
            rootStyle={{ width: "100%" }}
            leftIcon={SearchIconComponent}
            rightIcon={xIconComponent}
            placeHolder={`Search ${headerLabel}`}
            onClickRightIcon={() => toggleHeaderSearch(false)}
            setSearchString={setSearchString}
            autoFocus={true}
          />
        ) : (
          <div
            className="drawer-header-label"
            onClick={() => toggleHeaderSearch(true)}
          >
            <span
              className="text-wrapper-1"
              onClick={() => toggleHeaderSearch(true)}
            >
              {headerLabel}
            </span>
            {showSearch ? (
              <SVGIcon
                SVGElementIcon={darkSearchIconComponent}
                onClick={() => toggleHeaderSearch(true)}
              />
            ) : !showCloseButtonOnLeft ? (
              <SVGIcon SVGElementIcon={xIconComponent} onClick={closeDrawer} />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};
