/**
 * Stores data in the session storage.
 * @param key - The identifier for the stored data.
 * @param data - The data to be stored.
 * @param json - If true, the data is converted to a JSON string. Defaults to true.
 * @param encoded - If true, the data is encoded to a base64 string. Defaults to true.
 */
export const setSessionStorage = (
  key: string,
  data: any,
  json: boolean = true,
  encoded: boolean = true
): void => {
  let _data = json ? JSON.stringify(data) : data;
  let encodedData = encoded && _data ? btoa(_data) : _data;
  sessionStorage.setItem(key, encodedData);
};

/**
 * Retrieves data from the session storage.
 * @param key - The identifier for the data to be retrieved.
 * @param json - If true, the retrieved data is parsed from a JSON string. Defaults to true.
 * @param encoded - If true, the retrieved data is decoded from a base64 string. Defaults to true.
 * @returns The retrieved data, or null if no data was found for the given key.
 */
export const getSessionStorage = (
  key: string,
  json: boolean = true,
  encoded: boolean = true
) => {
  const encodedData = sessionStorage.getItem(key);
  if (encodedData) {
    let data = encoded ? atob(encodedData) : encodedData;
    return json ? JSON.parse(data) : data;
  } else {
    return null;
  }
};

/**
 * Removes data from the session storage.
 * @param key - The identifier for the data to be removed.
 */
export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

/**
 * Clears the session storage by removing all items.
 */
export const clearSessionStorage = () => {
  sessionStorage.clear();
};
