import React from "react";

import { Empty as AntdEmpty } from "antd";
import "./Empty.css";

export { AntdEmpty };

export interface EmptyProps {
  className?: string;
  rootClassName?: string;
  imageStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  image?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

export const Empty: React.FC<EmptyProps> = ({
  image = AntdEmpty.PRESENTED_IMAGE_SIMPLE,
  className,
  rootClassName,
  imageStyle,
  style,
  description,
  children,
}) => {
  return (
    <AntdEmpty
      className={className}
      rootClassName={rootClassName}
      imageStyle={imageStyle}
      style={style}
      image={image}
      description={description}
    >
      {children}
    </AntdEmpty>
  );
};
