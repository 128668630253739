import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as StrechRole } from '../../../../assets/link.svg';
import { SVGIcon } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { Button, Collapse, Tooltip } from '../../../../lib';
import { setPracticeTitle } from '../../../../redux/slice/career-path';
import { RootState } from '../../../../redux/store';
import { ICareerPathPracticeGroup } from '../../../../views/interfaces';
import './Practice-List.css';

export interface PracticeListProps {}

export const PracticeList: React.FC<PracticeListProps> = () => {
  const dispatch = useAppDispatch();
  const { practiceGroup } = useAppSelector(
    (state: RootState) => state.careerPath
  );

  const [practiceMenuList, setPracticeMenuList] = useState<
    ICareerPathPracticeGroup[]
  >([]);
  const [activeKey, setActiveKey] = useState<string[]>(['1']);

  const onClickOnPractice = useCallback(
    (practiceName: string) => {
      dispatch(setPracticeTitle(practiceName));
      setPracticeMenuList(
        practiceGroup?.map((category) => ({
          ...category,
          practices: category?.practices?.map((practice) => ({
            ...practice,
            active: practiceName === practice.name,
          })),
        }))
      );
    },
    [dispatch, practiceGroup]
  );

  useEffect(() => {
    if (practiceGroup.length > 0) {
      let defaultActiveFound = false;
      practiceGroup?.forEach((group) => {
        if (Array.isArray(group.practices)) {
          group.practices?.forEach((practice) => {
            if (practice.active) {
              defaultActiveFound = true;
              dispatch(setPracticeTitle(practice.name));
            }
          });
        }
      });
      setPracticeMenuList(practiceGroup);
      if (!defaultActiveFound) {
        const defaultActive = practiceGroup
          .flatMap((group) =>
            Array.isArray(group.practices) ? group.practices : []
          )
          .find((practice) => practice.my_practice === true);
        if (defaultActive) {
          onClickOnPractice(defaultActive.name);
        } else {
          onClickOnPractice(practiceGroup[0].practices[0].name);
        }
      }
    }
  }, [practiceGroup, onClickOnPractice, dispatch]);

  const collapseItems = useMemo(() => {
    return practiceMenuList.map((item, index) => {
      return {
        key: (index + 1).toString(),
        label: item.practice_group_name,
        children: (
          <div className='career-sidebar-menu'>
            <div className='top'>
              {Array.isArray(item.practices) &&
                item.practices.map((value, _index) => (
                  <div
                    key={(_index + 1)?.toString()}
                    className={`career-sidebar-menu-item ${
                      value.active ? 'career-sidebar-menu-item-active' : ''
                    }`}
                    onClick={() => onClickOnPractice(value.name)}
                  >
                    <div className={`frame`}>
                      <div className='heading'>
                        <span className='text-wrapper-1'>{value.name}</span>
                      </div>
                    </div>
                    {value.my_practice && (
                      <div className='my-practice'>
                        <Button type='primary'>My Practice</Button>
                      </div>
                    )}
                    {value.stretch_role && (
                      <Tooltip title='Lateral Path'>
                        <div>
                          <SVGIcon
                            SVGElementIcon={StrechRole}
                            style={{ stroke: 'transparent' }}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ),
      };
    });
  }, [practiceMenuList, onClickOnPractice]);

  return (
    <div className='practice-list'>
      {collapseItems.length > 0 && (
        <p className='practice-sidebar-header'>practices</p>
      )}
      <Collapse
        items={collapseItems}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        className='practice-sidebar'
      />
    </div>
  );
};
