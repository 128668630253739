import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from '../../assets/dashboard.svg';
import { ReactComponent as ChevronsLeft } from '../../assets/left.svg';
import { ReactComponent as LogoutIcon } from '../../assets/log-out.svg';
import logo from '../../assets/logo.jpg';
import { ReactComponent as PlayIcon } from '../../assets/play.svg';
import { ReactComponent as ChevronsRight } from '../../assets/right.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import {
  getEmployeeProfile,
  setActiveModuleCode,
  setHeaderTitle,
  setLogout,
  setRouteBack,
  setSidebarCollapsedMode,
} from '../../redux/slice/app';
import { resetTourCompleted } from '../../redux/slice/career-path';
import { RouterModuleUrl } from '../../views/constants';
import { EModuleCode, RouterUrl } from '../../views/enums';
import { IModule } from '../../views/interfaces';
import { SidebarMenu } from '../Sidebar-Menu';
import { SidebarMenuItem } from '../Sidebar-Menu-Item';
import { SidebarUser } from '../Sidebar-User';
import { SVGIcon } from '../SVG-Icon';
import './Sidebar.css';

export interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { sidebarCollapsed, avatar, activeModuleCode, profile, graph } =
    useAppSelector((state) => state.app);
  const [sidebarMenu, setSidebarMenu] = useState<IModule[]>([]);
  const nameInitials = useRef<string>('');

  useEffect(() => {
    if (graph?.displayName) {
      graph.displayName.split(' ').forEach((name) => {
        nameInitials.current += name.charAt(0);
      });
    }
  }, [graph.displayName]);

  useEffect(() => {
    dispatch(getEmployeeProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profile && profile?.moduleList?.length > 0) {
      setSidebarMenu(profile.moduleList);
      const path = location.pathname
        .split('/')
        .filter((item) => item.length > 0);
      if (path.length > 0) {
        const moduleCode = RouterModuleUrl[path[path.length - 1]]?.moduleCode;
        const hasAccess = profile.moduleList?.some(
          (item) => item.code == moduleCode
        );
        if (!hasAccess) {
          history.back();
        }
      }
      const activeMenu = profile.moduleList?.find((item) =>
        location.pathname != '/'
          ? location.pathname == item.endpoint
          : item.isActive
      );
      if (activeMenu) {
        if (location.pathname == '/') {
          navigate(activeMenu.endpoint);
        }
        dispatch(setHeaderTitle(activeMenu.name));
        dispatch(setActiveModuleCode(activeMenu.code));
      }
    }
  }, [profile, location.pathname, dispatch, navigate]);

  const changeActiveMenu = (code: string) => {
    const selectedMenu = sidebarMenu.find((item) => item.code == code);
    if (selectedMenu) {
      navigate(selectedMenu.endpoint);
    }
  };

  const startCareerCompassTour = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
    dispatch(resetTourCompleted());
    if (location.pathname != `/${RouterUrl.careerPath}`) {
      dispatch(setRouteBack({ status: true, url: location.pathname }));
      navigate(`/${RouterUrl.careerPath}`);
    }
  };

  return (
    <aside className={`sidebar ${sidebarCollapsed ? 'collapsed' : ''}`.trim()}>
      <div className='sidebar-header'>
        <div className='sidebar-header-frame'>
          <div className='sidebar-title'>
            <span className='logo-container'>
              <img src={logo} height={24} width={24} alt='logo' />
            </span>
            {sidebarCollapsed ? '' : <p className='text-wrapper-1'>tribe</p>}
          </div>
          {activeModuleCode != EModuleCode.M_CAREER_COMPASS && (
            <div
              className='icon'
              onClick={() =>
                dispatch(setSidebarCollapsedMode(!sidebarCollapsed))
              }
            >
              <SVGIcon
                SVGElementIcon={sidebarCollapsed ? ChevronsRight : ChevronsLeft}
                color='#404041'
              ></SVGIcon>
            </div>
          )}
        </div>
      </div>
      <div className='sidebar-content'>
        {activeModuleCode == EModuleCode.M_CAREER_COMPASS ? (
          <SidebarUser />
        ) : (
          <SidebarMenu
            menuList={sidebarMenu}
            onClick={changeActiveMenu}
            activeMenuCode={activeModuleCode}
          />
        )}
      </div>
      <div className='sidebar-footer'>
        {activeModuleCode == EModuleCode.M_CAREER_COMPASS && (
          <div className='sidebar-tour-card'>
            <div>
              <p className='text-wrapper-1'>Missed exploring Career Compass?</p>
            </div>
            <div
              className='sidebar-tour-button'
              onClick={() => startCareerCompassTour()}
            >
              <SVGIcon SVGElementIcon={PlayIcon} color='#fe5000' fill={true} />
              <p>Take the Tour again</p>
            </div>
          </div>
        )}
        <div
          className={`sidebar-footer-frame ${
            sidebarCollapsed ? 'sidebar-menu-only-icon' : ''
          }`.trim()}
        >
          {activeModuleCode != EModuleCode.M_CAREER_COMPASS && (
            <SidebarMenuItem
              code={EModuleCode.M_CAREER_COMPASS}
              title={'My Profile'}
              activeMenuCode={activeModuleCode}
              isSvgIcon={false}
              src={avatar}
              avatarInitials={nameInitials.current}
              onClick={() => {
                dispatch(setActiveModuleCode(EModuleCode.M_CAREER_COMPASS));
                navigate('/' + RouterUrl.careerPath);
              }}
            />
          )}
          {activeModuleCode == EModuleCode.M_CAREER_COMPASS &&
            sidebarMenu.find(
              (item) => item.code == EModuleCode.M_DASHBOARD
            ) && (
              <SidebarMenuItem
                code={EModuleCode.M_DASHBOARD}
                title={'Dashboard'}
                activeMenuCode={activeModuleCode}
                isSvgIcon={true}
                SVGIconElement={DashboardIcon}
                avatarInitials={nameInitials.current}
                onClick={() => {
                  dispatch(setActiveModuleCode(EModuleCode.M_DASHBOARD));
                  navigate('/' + RouterUrl.dashboard);
                }}
              />
            )}
          <SidebarMenuItem
            code={EModuleCode.M_LOGOUT}
            title={'Logout'}
            activeMenuCode={activeModuleCode}
            SVGIconElement={LogoutIcon}
            onClick={() => {
              dispatch(setLogout(true));
            }}
          />
        </div>
      </div>
    </aside>
  );
};
