import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactComponent as ProfileEdit } from '../../assets/profile-edit.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { Avatar, Drawer } from '../../lib';
import { getCareerCompassEmployeeData } from '../../redux/slice/career-path';
import { ProfileSummary } from '../Profile-Summary';
import { SidebarProfile } from '../Sidebar-Profile';
import { SVGIcon } from '../SVG-Icon';
import './Sidebar-User.css';

export const SidebarUser: React.FC = () => {
  const dispatch = useAppDispatch();
  const { avatar, profile } = useAppSelector((state) => state.app);
  const { employeeData, profileSummary } = useAppSelector(
    (state) => state.careerPath
  );
  const nameInitials = useRef<string>('');
  const [editSummary, setEditSummary] = useState<boolean>(false);
  const [expandText, setExpandText] = useState<boolean>(false);
  const [profileSummaryText, setProfileSummaryText] = useState<string>('');

  const profileSummaries = useMemo(() => {
    return [
      "Don’t settle for ordinary. 🚀 Your 'About Me' is a chance to share the story that matters, the change you’re here to create. 🌍✨ What’s your bold idea? What problem are you solving? 🧩🔍 Make this section remarkable—because the work that stands out is the work that gets remembered. 📝💡",
      "Start with WHY. 🎯 Your 'About Me' is your purpose—why you do what you do. 🔍❤️ Who are you serving, and how does it inspire you every day? 👥🌟 Share the deeper reason behind your work, and let people connect with your cause, not just your accomplishments. 🤝🚀",
      "Think different. 🧠✨ This isn’t just an 'About Me'; it’s your manifesto. 📜💥 What’s the vision that drives you? 🔍🔥 Where do you find your inspiration? 🎨🌍 Make this section a declaration of your passion to innovate, push boundaries, and change the world. 🌍🚀",
      "Master your mind. 🧠💪 Your 'About Me' is a reflection of your journey and mindset. 🌱✨ How do you handle setbacks and grow stronger? 🚧➡️🏆 Share the strategies that keep you moving forward, and let this space show not just what you do, but how you think. 🧩🔍",
      "Take action. 🚀 Your 'About Me' is the 5-second moment that matters—don’t overthink it. ⏳📝 Share what drives you, what you’re fighting for, and what gets you out of bed in the morning. ☀️💪 Make it real, make it bold, and hit that edit button now. 🖊️⚡️",
      "Be the change. 🌱✨ Your 'About Me' isn’t just about achievements; it’s about your journey and growth. 🌍❤️ Share the deeper purpose that guides you and the higher self you aspire to become. 🙏✨ What are you here to give? Your presence can inspire others. 🌈🤝",
      "Write your own story. 📖✨ Your 'About Me' is a chapter in the journey of who you are and who you’re becoming. 🏰🦋 Share your passions, your magic, and even the struggles that shaped you. ⚡️📝 Every profile tells a tale—make yours unforgettable. ✨🚀",
      "Craft your narrative. 🖊️🗣️ Your 'About Me' is a dialogue—what do you want to say? 📜💡 Share the experiences, language, and connections that shape you. 🌍👥 Every word contributes to your story, so choose those that truly represent who you are and where you’re headed. 🚀📖",
    ];
  }, []);

  const getEmployeeDetails = useCallback(() => {
    dispatch(getCareerCompassEmployeeData());
  }, [dispatch]);

  useEffect(() => {
    if (profile.name) {
      nameInitials.current = profile.name
        .split(' ')
        .filter((_, index, arr) => index === 0 || index === arr.length - 1)
        .map((name) => name.charAt(0))
        .join('');
      getEmployeeDetails();
    }
  }, [profile.name, profile.emailId, getEmployeeDetails]);

  useEffect(() => {
    if (profileSummary) {
      getEmployeeDetails();
    }
  }, [profileSummary, getEmployeeDetails]);

  const handleCloseDrawer = () => {
    setEditSummary(false);
  };

  const expandSummary = useCallback(() => {
    setExpandText(!expandText);
  }, [expandText]);

  useEffect(() => {
    const text = employeeData?.profile_summary
      ? employeeData.profile_summary
      : profileSummaries[Math.floor(Math.random() * profileSummaries.length)];
    if (!expandText && text.length > 100) {
      setProfileSummaryText(`${text.slice(0, 100)}...`);
    } else {
      setProfileSummaryText(text);
    }
  }, [employeeData?.profile_summary, profileSummaries, expandText]);

  return (
    <div className='sidebar-user-container'>
      {employeeData != null && (
        <>
          <div className='sidebar-user-profile'>
            <Avatar
              size={'large'}
              src={avatar ? <img src={avatar} alt='avatar' /> : undefined}
            >
              {!avatar && nameInitials.current}
            </Avatar>
            <div className='sidebar-user-personal-details'>
              <div className='id-name'>
                <p>
                  {employeeData?.employee_number} | {employeeData?.name}
                </p>
              </div>
              <div className='level-designation'>
                <p>
                  {employeeData?.level}{' '}
                  {employeeData.designation && (
                    <>| {employeeData?.designation}</>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className='profile-summary'>
            <div className='profile-summary-details'>
              <p className='text-wrapper-1'>Profile Summary:</p>
              <SVGIcon
                SVGElementIcon={ProfileEdit}
                onClick={() => setEditSummary(true)}
              />
            </div>

            <p
              className='text-wrapper-2'
              onMouseLeave={() => setTimeout(() => setExpandText(false), 800)}
            >
              {profileSummaryText}
            </p>
            {profileSummaryText.length >= 100 && (
              <span onClick={expandSummary} className='toggle-button'>
                {expandText ? 'Show less' : 'Show more'}
              </span>
            )}
          </div>
          <SidebarProfile profileDetails={employeeData} />
          <Drawer
            open={editSummary}
            title='Profile Summary'
            onClose={() => setEditSummary(false)}
            children={
              <ProfileSummary
                onClose={handleCloseDrawer}
                defaultValue={employeeData?.profile_summary}
              />
            }
          />
        </>
      )}
    </div>
  );
};
