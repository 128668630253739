/**
 * Stores data in the local storage.
 * @param key - The identifier for the stored data.
 * @param data - The data to be stored.
 * @param json - If true, the data is converted to a JSON string. Defaults to true.
 * @param encoded - If true, the data is encoded to a base64 string. Defaults to true.
 */
export const setLocalStorage = (
    key: string,
    data: any,
    json: boolean = true,
    encoded: boolean = true
): void => {
    let _data = json ? JSON.stringify(data) : data;
    let encodedData = encoded ? btoa(_data) : _data;
    localStorage.setItem(key, encodedData);
};

/**
 * Retrieves data from the local storage.
 * @param key - The identifier for the data to be retrieved.
 * @param json - If true, the retrieved data is parsed from a JSON string. Defaults to true.
 * @param encoded - If true, the retrieved data is decoded from a base64 string. Defaults to true.
 * @returns The retrieved data, or null if no data was found for the given key.
 */
export const getLocalStorage = (
    key: string,
    json: boolean = true,
    encoded: boolean = true
) => {
    const encodedData = localStorage.getItem(key);
    if (encodedData) {
        let data = encoded ? atob(encodedData) : encodedData;
        return json ? JSON.parse(data) : data;
    } else {
        return null;
    }
};

/**
 * Removes data from the local storage.
 * @param key - The identifier for the data to be removed.
 */
export const removeLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};

/**
 * Clears all data from the local storage.
 */
export const clearLocalStorage = () => {
    localStorage.clear();
};
