import React, { useState } from "react";

import { Dayjs } from "dayjs";
import { DatePicker, Radio } from "../../lib";
import { EDatePickerType } from "../../views/enums";
import "./Filter-Advanced-Extra.css";

export interface FilterAdvancedExtraProps {
  headerTitle: string;
  options?: { label: EDatePickerType; value: EDatePickerType }[];
  value?: Dayjs[];
  setValue?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const FilterAdvancedExtra: React.FC<FilterAdvancedExtraProps> = ({
  headerTitle,
  options = [
    {
      label: EDatePickerType.SpecificDate,
      value: EDatePickerType.SpecificDate,
    },
    {
      label: EDatePickerType.DateRange,
      value: EDatePickerType.DateRange,
    },
  ],
  value = [],
  setValue = () => {},
}) => {
  const [datePickerType, setDatePickerType] = useState<
    EDatePickerType | undefined
  >(
    value && value?.length > 1
      ? EDatePickerType.DateRange
      : value && value?.length == 1
      ? EDatePickerType.SpecificDate
      : undefined
  );

  const onChangeDatePickerType = (value: string) => {
    setDatePickerType(value as EDatePickerType);
    setValue && setValue([]);
  };

  const onChangePositionStartDate = (value: Dayjs | [Dayjs, Dayjs]) => {
    let utcDates: string[] = [];
    if (Array.isArray(value)) {
      utcDates = [value[0]?.format(), value[1]?.endOf("day").format()];
    } else {
      utcDates = [value?.format()];
    }
    setValue && setValue(utcDates);
  };

  return (
    <div className="filter-advanced-date-picker">
      <p className="text-wrapper-1">{headerTitle}</p>
      <div className="picker-container">
        <Radio
          options={options}
          value={datePickerType}
          onChange={onChangeDatePickerType}
        />
        <DatePicker
          id="start"
          placeholder={
            datePickerType === EDatePickerType.SpecificDate
              ? "Start Date"
              : ["Start Date", "End Date"]
          }
          defaultValue={value}
          showRange={datePickerType !== EDatePickerType.SpecificDate}
          onChange={onChangePositionStartDate}
        />
      </div>
    </div>
  );
};
