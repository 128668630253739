import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { Drawer, Radio } from '../../../../lib';
import {
  getCareerCompassCareerLadder,
  getCareerCompassRoleDetails,
  getCareerCompassStretchRole,
} from '../../../../redux/slice/career-path';
import { EFlowTypes } from '../../../../views/enums';
import { CareerLadder } from '../Career-Ladder';
import { RoleDetails } from '../Role-Details';
import { StretchRoles } from '../Stretch-Roles';
import './Practice-Career.css';

export interface PracticeCareerProps {}

export const PracticeCareer: React.FC<PracticeCareerProps> = () => {
  const dispatch = useAppDispatch();
  const {
    practiceTitle,
    practiceGroup,
    careerLadder,
    stretchRole,
    employeeData,
  } = useAppSelector((state) => state.careerPath);
  const [roleDetails, setRoleDetails] = useState<{
    show: boolean;
    data?: any;
  }>({ show: false });
  const buttonGroupOptions = useMemo(() => {
    return [
      {
        label: <p id='career-ladder-button'>{EFlowTypes.CARRER_LADDER}</p>,
        value: EFlowTypes.CARRER_LADDER.split(' ').join('_').toLowerCase(),
      },
      {
        label: <p id='stretch-roles-button'>{EFlowTypes.STRETCH_ROLES}</p>,
        value: EFlowTypes.STRETCH_ROLES.split(' ').join('_').toLowerCase(),
      },
    ];
  }, []);

  const [activeButton, setActiveButton] = useState<string>(
    buttonGroupOptions[0].value
  );

  useEffect(() => {
    if (employeeData && practiceTitle && practiceGroup.length > 0) {
      setActiveButton(buttonGroupOptions[0].value);
      dispatch(
        getCareerCompassCareerLadder({
          practice_name: practiceTitle,
          current_role_name:
            employeeData?.practice == practiceTitle
              ? employeeData?.role
              : undefined,
        })
      );
      if (employeeData?.practice == practiceTitle) {
        dispatch(
          getCareerCompassStretchRole({
            practice_name: practiceTitle,
            role_name: employeeData?.role,
            level: employeeData?.level,
          })
        );
      }
    }
  }, [
    practiceGroup,
    practiceTitle,
    employeeData,
    buttonGroupOptions,
    dispatch,
  ]);

  const getRoleDetails = useCallback(
    (data: any, type: string) => {
      const payload = {
        roleId: data?.id,
        practice: type == 'careerLadder' ? data?.practice : data?.extraLabel,
      };
      dispatch(getCareerCompassRoleDetails(payload));
    },
    [dispatch]
  );

  const handleNodeClick = (data?: any, type: string = '') => {
    setRoleDetails({
      show: data ? true : false,
      data,
    });
    getRoleDetails(data, type);
  };

  return (
    <Fragment>
      <div className='practice-container'>
        {practiceTitle && (
          <div className='practice-description'>
            <p className='text-wrapper-1'>What does {practiceTitle} do?</p>
            <p className='text-wrapper-2'>
              {practiceGroup
                .flatMap((group) => group.practices)
                .find((practice) => practice.name === practiceTitle)
                ?.description ?? ''}
            </p>
          </div>
        )}
        <div className='practice-button-group'>
          {employeeData?.practice == practiceTitle && (
            <Radio
              options={buttonGroupOptions}
              defaultValue={buttonGroupOptions[0].value}
              optionType='button'
              buttonStyle='solid'
              onChange={(value) => setActiveButton(value)}
              value={activeButton}
            ></Radio>
          )}
        </div>
        <div className='practice-tree-graph'>
          {practiceTitle && (
            <>
              {activeButton === buttonGroupOptions[0].value ? (
                <CareerLadder
                  flowData={careerLadder}
                  handleNodeClick={(data) =>
                    handleNodeClick(data, 'careerLadder')
                  }
                />
              ) : (
                <StretchRoles
                  flowData={stretchRole}
                  handleNodeClick={(data) =>
                    handleNodeClick(data, 'lateralPath')
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
      <Drawer
        open={roleDetails.show}
        width={840}
        style={{ backgroundColor: '#F6F7F7' }}
        onClose={() => setRoleDetails({ show: false })}
        title={roleDetails?.data?.data?.label ?? ''}
      >
        <RoleDetails />
      </Drawer>
    </Fragment>
  );
};
