import React, { CSSProperties, useEffect, useId, useState } from "react";

import AntdCheckbox, { CheckboxChangeEvent } from "antd/es/checkbox";
import "./Checkbox.css";

export interface CheckboxProps {
  label: string | JSX.Element;
  value: string;
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  action?: (label: any, value: string, checked: boolean) => void;
  checkboxType?: "group" | "item";
  id?: string;
  style?: CSSProperties;
  className?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  value,
  checked = false,
  indeterminate = false,
  disabled = false,
  action = (label: any, value: string, checked: boolean) => {},
  checkboxType = "item",
  id,
  style,
  className,
}) => {
  const checkboxId = useId();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isIndeterminate, setisIndeterminate] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    setisIndeterminate(indeterminate);
  }, [indeterminate]);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    e.stopPropagation();
    setIsChecked(!isChecked);
    setisIndeterminate(false);
    action(label, value, e.target.checked);
  };

  return (
    <div className="checkbox">
      <AntdCheckbox
        id={id ?? checkboxId}
        style={style}
        className={className}
        value={value}
        checked={isChecked}
        disabled={disabled}
        onChange={handleCheckboxChange}
        indeterminate={isIndeterminate}
        onClick={(e) => e.stopPropagation()}
        title={typeof label == "string" ? label : undefined}
      >
        <p
          className="checkbox-label"
          title={typeof label == "string" ? label : undefined}
        >
          {label}
        </p>
      </AntdCheckbox>
    </div>
  );
};
