import React from "react";

import "./Notification.css";

export interface NotificationProps {
  content: string | string[];
  ids?: string[];
  employees?: {
    name: string;
    level: string;
    role: string;
    specialization: string;
  }[];
  reason?: string;
}
export const Notification: React.FC<NotificationProps> = ({
  content,
  ids = [],
  employees = [],
  reason,
}) => {
  return (
    <>
      {Array.isArray(content) ? (
        content.map((item, index) => {
          return (
            <p key={index} className="custom-description">
              {item}
            </p>
          );
        })
      ) : (
        <p className="custom-description">{content}</p>
      )}
      {ids.length > 0 && <p>{ids.join(", ")}</p>}
      {employees.length > 0 && (
        <p>
          {employees.map((item) => {
            return (
              <>
                <span style={{ color: "#fe5000" }}>{item.name}</span> -{" "}
                {item.level} | {item.role} | {item.specialization}
                <br />
              </>
            );
          })}
        </p>
      )}
      {reason && (
        <p>
          <b>Reason: </b>
          {reason}
        </p>
      )}
    </>
  );
};
