import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Button } from "../../lib/Button";
import { Radio } from "../../lib/Radio";
import {
  getCancellationReasons,
  getRejectionReasons,
  getRmgReasons,
} from "../../redux/slice/demand";
import { IActiveProposalsTable, ILabelValue } from "../../views/interfaces";
import "./Reject-Cancel-Reason.css";

export interface RejectCancelReasonProps {
  type: "reject" | "cancel" | "rmgReasons";
  fulfillment: IActiveProposalsTable | null;
  onClose: (reason: string | null) => void;
}

export const RejectCancelReason: React.FC<RejectCancelReasonProps> = ({
  type,
  fulfillment,
  onClose = () => {},
}) => {
  const dispatch = useAppDispatch();
  const { rejectionReasonList, cancellationReasonList, rmgReasonList } =
    useAppSelector((state) => state.demand);
  const [reasonList, setReasonList] = useState<ILabelValue[]>([]);
  const [rejectCancelReason, setRejectCancelReason] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (type == "reject" && rejectionReasonList.length == 0) {
      dispatch(getRejectionReasons());
    } else if (type == "cancel" && cancellationReasonList.length == 0) {
      dispatch(getCancellationReasons());
    } else if (type == "rmgReasons" && rmgReasonList.length == 0) {
      dispatch(getRmgReasons());
    }
  }, [
    type,
    rejectionReasonList.length,
    cancellationReasonList.length,
    rmgReasonList.length,
    dispatch,
  ]);

  useEffect(() => {
    if (type == "reject" && rejectionReasonList.length > 0) {
      setReasonList(rejectionReasonList);
    }
  }, [type, rejectionReasonList]);

  useEffect(() => {
    if (type == "cancel" && cancellationReasonList.length > 0) {
      setReasonList(cancellationReasonList);
    }
  }, [type, cancellationReasonList]);
  useEffect(() => {
    if (type == "rmgReasons" && rmgReasonList.length > 0) {
      setReasonList(rmgReasonList);
    }
  }, [type, rmgReasonList]);

  return (
    <div className="reject-cancel-reason">
      <div className="reject-cancel-readon-list">
        {type == "reject" ? (
          <p className="reject-cancel-readon-label">
            Please select the reason for Rejecting{" "}
            <b>{fulfillment?.candidateNameLevelRole.name}</b>
            {" | "}
            <b>{fulfillment?.candidateNameLevelRole.employeeId}</b>
          </p>
        ) : type == "cancel" ? (
          <p className="reject-cancel-readon-label">
            Please select the reason for Cancellation
          </p>
        ) : (
          <p className="reject-cancel-readon-label">
            Please select the reason for Closing
          </p>
        )}
        <Radio
          className="reject-cancel-readon-radio"
          value={rejectCancelReason ?? ""}
          onChange={(reason) => setRejectCancelReason(reason)}
          options={reasonList}
        />
      </div>
      <div className="reject-cancel-reason-actions">
        <Button onClick={() => onClose(null)}>Cancel</Button>
        <Button
          type="primary"
          disabled={!rejectCancelReason}
          onClick={() => onClose(rejectCancelReason)}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
