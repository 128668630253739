import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ReactComponent as SaveIconComponent } from "../../../../assets/save.svg";
import { SVGIcon } from "../../../../components/SVG-Icon";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import { Button, Drawer } from "../../../../lib";
import { AntdCollapseProps, Collapse } from "../../../../lib/Collapse";
import {
  getActiveProposals,
  getExternalFulfillment,
  getProposalHistory,
  proposeSelectedEmployee,
  resetFormSubmitted,
} from "../../../../redux/slice/demand";
import { EFulFillmentStatus } from "../../../../views/enums";
import {
  IDemandMeta,
  IProposalEmployeeList,
} from "../../../../views/interfaces";
import { ActiveProposal } from "../Active-Proposal";
import { ExternalFulfillment } from "../External-Fulfillment";
import { ProposeCandidate } from "../Propose-Candidate";
import { ProposeCandidateItem } from "../Propose-Candidate-Item";
import { ProposedHistory } from "../Proposed-History";
import "./Demand-Fulfillment.css";

const maxProposeCandidates = 3;
export interface DemandFulfillmentProps {
  meta: IDemandMeta;
}

export const DemandFulfillment: React.FC<DemandFulfillmentProps> = ({
  meta,
}) => {
  const dispatch = useAppDispatch();
  const {
    activeProposals,
    externalFulfillment,
    proposalHistory,
    formSubmitted,
  } = useAppSelector((state) => state.demand);
  const [openProposeCandidates, setOpenProposeCandidates] =
    useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<
    IProposalEmployeeList[]
  >([]);
  const [activeKey, setActiveKey] = useState<string[]>(["1"]);
  const getData = useCallback(() => {
    dispatch(getActiveProposals({ defreqId: meta.defreqId }));
    dispatch(getProposalHistory({ defreqId: meta.defreqId }));
  }, [meta.defreqId, dispatch]);

  useEffect(() => {
    if (meta.demandStatus.fulfillmentType == "Internal") {
      getData();
    }
  }, [meta.demandStatus.fulfillmentType, getData]);

  useEffect(() => {
    if (formSubmitted) {
      setSelectedEmployee([]);
      dispatch(resetFormSubmitted());
      getData();
    }
  }, [formSubmitted, getData, dispatch]);
  useEffect(() => {
    if (meta.defreqId) {
      dispatch(getExternalFulfillment({ defreqId: meta.defreqId }));
    }
  }, [dispatch, meta]);

  const closeProposeCandidates = (list: IProposalEmployeeList[]) => {
    setSelectedEmployee(list);
    setOpenProposeCandidates(false);
    setActiveKey(["1"]);
  };

  const removeAlreadyProposedEmployee = (employeeId: number) => {
    setSelectedEmployee((prev: IProposalEmployeeList[]) =>
      prev.filter((emp) => emp.employeeId !== employeeId)
    );
  };

  const proposedSelectedEmployee = () => {
    dispatch(
      proposeSelectedEmployee({
        defreqId: meta.defreqId,
        employeeInternalIds: selectedEmployee.map((emp) => emp.internalId),
        employee: selectedEmployee.map((emp) => {
          emp.employeeId,
            emp.employeeName,
            emp.level,
            emp.role,
            emp.specialization;
        }),
        markDemandAsInternal: true,
      })
    );
  };

  const items: AntdCollapseProps["items"] = useMemo(() => {
    const collapseItems = [
      {
        key: "1",
        label: (
          <div className="demand-fulfillment-frame">
            {meta.demandStatus.fulfillmentType ==
            EFulFillmentStatus.External ? (
              <span className="demand-fulfillment-label">
                External Fulfilment
              </span>
            ) : (
              <>
                <span className="demand-fulfillment-label">
                  Active Proposals
                </span>
                <Button
                  htmlType="button"
                  type="link"
                  // disabled={
                  //   activeProposals.length + selectedEmployee.length ==
                  //   maxProposeCandidates
                  // }
                  disabled={true} // for mvp1 release
                  onClick={() => setOpenProposeCandidates(true)}
                >
                  Propose Candidates
                </Button>
              </>
            )}
          </div>
        ),
        children: (
          <div className="demand-fulfillment-table">
            {meta.demandStatus.fulfillmentType == EFulFillmentStatus.External &&
            externalFulfillment.length > 0 ? (
              <ExternalFulfillment data={externalFulfillment} />
            ) : meta.demandStatus.fulfillmentType ==
                EFulFillmentStatus.External && activeProposals.length > 0 ? (
              <ActiveProposal data={activeProposals} meta={meta} />
            ) : (
              <div className="empty-active-candidate">
                {meta.demandStatus.fulfillmentType ==
                EFulFillmentStatus.External ? (
                  <span>No offers have been released for this demand yet.</span>
                ) : (
                  <span>There are no active proposal for this demand.</span>
                )}
              </div>
            )}
            <div className="proposed-candidate-list">
              {selectedEmployee.map((employee, idx) => (
                <ProposeCandidateItem
                  key={idx}
                  {...employee}
                  isInFullfillment={true}
                  onClick={() =>
                    removeAlreadyProposedEmployee(employee.employeeId)
                  }
                />
              ))}
            </div>
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <span className="demand-fulfillment-label">Proposed History</span>
        ),
        children: (
          <div className="demand-fulfillment-table">
            <ProposedHistory data={proposalHistory} />
          </div>
        ),
      },
    ];
    return collapseItems;
  }, [
    activeProposals,
    externalFulfillment,
    proposalHistory,
    selectedEmployee,
    meta,
  ]);

  return (
    <Fragment>
      <div className="demand-fulfillment">
        <div className="demand-fulfillment-collapse">
          <Collapse
            items={items}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
          />
        </div>
        <div className="demand-form-section-button">
          <div className="demand-form-section-content">
            {/* <Button
              htmlType="button"
              disabled={
                meta.demandStatus.fulfillmentType != EFulFillmentStatus.Internal
              }
              style={{ width: "100%" }}
            >
              Convert
            </Button> */}
            <Button htmlType="reset" style={{ width: "100%" }}>
              Cancel Demand
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={selectedEmployee.length == 0}
              style={{ width: "100%" }}
              icon={
                <SVGIcon
                  SVGElementIcon={SaveIconComponent}
                  cursorPointer={selectedEmployee.length == 0}
                  color={selectedEmployee.length > 0 ? "#fff" : undefined}
                />
              }
              onClick={() => proposedSelectedEmployee()}
            >
              Save & Close
            </Button>
          </div>
        </div>
      </div>
      <Drawer
        open={openProposeCandidates}
        onClose={() => setOpenProposeCandidates(false)}
        title="Propose Candidates"
        children={
          <ProposeCandidate
            alreadyProposed={selectedEmployee}
            onClose={(list: IProposalEmployeeList[]) =>
              closeProposeCandidates(list)
            }
            maxProposeCandidates={maxProposeCandidates - activeProposals.length}
          />
        }
        placement="bottom"
        rootClassName="propose-candidates-drawer"
      />
    </Fragment>
  );
};
