import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { Loader } from './components/Loader';
import './index.css';
import { msalConfig } from './modules/Auth/Auth.config';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { router } from './router';

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <RouterProvider
        router={router}
        fallbackElement={<Loader loading={1} />}
        future={{ v7_startTransition: true }}
      />
    </MsalProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
