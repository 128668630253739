import React from 'react';

import { ReactComponent as CareerLadderPlaceholder } from '../../../../assets/career-ladder-placeholder.svg';
import { ReactComponent as stretchRole } from '../../../../assets/link.svg';
import { SVGIcon } from '../../../../components';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { FlowProvider } from '../../../../lib/Flow';
import { IReactFlow } from '../../../../views/interfaces';
import { PracticeExpert } from '../Practice-Expert';
import './Career-Ladder.css';

export interface CareerLadderProps {
  flowData: IReactFlow;
  handleNodeClick: (data?: any) => void;
}

export const CareerLadder: React.FC<CareerLadderProps> = ({
  flowData,
  handleNodeClick,
}) => {
  const { employeeData, practiceTitle, loading } = useAppSelector(
    (state) => state.careerPath
  );
  return (
    <div className='career-ladder'>
      <FlowProvider
        background={{ bgColor: '#FEF1EC' }}
        showControls={true}
        nodeData={flowData.nodeList}
        edgeData={flowData.edgeList}
        handleNodeClick={handleNodeClick}
        treeDirection='BT'
        edgeType='smoothstep'
        showCurrentRole={true}
        multipleHandles={true}
        edgeStyle={{ stroke: '#FF9665', strokeWidth: 1 }}
        panel={{
          position: 'bottom-left',
          children: flowData.nodeList.length > 0 && (
            <div className='custom-panel'>
              <div className='eligible-roles'>
                {employeeData?.practice == practiceTitle ? (
                  <div className='eligible-div'></div>
                ) : (
                  <SVGIcon
                    SVGElementIcon={stretchRole}
                    style={{ stroke: 'transparent' }}
                  />
                )}

                <p>
                  {employeeData?.practice == practiceTitle
                    ? 'next step roles'
                    : 'lateral paths'}
                </p>
              </div>
            </div>
          ),
        }}
        otherPractice={employeeData?.practice !== practiceTitle}
        controlStyles={{ left: 10, bottom: 90 }}
        placeholder={
          loading == 0 && (
            <div
              style={{
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '48px',
              }}
            >
              <div>
                <SVGIcon
                  SVGElementIcon={CareerLadderPlaceholder}
                  color='unset'
                  rootStyle={{ width: '100%', height: '100%' }}
                />
              </div>
              <div
                style={{
                  height: '58px',
                  width: '472px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p>
                  The career ladder for your practice is still being crafted.
                </p>
                <p>
                  Stay curious, explore other practices, and continue shaping
                  your own career path.
                </p>
              </div>
            </div>
          )
        }
      />
      <PracticeExpert />
    </div>
  );
};
