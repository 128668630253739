import React, { CSSProperties, FunctionComponent } from "react";

import { ReactComponent as NoIconComponent } from "../../assets/image_placeholder.svg";
import "./SVG-Icon.css";

export interface SVGIconProps {
  SVGElementIcon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  height?: number | string;
  width?: number | string;
  color?: string;
  colorActive?: string;
  cursorPointer?: boolean;
  active?: boolean;
  fill?: boolean;
  onClick?: any;
  testid?: string;
  className?: string;
  rootClassName?: string;
  style?: CSSProperties;
  rootStyle?: CSSProperties;
}

export const SVGIcon = ({
  SVGElementIcon,
  height = 24,
  width = 24,
  color = "#929497",
  colorActive = "#FE5000",
  cursorPointer = true,
  active = false,
  fill = false,
  onClick,
  testid = "svg-icon",
  className,
  rootClassName,
  style,
  rootStyle,
}: SVGIconProps) => {
  return (
    <span
      className={`svg-icon ${rootClassName ? rootClassName : ""}`.trim()}
      style={{
        height: height,
        width: width,
        cursor: cursorPointer ? "pointer" : "defult",
        ...rootStyle,
      }}
      onClick={onClick}
    >
      {SVGElementIcon ? (
        <SVGElementIcon
          stroke={active ? colorActive : color}
          fill={fill ? (active ? colorActive : color) : "none"}
          data-testid={testid}
          style={style}
          className={className}
        />
      ) : (
        <NoIconComponent
          data-testid={testid}
          style={style}
          className={className}
        />
      )}
    </span>
  );
};
