import {
  Button as AntdButton,
  ButtonProps as AntdButtonProps,
  FloatButton as AntdFloatButton,
  FloatButtonGroupProps as AntdFloatButtonGroupProps,
  FloatButtonProps as AntdFloatButtonProps,
} from 'antd';
import React, { CSSProperties, Fragment, ReactNode } from 'react';
import './Button.css';

export { AntdButton, AntdFloatButton };
export type { AntdButtonProps, AntdFloatButtonProps };

export interface ButtonProps {
  children?: ReactNode;
  onClick?: () => void;
  htmlType?: AntdButtonProps['htmlType'];
  icon?: ReactNode;
  iconPosition?: AntdButtonProps['iconPosition'];
  shape?: AntdButtonProps['shape'];
  floatShape?: AntdFloatButtonProps['shape'];
  size?: AntdButtonProps['size'];
  type?: AntdButtonProps['type'];
  floatType?: AntdFloatButtonProps['type'];
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  classNames?: {
    icon: string;
  };
  styles?: {
    icon: React.CSSProperties;
  };
  testid?: string;
  floatButton?: boolean;
  tooltip?: AntdFloatButtonProps['tooltip'];
  badge?: AntdFloatButtonProps['badge'];
  description?: AntdFloatButtonProps['description'];
  title?: string;
  floatButtonType?: 'default' | 'group';
  floatTrigger?: AntdFloatButtonGroupProps['trigger'];
  floatPlacement?: AntdFloatButtonGroupProps['placement'];
  floatCloseIcon?: AntdFloatButtonGroupProps['closeIcon'];
  floatOnOpenChange?: AntdFloatButtonGroupProps['onOpenChange'];
}

export const Button: React.FC<ButtonProps> = ({
  children,
  htmlType = 'button',
  onClick = () => {},
  icon,
  iconPosition = 'start',
  shape = 'default',
  floatShape = 'circle',
  size = 'middle',
  type = 'default',
  floatType = 'default',
  disabled = false,
  style,
  className,
  classNames,
  styles,
  testid = 'button-testid',
  floatButton = false,
  floatButtonType = 'default',
  tooltip,
  badge,
  description,
  title,
  floatTrigger = 'hover',
  floatPlacement = 'top',
  floatCloseIcon = null,
  floatOnOpenChange = () => {},
}) => {
  return (
    <Fragment>
      {floatButton ? (
        floatButtonType === 'group' ? (
          <AntdFloatButton.Group
            icon={icon}
            shape={floatShape}
            type={floatType}
            className={className}
            style={style}
            data-testid={testid}
            onClick={onClick}
            tooltip={tooltip}
            badge={badge}
            description={description}
            trigger={floatTrigger}
            placement={floatPlacement}
            closeIcon={floatCloseIcon}
            onOpenChange={floatOnOpenChange}
          >
            {children}
          </AntdFloatButton.Group>
        ) : (
          <AntdFloatButton
            icon={icon}
            shape={floatShape}
            type={floatType}
            className={className}
            style={style}
            data-testid={testid}
            onClick={onClick}
            tooltip={tooltip}
            badge={badge}
            description={description}
          >
            {children}
          </AntdFloatButton>
        )
      ) : (
        <AntdButton
          htmlType={htmlType}
          icon={icon}
          iconPosition={iconPosition}
          shape={shape}
          size={size}
          type={type}
          disabled={disabled}
          className={className}
          classNames={classNames}
          style={style}
          styles={styles}
          data-testid={testid}
          onClick={onClick}
          title={title}
        >
          {children}
        </AntdButton>
      )}
    </Fragment>
  );
};
