import React, {
  CSSProperties,
  Dispatch,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";

import { SVGIcon } from "../../components";
import "./Search-Bar.css";

export interface SearchBarProps {
  property?: "default" | "hover" | "disabled";
  size?: "large" | "medium";
  placeHolder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  leftIcon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  rightIcon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onClickRightIcon?: any;
  onClickLeftIcon?: any;
  onClick?: any;
  autoFocus?: boolean;
  setSearchString?: Dispatch<React.SetStateAction<string | null>>;
  style?: CSSProperties;
  rootStyle?: CSSProperties;
  className?: string;
  rootClassName?: string;
  id?: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  property = "default",
  size = "large",
  placeHolder = "Search",
  disabled = false,
  readOnly = false,
  leftIcon,
  rightIcon,
  autoFocus = false,
  onClickRightIcon = () => {},
  onClickLeftIcon = () => {},
  onClick = () => {},
  setSearchString,
  id = "search-bar",
  className,
  rootClassName,
  style,
  rootStyle,
}) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (autoFocus) {
      searchRef.current?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (search && search.length > 0) {
        setSearchString?.(search);
      } else {
        setSearchString?.(null);
      }
    }, 1000);

    return () => {
      clearTimeout(timeId);
    };
  }, [search, setSearchString]);

  const handleChange = (e: any) => {
    e.preventDefault();
    setSearch(searchRef.current?.value || "");
  };

  return (
    <div
      className={[
        "search-bar",
        rootClassName ?? "",
        size ? "search-bar-" + size : "",
        property ? "search-bar-" + property : "",
      ]
        .join(" ")
        .trim()}
      style={rootStyle}
      onClick={onClick}
    >
      {leftIcon ? (
        <SVGIcon
          SVGElementIcon={leftIcon}
          onClick={onClickLeftIcon}
          rootClassName="icon-left"
        />
      ) : (
        <></>
      )}
      <input
        type="text"
        id={id}
        name={id}
        className={`search-bar-input ${className ?? ""}`.trim()}
        placeholder={placeHolder}
        disabled={disabled}
        readOnly={disabled || readOnly}
        ref={searchRef}
        onChange={handleChange}
        style={style}
      />
      {rightIcon ? (
        <SVGIcon
          SVGElementIcon={rightIcon}
          onClick={onClickRightIcon}
          rootClassName="icon-right"
        />
      ) : (
        <></>
      )}
    </div>
  );
};
