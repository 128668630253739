import dayjs from "dayjs";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as TableActionIcon } from "../../assets/more-vertical.svg";
import { SVGIcon, SubHeader } from "../../components";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Button, Drawer, Tag, Tooltip } from "../../lib";
import { AntdMenuProps, Dropdown } from "../../lib/Dropdown";
import {
  AntdTableProps,
  Table,
  TableParams,
  defaultPagination,
} from "../../lib/Table";
import { setNotification } from "../../redux/slice/app";
import {
  bulkDemandApproveOrReject,
  bulkFulfillmentUpdate,
  defaultFilterData,
  exportOpenTableData,
  getOpenTableData,
  resetBulkUpdateActionStatus,
  resetbulkDemandApproveOrRejectStatus,
  setReloadOpenDemand,
} from "../../redux/slice/open-demand";
import {
  getSelectedFilter,
  limitOffset,
  sort,
  updateFilterObject,
} from "../../utils";
import {
  EDemandSubStatusExternal,
  EFulFillmentStatus,
  EModuleCode,
  Filters,
  SortByColumn,
} from "../../views/enums";
import {
  IDemandMeta,
  IFilterOption,
  IOpenDemandFilter,
  IOpenDemandSelectedFilter,
  IOpenDemandTable,
  ISort,
} from "../../views/interfaces";
import { Demand } from "../Demand";
import "./Open-Demands.css";

export const defaultSelectedFilterData: IOpenDemandSelectedFilter = {
  account: [],
  vertical: [],
  subVertical: [],
  practice: [],
  project: [],
  opportunity: [],
  location: [],
  legalEntity: [],
  specialization: [],
  demandStatus: [],
  fullfillmentType: [],
  supplyRisk: [],
  role: [],
  level: [],
};

export interface OpenDemandsProps {}

export const OpenDemands: React.FC<OpenDemandsProps> = () => {
  const dispatch = useAppDispatch();
  const {
    filterData,
    sortData,
    tableData,
    totalCount,
    bulkUpdateActionStatus,
    bulkDemandApproveOrRejectStatus,
    reloadOpenDemand,
  } = useAppSelector((state) => state.openDemands);
  const { profile } = useAppSelector((state) => state.app);
  const location = useLocation();
  const locationState = useRef(location.state);

  const [openDemandForm, setOpenDemandForm] = useState<{
    state: boolean;
    key?: string;
    meta?: IDemandMeta;
    isRmAdmin?: boolean;
  }>({ state: false });
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: defaultPagination,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const hasSelected = selectedRowKeys.length > 0;
  const [moduleId, setModuleId] = useState<number | undefined>(undefined);
  const [filter, setFilter] = useState<IOpenDemandFilter>(defaultFilterData);
  const [selectedFilters, setSelectedFilter] =
    useState<IOpenDemandSelectedFilter>(defaultSelectedFilterData);
  const [isSelectedFilterChanged, setIsSelectedFilterChanged] =
    useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<ISort | null>(null);
  const [isRmAdmin, setIsRmAdmin] = useState<boolean>(false);
  const [isApproveReject, setIsApproveReject] = useState<boolean>(false);
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [positionStartDate, setPositionStartDate] = useState<string[]>([]);
  const [positionEndDate, setPositionEndDate] = useState<string[]>([]);
  const positionStartDateRef = useRef(positionStartDate);
  const positionEndDateRef = useRef(positionEndDate);
  const selectedFiltersRef = useRef(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.defaultFilters) {
      const defaultFilters = location.state.defaultFilters;
      setSelectedFilter({ ...defaultSelectedFilterData, ...defaultFilters });
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location, navigate]);

  useEffect(() => {
    setModuleId(
      profile?.moduleList?.find(
        (module) => module.code == EModuleCode.M_OPEN_DEMANDS
      )?.id
    );
  }, [profile]);

  useEffect(() => {
    positionStartDateRef.current = positionStartDate;
  }, [positionStartDate]);

  useEffect(() => {
    positionEndDateRef.current = positionEndDate;
  }, [positionEndDate]);

  const toggleDemandCreationForm = useCallback(
    (
      value: boolean,
      key?: string,
      demandId?: string,
      defreqId?: string,
      isRmAdmin?: boolean // only for mvp1 release
    ) => {
      if (value) {
        const selectedDemand = tableData.find(
          (item) =>
            item.id.demandId === demandId || item.id.defreqId === defreqId
        );
        let metaData: IDemandMeta | undefined;
        if (selectedDemand) {
          metaData = {
            defreqInternalId: selectedDemand.id.defreqInternalId,
            demandId: selectedDemand.id.demandId,
            defreqId: selectedDemand.id.defreqId,
            vertical: selectedDemand.practiceVerticalSubVertical.vertical,
            subVertical: selectedDemand.practiceVerticalSubVertical.subVertical,
            practice: selectedDemand.practiceVerticalSubVertical.practice,
            level: selectedDemand.levelRole.level,
            role: selectedDemand.levelRole.role,
            account: selectedDemand.accountProjectOpportunity.account,
            project: selectedDemand.accountProjectOpportunity.project,
            opportunity: selectedDemand.accountProjectOpportunity.opportunity,
            entity: selectedDemand.entity,
            demandStatus: selectedDemand.demandStatus,
            inHouseDemand: selectedDemand.inHouseDemand,
          };
        }
        setOpenDemandForm({
          state: value,
          key: key,
          meta: metaData,
          isRmAdmin, // only for mvp1 release
        });
      } else {
        setOpenDemandForm({ state: value, isRmAdmin }); // only for mvp1 release
      }
    },
    [tableData]
  );

  const getTableData = useCallback(
    (moduleId: number) => {
      dispatch(
        getOpenTableData({
          body: {
            sort: sort(tableParams.sortOrder, sortBy?.columnName),
            filter: {
              ...selectedFilters,
              positionStartDate: positionStartDateRef.current,
              positionEndDate: positionEndDateRef.current,
            },
            additionalFilter: [locationState.current?.additionalFilters || {}],
            searchQuery: searchString ?? "",
          },
          params: {
            ...limitOffset(tableParams),
            mid: moduleId,
          },
        })
      );
    },
    [dispatch, searchString, selectedFilters, sortBy?.columnName, tableParams]
  );

  useEffect(() => {
    if (JSON.stringify(filterData) === JSON.stringify(defaultFilterData))
      return;
    setFilter({ ...filterData });
  }, [filterData]);

  useEffect(() => {
    if (isSelectedFilterChanged) {
      const projects = filter.projectOpportunity.filter(
        (item: any) => item.id == 1
      );
      const opportunities = filter.projectOpportunity.filter(
        (item: any) => item.id == 2
      );
      const subVertical = filter.vertical
        .map((item) => item?.children ?? [])
        .flat();
      const demandStatus: IFilterOption[] = [];
      const fulfillmentType = filter.demandStatus.map((item) => {
        if (item.children && item.children.length > 0) {
          demandStatus.push(
            ...item.children.map((child) => {
              return {
                ...child,
                value: child.value.split(":")[0],
              };
            })
          );
        }
        return item;
      });
      const filterSelected = {
        account: clearFilters ? getSelectedFilter(filter.account) : [],
        practice: clearFilters ? getSelectedFilter(filter.practice) : [],
        project: clearFilters ? getSelectedFilter(projects) : [],
        opportunity: clearFilters ? getSelectedFilter(opportunities) : [],
        vertical: clearFilters ? getSelectedFilter(filter.vertical) : [],
        subVertical: clearFilters ? getSelectedFilter(subVertical) : [],
        location: clearFilters ? getSelectedFilter(filter.location) : [],
        legalEntity: clearFilters ? getSelectedFilter(filter.legalEntity) : [],
        specialization: clearFilters
          ? getSelectedFilter(filter.specialization)
          : [],
        supplyRisk: clearFilters ? getSelectedFilter(filter.supplyRisk) : [],
        demandStatus: clearFilters ? getSelectedFilter(demandStatus) : [],
        fullfillmentType: clearFilters
          ? getSelectedFilter(fulfillmentType)
          : [],
        level: clearFilters ? getSelectedFilter(filter.level) : [],
        role: clearFilters ? getSelectedFilter(filter.role) : [],
      };
      setSelectedFilter(filterSelected);
      setIsSelectedFilterChanged(false);
      if (!clearFilters) {
        selectedFiltersRef.current = 0;
      } else {
        selectedFiltersRef.current = Object.keys(filterSelected).reduce(
          (initial, key) => {
            if (
              [
                "project",
                "opportunity",
                "vertical",
                "subVertical",
                "demandStatus",
                "fullfillmentType",
              ].includes(key)
            ) {
              return initial;
            }
            const count =
              filterSelected[key as unknown as keyof IOpenDemandSelectedFilter]
                .length >= 1
                ? 1
                : 0;
            return initial + count;
          },
          0
        );
        if (
          filterSelected.project.length > 0 ||
          filterSelected.opportunity.length > 0
        ) {
          selectedFiltersRef.current += 1;
        }
        if (
          filterSelected.subVertical.length > 0 ||
          filterSelected.vertical.length > 0
        ) {
          selectedFiltersRef.current += 1;
        }
        if (
          filterSelected.demandStatus.length > 0 ||
          filterSelected.fullfillmentType.length > 0
        ) {
          selectedFiltersRef.current += 1;
        }
      }
    }
  }, [
    isSelectedFilterChanged,
    filter,
    dispatch,
    clearFilters,
    selectedFilters,
  ]);

  useEffect(() => {
    if (moduleId) {
      getTableData(moduleId);
    }
  }, [getTableData, moduleId]);

  useEffect(() => {
    if (reloadOpenDemand && moduleId) {
      getTableData(moduleId);
      dispatch(setReloadOpenDemand(false));
    }
  }, [reloadOpenDemand, getTableData, moduleId, dispatch]);

  const exportTableData = () => {
    dispatch(
      exportOpenTableData({
        body: {
          sort: sort(tableParams.sortOrder, sortBy?.columnName),
          filter: selectedFilters,
          searchQuery: searchString ?? "",
        },
      })
    );
  };

  const approveOrRejectOpenDemand = (isApproved: boolean) => {
    dispatch(
      bulkDemandApproveOrReject({
        body: {
          id: selectedRowKeys,
          is_approved: isApproved,
        },
      })
    );
  };

  const onChangeFilter = (
    key: keyof IOpenDemandFilter,
    label: any,
    value: string,
    checked: boolean
  ) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: prev[key].map((item) => {
          if (item.value === value && item.label === label) {
            return {
              ...item,
              tempChecked: checked,
              isUpdated: true,
              children: item.children?.map((child) => {
                return {
                  ...child,
                  tempChecked: checked,
                  isUpdated: true,
                };
              }),
            };
          } else if (item.children && item.children.length > 0) {
            let isChildUpdated = false;
            const selectedChild = item.children.map((child) => {
              if (child.value === value && child.label === label) {
                isChildUpdated = true;
                return {
                  ...child,
                  tempChecked: checked,
                  isUpdated: true,
                };
              }
              return child;
            });
            if (!isChildUpdated) {
              return item;
            }
            const isChildTempChecked = selectedChild.every((child) =>
              child.isUpdated ? child.tempChecked : child.checked
            );
            const isChildIndeterminate = selectedChild.some((child) =>
              child.isUpdated ? child.tempChecked : child.checked
            );
            return {
              ...item,
              isUpdated: isChildUpdated,
              tempChecked: isChildTempChecked,
              indeterminate: !isChildTempChecked && isChildIndeterminate,
              children: selectedChild,
            };
          }
          return item;
        }),
      };
    });
  };

  const filterApply = (
    apply: boolean = false,
    clear: boolean = false,
    key?: keyof IOpenDemandFilter
  ) => {
    if (apply) {
      if (key) {
        setFilter((prev) => {
          return {
            ...prev,
            [key]: updateFilterObject(apply, prev[key]),
          };
        });
      } else {
        setFilter((prev) => {
          return {
            account: updateFilterObject(apply, prev.account),
            vertical: updateFilterObject(apply, prev.vertical),
            practice: updateFilterObject(apply, prev.practice),
            location: updateFilterObject(apply, prev.location),
            legalEntity: updateFilterObject(apply, prev.legalEntity),
            specialization: updateFilterObject(apply, prev.specialization),
            demandStatus: updateFilterObject(apply, prev.demandStatus),
            supplyRisk: updateFilterObject(apply, prev.supplyRisk),
            projectOpportunity: updateFilterObject(
              apply,
              prev.projectOpportunity
            ),
            role: updateFilterObject(apply, prev.role),
            level: updateFilterObject(apply, prev.level),
          };
        });
      }
      setClearFilters(true);
      setIsSelectedFilterChanged(true);
    } else if (clear) {
      setFilter((prev) => {
        return {
          account: updateFilterObject(false, prev.account, true),
          vertical: updateFilterObject(false, prev.vertical, true),
          practice: updateFilterObject(false, prev.practice, true),
          location: updateFilterObject(false, prev.location, true),
          legalEntity: updateFilterObject(false, prev.legalEntity, true),
          specialization: updateFilterObject(false, prev.specialization, true),
          demandStatus: updateFilterObject(false, prev.demandStatus, true),
          supplyRisk: updateFilterObject(false, prev.supplyRisk, true),
          projectOpportunity: updateFilterObject(
            false,
            prev.projectOpportunity,
            true
          ),
          role: updateFilterObject(false, prev.role, true),
          level: updateFilterObject(false, prev.level, true),
        };
      });
      setClearFilters(false);
      setIsSelectedFilterChanged(true);
    } else {
      setIsSelectedFilterChanged(false);
    }
  };

  const bulkUpdate = (fullfilmentStatus: EFulFillmentStatus) => {
    dispatch(
      bulkFulfillmentUpdate({
        body: {
          newStatus: fullfilmentStatus,
          defreqList: selectedRowKeys.map((item) => {
            return {
              id: item,
              currentStatus: tableData.find((row) => row.key === item)
                ?.demandStatus.fulfillmentType,
            };
          }),
        },
      })
    );
  };

  useEffect(() => {
    if (bulkUpdateActionStatus || bulkDemandApproveOrRejectStatus) {
      dispatch(resetBulkUpdateActionStatus());
      dispatch(resetbulkDemandApproveOrRejectStatus());
      setSelectedRowKeys([]);
      if (moduleId) {
        getTableData(moduleId);
      }
    }
  }, [
    bulkUpdateActionStatus,
    getTableData,
    dispatch,
    bulkDemandApproveOrRejectStatus,
    moduleId,
  ]);

  const dropdownItems = useCallback(
    (demandId: string, defreqId: string, isRmAdmin: boolean = false) => {
      // only for mvp1 release
      return [
        {
          key: "1",
          label: (
            <span
              onClick={() =>
                toggleDemandCreationForm(
                  true,
                  "1",
                  demandId,
                  defreqId,
                  isRmAdmin // only for mvp1 release
                )
              }
            >
              Demand Details
            </span>
          ),
        },
        {
          key: "2",
          label: (
            <span
              onClick={() =>
                toggleDemandCreationForm(true, "3", demandId, defreqId)
              }
            >
              Fulfillment
            </span>
          ),
        },
        {
          key: "3",
          label: (
            <span
              onClick={() =>
                toggleDemandCreationForm(true, "2", demandId, defreqId)
              }
            >
              Demand history
            </span>
          ),
        },
      ] as AntdMenuProps["items"];
    },
    [toggleDemandCreationForm]
  );

  const tableColumns: AntdTableProps<IOpenDemandTable>["columns"] = [
    {
      title: "Demand ID",
      dataIndex: "id",
      key: "id",
      width: 95,
      render: ({ demandId, defreqId }) => (
        <>
          <span>{defreqId}</span>
        </>
      ),
    },
    {
      title: (
        <span>
          Level
          <br />
          Role
        </span>
      ),
      dataIndex: "levelRole",
      key: "levelRole",
      width: 75,
      sorter: SortByColumn.level == sortBy?.columnName,
      render: ({ level, role }) => (
        <>
          <span>{level}</span>
          <br />
          <Tooltip title={role?.name} placement="bottomRight">
            <span>{role?.name}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Specialization
          <br />
          Skills
        </span>
      ),
      dataIndex: "specializationSkills",
      key: "specializationSkills",
      sorter: SortByColumn.specialization == sortBy?.columnName,
      render: ({ specialization, skills }) => (
        <>
          <Tooltip title={specialization} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{specialization}</span>
          </Tooltip>
          <br />
          {skills.length > 0 && (
            <Tooltip title={skills.join(", ")} placement="bottomLeft">
              <span>{skills.join(", ")}</span>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: SortByColumn.location == sortBy?.columnName,
      render: ({ name, onsiteOffshore }) => (
        <>
          <Tooltip title={name} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{name}</span>
          </Tooltip>
          <br />
          <span>{onsiteOffshore}</span>
        </>
      ),
    },
    {
      title: (
        <span>
          Practice
          <br />
          Sub-Vertical
        </span>
      ),
      dataIndex: "practiceVerticalSubVertical",
      key: "practiceVerticalSubVertical",
      sorter: SortByColumn.practice == sortBy?.columnName,
      render: ({ practice, vertical, subVertical }) => (
        <>
          <Tooltip title={practice.name} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{practice.name}</span>
          </Tooltip>
          <br />
          <Tooltip
            title={
              subVertical.name.length > 0 ? subVertical.name : vertical.name
            }
            placement="bottomRight"
          >
            <span>
              {subVertical.name.length > 0 ? subVertical.name : vertical.name}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Account
          <br />
          Project / Opp.
        </span>
      ),
      dataIndex: "accountProjectOpportunity",
      key: "accountProjectOpportunity",
      render: ({ account, project, opportunity }) => (
        <>
          <Tooltip title={account.name} placement="bottomRight">
            <span style={{ fontWeight: 600 }}>{account.name}</span>
          </Tooltip>
          <br />
          <Tooltip
            title={
              project?.name && project?.name.length > 0
                ? project?.name
                : opportunity?.name
            }
            placement="bottomRight"
          >
            <span>
              {project?.name && project?.name.length > 0
                ? project?.name
                : opportunity?.name}
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <span>
          Pos. Start & <br /> End Date
        </span>
      ),
      dataIndex: "positionStartEndDate",
      key: "positionStartEndDate",
      sorter: SortByColumn.startDate == sortBy?.columnName,
      render: ({ startDate, endDate }) => (
        <>
          <span>{startDate}</span>
          <br />
          <span>{endDate}</span>
        </>
      ),
    },
    {
      title: (
        <span>
          Fulfillment &
          <br />
          Demand Status
        </span>
      ),
      dataIndex: "demandStatus",
      key: "demandStatus",
      sorter: SortByColumn.demandStatus == sortBy?.columnName,
      render: ({ status, fulfillmentType, subStatus }) => (
        <>
          {fulfillmentType == "Not Acted" ? (
            <Tag
              color="#FEEFE2"
              textColor="#D6680B"
              style={{ padding: "4px 8px" }}
            >
              {fulfillmentType}
            </Tag>
          ) : (
            <span>{fulfillmentType}</span>
          )}
          <br />
          {subStatus && subStatus.length > 0 && (
            <Tag
              color="#ECFDF3"
              textColor="#027A48"
              style={{
                padding: "4px 8px",
                maxWidth: "76px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={subStatus}
            >
              {subStatus}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Supply Risk",
      dataIndex: "supplyRisk",
      key: "supplyRisk",
      sorter: SortByColumn.supplyRisk == sortBy?.columnName,
      render: ({ status, startDate }) => (
        <>
          <span style={{ fontWeight: 600 }}>{status ? "Yes" : "No"}</span>
          <br />
          {status && <span>{startDate}</span>}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      sorter: false,
      width: 50,
      render: (_, record) => (
        <Dropdown
          placement="bottomRight"
          menu={{
            items: dropdownItems(
              record.id.demandId,
              record.id.defreqId,
              record.bulkAction.hasAccess // only for mvp1 release
            ),
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SVGIcon SVGElementIcon={TableActionIcon} color="#59595A" />
          </div>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (selectedRowKeys.length === 0) {
      setIsApproveReject(false);
      setDisableButtons(false);
      return;
    }
    if (selectedRowKeys.length > 0) {
      const allBulkActionsAllowed = selectedRowKeys.every((key) => {
        return (
          tableData.find((item) => item.key === key)?.bulkAction.hasAccess ==
          true
        );
      });

      setIsRmAdmin(allBulkActionsAllowed);
    }

    const allRows = selectedRowKeys
      .map((key) => tableData.find((item) => item.key === key))
      .filter(Boolean);

    const allExternalPendingApproval = allRows.every(
      (row) =>
        row?.demandStatus.fulfillmentType === EFulFillmentStatus.External &&
        row?.demandStatus.subStatus ===
          EDemandSubStatusExternal.Pending_Approval
    );

    const allNotActed = allRows.every(
      (row) =>
        row?.demandStatus.fulfillmentType === EFulFillmentStatus.Not_Acted
    );

    setIsApproveReject(allExternalPendingApproval && !allNotActed);

    const hasExternalPendingApproval = allRows.some(
      (row) =>
        row?.demandStatus.fulfillmentType === EFulFillmentStatus.External &&
        row?.demandStatus.subStatus ===
          EDemandSubStatusExternal.Pending_Approval
    );

    setDisableButtons(!isRmAdmin && hasExternalPendingApproval); // only for mvp1 release
  }, [selectedRowKeys, tableData, isRmAdmin]); // only for mvp1 release

  const handleBulkUpdate = (status: any) => {
    if (selectedRowKeys.length > 1) {
      const allConditionsMet = selectedRowKeys.every((key) => {
        const row = tableData.find((item) => item.key === key);
        if (!row) return false;

        const { fulfillmentType, subStatus } = row.demandStatus;
        // only for mvp1 release
        if (isRmAdmin) {
          return (
            (fulfillmentType === EFulFillmentStatus.External &&
              subStatus === EDemandSubStatusExternal.Pending_Approval) ||
            fulfillmentType === EFulFillmentStatus.Not_Acted
          );
        } else {
          return fulfillmentType === EFulFillmentStatus.Not_Acted;
        }
      });

      const hasNotActed = selectedRowKeys.some((key) => {
        const row = tableData.find((item) => item.key === key);
        return (
          row?.demandStatus.fulfillmentType === EFulFillmentStatus.Not_Acted
        );
      });

      const hasExternal = selectedRowKeys.some((key) => {
        const row = tableData.find((item) => item.key === key);
        return (
          row?.demandStatus.fulfillmentType === EFulFillmentStatus.External &&
          row.demandStatus.subStatus ===
            EDemandSubStatusExternal.Pending_Approval
        );
      });
      const conflictingTypes = hasNotActed && hasExternal;

      if (!allConditionsMet || conflictingTypes) {
        dispatch(
          setNotification({
            show: true,
            message: "Alert",
            key: "Bulk Alert",
            type: "error",
            description: {
              content:
                'Bulk user action is allowed when all selected Demands are either in "Not Acted" or "External : Pending Approval" status."',
            },
          })
        );
        return;
      }

      bulkUpdate(status);
    } else {
      bulkUpdate(status);
    }
  };

  return (
    <Fragment>
      <div className="open-demands">
        <SubHeader
          filters={[
            {
              label: Filters.demandStatus,
              is_searchable: true,
              data: filter?.demandStatus?.map((item) => {
                return {
                  ...item,
                  checked: item.tempChecked || item.checked,
                  filterType:
                    item.children && item.children.length > 0
                      ? "group"
                      : "item",
                };
              }),
              onChange: (label, value, checked) => {
                onChangeFilter("demandStatus", label, value, checked);
              },
              action: (apply, clear) =>
                filterApply(apply, clear, "demandStatus"),
              filterType: "group",
            },
            {
              label: Filters.practice,
              is_searchable: true,
              data: filter?.practice,
              onChange: (label, value, checked) => {
                onChangeFilter("practice", label, value, checked);
              },
              action: (apply, clear) => filterApply(apply, clear, "practice"),
            },
            {
              label: Filters.vertical,
              is_searchable: true,
              data: filter?.vertical,
              onChange: (label, value, checked) => {
                onChangeFilter("vertical", label, value, checked);
              },
              action: (apply, clear) => filterApply(apply, clear, "vertical"),
              filterType: "group",
            },
          ]}
          advancedFilter={{
            action: (apply, clear) => filterApply(apply, clear),
            clearFilters: clearFilters,
            data: [
              {
                label: Filters.account,
                data: filter?.account,
                onChange: (label, value, checked) => {
                  onChangeFilter("account", label, value, checked);
                },
              },
              {
                label: Filters.projectOpportunity,
                data: filter.projectOpportunity,
                onChange: (label, value, checked) => {
                  onChangeFilter("projectOpportunity", label, value, checked);
                },
              },
              {
                label: Filters.vertical,
                data: filter?.vertical?.map((item) => {
                  return {
                    ...item,
                    checked: item.tempChecked || item.checked,
                    filterType:
                      item.children && item.children.length > 0
                        ? "group"
                        : "item",
                  };
                }),
                onChange: (label, value, checked) => {
                  onChangeFilter("vertical", label, value, checked);
                },
                filterType: "group",
              },
              {
                label: Filters.practice,
                data: filter?.practice,
                onChange: (label, value, checked) => {
                  onChangeFilter("practice", label, value, checked);
                },
              },
              {
                label: Filters.location,
                data: filter?.location,
                onChange: (label, value, checked) => {
                  onChangeFilter("location", label, value, checked);
                },
              },
              {
                label: Filters.legalEntity,
                data: filter?.legalEntity,
                onChange: (label, value, checked) => {
                  onChangeFilter("legalEntity", label, value, checked);
                },
              },
              {
                label: Filters.specialization,
                data: filter?.specialization,
                onChange: (label, value, checked) => {
                  onChangeFilter("specialization", label, value, checked);
                },
              },
              {
                label: Filters.demandStatus,
                data: filter?.demandStatus?.map((item) => {
                  return {
                    ...item,
                    checked: item.tempChecked || item.checked,
                    filterType:
                      item.children && item.children.length > 0
                        ? "group"
                        : "item",
                  };
                }),
                onChange: (label, value, checked) => {
                  onChangeFilter("demandStatus", label, value, checked);
                },
                filterType: "group",
              },
              {
                label: Filters.level,
                data: filter?.level,
                onChange: (label, value, checked) => {
                  onChangeFilter("level", label, value, checked);
                },
              },
              {
                label: Filters.role,
                data: filter?.role,
                onChange: (label, value, checked) => {
                  onChangeFilter("role", label, value, checked);
                },
              },
              {
                label: Filters.supplyRisk,
                data: filter?.supplyRisk,
                onChange: (label, value, checked) => {
                  onChangeFilter("supplyRisk", label, value, checked);
                },
              },
            ],
          }}
          advancedFilterCount={selectedFiltersRef.current}
          setSearchString={setSearchString}
          onClickExportData={exportTableData}
          showSearchBar={true}
          showExportDataButton={true}
          sortOption={{
            data: sortData,
            action: (sortBy) => {
              setSortBy(sortBy);
              setTableParams((prev) => {
                return {
                  ...prev,
                  sortOrder: "ascend",
                };
              });
            },
            selected: sortBy,
          }}
          positionStartDate={{
            show: true,
            value: positionStartDate.map((date) => dayjs(date)),
            setValue: setPositionStartDate,
          }}
          positionEndDate={{
            show: true,
            value: positionEndDate.map((date) => dayjs(date)),
            setValue: setPositionEndDate,
          }}
        />
        <div className="open-demands-table">
          <Table
            columns={tableColumns}
            dataSource={tableData}
            totalCount={totalCount}
            enableCheckbox={true}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            tableParams={tableParams}
            setTableParams={setTableParams}
          />
        </div>
      </div>
      {hasSelected && (
        <div className="open-demands-footer">
          <span>
            Selected Items{" "}
            <span style={{ color: "#fe5000" }}>{selectedRowKeys.length}</span>
            <span style={{ marginLeft: 8 }}>Mark as</span>
          </span>
          {isRmAdmin ? (
            isApproveReject ? (
              <>
                <Button
                  onClick={() => {
                    approveOrRejectOpenDemand(false);
                  }}
                >
                  Reject
                </Button>
                <Button
                  onClick={() => {
                    approveOrRejectOpenDemand(true);
                  }}
                  type="primary"
                >
                  Approve
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={!hasSelected || disableButtons}
                  onClick={() => handleBulkUpdate(EFulFillmentStatus.Internal)}
                >
                  Internal
                </Button>
                <Button
                  disabled={!hasSelected || disableButtons}
                  onClick={() => handleBulkUpdate(EFulFillmentStatus.External)}
                >
                  External
                </Button>
                <Button
                  disabled={!hasSelected || disableButtons}
                  style={{ width: "auto" }}
                  onClick={() =>
                    handleBulkUpdate(EFulFillmentStatus.No_Staffing)
                  }
                >
                  No Staffing
                </Button>
              </>
            )
          ) : (
            <>
              <Button
                disabled={!hasSelected || disableButtons}
                onClick={() => handleBulkUpdate(EFulFillmentStatus.Internal)}
              >
                Internal
              </Button>
              <Button
                disabled={!hasSelected || disableButtons}
                onClick={() => handleBulkUpdate(EFulFillmentStatus.External)}
              >
                External
              </Button>
              <Button
                disabled={!hasSelected || disableButtons}
                style={{ width: "auto" }}
                onClick={() => handleBulkUpdate(EFulFillmentStatus.No_Staffing)}
              >
                No Staffing
              </Button>
            </>
          )}
        </div>
      )}

      <Drawer
        title="Open Demands Form"
        open={openDemandForm.state}
        onClose={() => toggleDemandCreationForm(false)}
        width={840}
        children={
          <Demand
            type="open"
            activeKey={openDemandForm.key}
            metaData={openDemandForm.meta}
            closeForm={() => {
              toggleDemandCreationForm(false);
            }}
            isRmAdmin={openDemandForm.isRmAdmin}
          />
        }
      />
    </Fragment>
  );
};
