import React, { CSSProperties } from "react";
import loaderGif from "../../assets/loader.gif";
import "./Loader.css";

export interface LoaderProps {
  loading: number;
}

export const Loader: React.FC<LoaderProps> = ({ loading = 0 }) => {
  const customStyle: CSSProperties = {
    visibility: loading > 0 ? "visible" : "hidden",
    opacity: loading,
    cursor: loading > 0 ? "wait" : "default",
    pointerEvents: loading > 0 ? "none" : "auto",
  };

  return (
    <div className="loader" style={customStyle}>
      <div className="loader-spinner">
        <img src={loaderGif} alt="loader" width={80} height={46} />
      </div>
    </div>
  );
};
