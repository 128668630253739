import React, { useState } from "react";

import { Tooltip } from "../../../../lib";
import {
  AntdTableProps,
  Table,
  TableParams,
  defaultPagination,
} from "../../../../lib/Table";
import { IProposalHistoryTable } from "../../../../views/interfaces";
import "./Proposed-History.css";

export interface ProposedHistoryProps {
  data: IProposalHistoryTable[];
}

export const ProposedHistory: React.FC<ProposedHistoryProps> = ({ data }) => {
  const tableColumns: AntdTableProps<IProposalHistoryTable>["columns"] = [
    {
      title: "Candidate Name/Level/Role",
      dataIndex: "candidateNameLevelRole",
      key: "candidateNameLevelRole",
      sorter: false,
      render: ({ employeeId, name, level, role }) => (
        <>
          <span>
            <span style={{ fontWeight: 600 }}>{employeeId}</span> -{" "}
            <Tooltip
              title={name}
              placement="bottomRight"
              arrow={{ pointAtCenter: true }}
            >
              {name}
            </Tooltip>
          </span>
          <br />
          <span>
            {level} | {role}
          </span>
        </>
      ),
    },
    {
      title: "Rejected Stage",
      dataIndex: "rejectedStage",
      key: "rejectedStage",
      sorter: false,
      render: (rejectedStage) => <span>{rejectedStage}</span>,
    },
    {
      title: "Proposed Date",
      dataIndex: "proposedDate",
      key: "proposedDate",
      sorter: false,
      render: (proposedDate) => <span>{proposedDate}</span>,
    },
    {
      title: "Rejected Date",
      dataIndex: "rejectedDate",
      key: "rejectedDate",
      sorter: false,
      render: (rejectedDate) => <span>{rejectedDate}</span>,
    },
    {
      title: "Rejected Reason",
      dataIndex: "rejectedReason",
      key: "rejectedReason",
      sorter: false,
      render: (rejectedReason) => (
        <>
          <Tooltip title={rejectedReason} placement="bottomRight">
            <span>{rejectedReason}</span>
          </Tooltip>
        </>
      ),
    },
  ];

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: defaultPagination,
  });

  return (
    <div className="proposed-hostory-table">
      <Table
        columns={tableColumns}
        dataSource={data}
        tableParams={tableParams}
        setTableParams={setTableParams}
        totalCount={data.length}
        showPagination={false}
      />
    </div>
  );
};
