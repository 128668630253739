import { Fragment, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import {
  getCareerCompassEmployeeData,
  getCareerCompassPracticeList,
  getCareerCompassSuccessStories,
  getDynamicPageContents,
  resetIntroCompleted,
  resetTourCompleted,
  resetUpdatePreferenceStatus,
  setIntroCompleted,
  setTourCompleted,
  updateEmployeeUserPreference,
} from '../../redux/slice/career-path';
import { EPreference } from '../../views/enums';
import './Career-Path.css';
import {
  CareerTour,
  IntroCard,
  PracticeCareer,
  PracticeList,
  SuccessStory,
} from './components';

export const CareerPath: React.FC = () => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.app);
  const {
    dynamicPageContents,
    employeeData,
    introCompleted,
    tourCompleted,
    updatePreferenceStatus,
  } = useAppSelector((state) => state.careerPath);

  useEffect(() => {
    if (profile.emailId) {
      dispatch(getCareerCompassPracticeList());
      dispatch(getCareerCompassSuccessStories());
    }
  }, [profile.emailId, dispatch]);

  const updateUserPreference = () => {
    const firstLoginCompleted =
      employeeData?.preferences?.find(
        (item) => item.preference_name === EPreference.FIRST_LOGIN_COMPLETED
      )?.value == 'true';
    if (employeeData && !firstLoginCompleted) {
      dispatch(
        updateEmployeeUserPreference({
          body: {
            preference_name: EPreference.FIRST_LOGIN_COMPLETED,
            type: 'boolean',
            value: 'true',
            employee_id: employeeData?.employee_id,
          },
        })
      );
    }
    const uiGuideCompleted =
      employeeData?.preferences?.find(
        (item) => item.preference_name === EPreference.UI_GUIDE_COMPLETED
      )?.value == 'true';
    if (employeeData && !uiGuideCompleted) {
      dispatch(
        updateEmployeeUserPreference({
          body: {
            preference_name: EPreference.UI_GUIDE_COMPLETED,
            type: 'boolean',
            value: 'true',
            employee_id: employeeData?.employee_id,
          },
        })
      );
    }
    dispatch(setIntroCompleted());
    dispatch(setTourCompleted());
  };

  useEffect(() => {
    if (updatePreferenceStatus && employeeData) {
      dispatch(getCareerCompassEmployeeData());
      dispatch(resetUpdatePreferenceStatus());
    }
  }, [updatePreferenceStatus, employeeData, dispatch]);

  const loadDynamicPageContants = useCallback(() => {
    dispatch(resetIntroCompleted());
    dispatch(getDynamicPageContents());
  }, [dispatch]);

  useEffect(() => {
    if (employeeData) {
      if (
        !employeeData?.preferences ||
        employeeData?.preferences?.length === 0
      ) {
        loadDynamicPageContants();
      } else {
        const firstLoginCompleted = employeeData?.preferences?.find(
          (item) => item.preference_name === EPreference.FIRST_LOGIN_COMPLETED
        );
        if (!firstLoginCompleted || firstLoginCompleted?.value === 'false') {
          loadDynamicPageContants();
        }
      }
    }
  }, [employeeData, loadDynamicPageContants]);

  const handleIntroCardClose = (value: boolean) => {
    dispatch(setIntroCompleted());
    if (value) {
      dispatch(resetTourCompleted());
    } else {
      updateUserPreference();
    }
  };

  return (
    <Fragment>
      <div className='career-path-container'>
        <div className='career-path-practices'>
          <div className='career-path-practices-sidebar'>
            <PracticeList />
          </div>
          <div className='career-path-practices-container'>
            <PracticeCareer />
          </div>
        </div>
        <div className='career-path-success-stories'>
          <SuccessStory />
        </div>
      </div>
      {dynamicPageContents.length > 0 && !introCompleted && (
        <IntroCard
          showModal={!introCompleted}
          onClose={(value) => {
            handleIntroCardClose(value);
          }}
          userName={employeeData?.name ?? 'Employee'}
          dynamicPageContents={dynamicPageContents}
        />
      )}
      {!tourCompleted && <CareerTour />}
    </Fragment>
  );
};
