import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from '@azure/msal-react';
import React, { useEffect } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { Layout } from './components';
import { useAppDispatch, useAppSelector } from './hooks/redux-hooks';
import useTokenRefresh from './hooks/token-refresh';
import { Auth } from './modules/Auth';
import { loginRequest, logoutRequest } from './modules/Auth/Auth.config';
import { setAvatar, setGraph, setLogout } from './redux/slice/app';
import { callMsGraph, callMsGraphPhoto } from './utils/graph';
import { clearLocalStorage } from './utils/local-storage';
import { clearSessionStorage } from './utils/session-storage';

const App: React.FC = () => {
  useTokenRefresh();
  const dispatch = useAppDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const { logout, graph } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (logout) {
      clearSessionStorage();
      clearLocalStorage();
      try {
        instance.logoutRedirect(logoutRequest);
      } catch (error) {
        console.error('SignOut Error: ', error);
      }
    }
  }, [logout, instance, dispatch]);

  useEffect(() => {
    if (account && graph?.id?.length == 0) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((response: any) => {
          if (response) {
            dispatch(setLogout(false));
            callMsGraph(response.accessToken).then((result: any) => {
              if (result) {
                dispatch(setGraph(result));
              }
            });
            callMsGraphPhoto(response.accessToken).then((_response: any) => {
              if (_response && _response.status === 200) {
                _response.blob().then((data: any) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(data);
                  reader.onload = () =>
                    dispatch(setAvatar(reader.result?.toString() || ''));
                });
              }
            });
          }
        });
    }
  }, [account, graph.id, instance, dispatch]);

  return (
    <div id='app'>
      <AuthenticatedTemplate>
        <Layout />
        <ScrollRestoration />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Auth />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default App;
