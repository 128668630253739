import { msalInstance } from '..';
import { graphConfig, loginRequest } from '../modules/Auth/Auth.config';

export const validateAddToken = async (accessToken?: string) => {
  if (accessToken) {
    return accessToken;
  }
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });
  return response.accessToken;
};

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken: string) {
  accessToken = await validateAddToken(accessToken);
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}

export async function callMsGraphPhoto(accessToken: string) {
  accessToken = await validateAddToken(accessToken);
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Content-type', 'image/jpeg');

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(`${graphConfig.graphMeEndpoint}/photo/$value`, options)
    .then((response: any) => response)
    .catch((error) => console.error(error));
}
