import React, { useLayoutEffect } from 'react';

import { Carousel, Modal } from '../../../../lib';
import { IDynamicPageContent } from '../../../../views/interfaces';
import './Intro-Card.css';

export interface IntroCardProps {
  showModal: boolean;
  onClose: (value: boolean) => void;
  userName: string;
  dynamicPageContents: IDynamicPageContent[];
}

export const IntroCard: React.FC<IntroCardProps> = ({
  showModal = false,
  onClose = () => {},
  userName = 'Employee',
  dynamicPageContents = [],
}) => {
  const onCancel = (e: any) => {
    onClose(false);
  };

  const onOk = (e: any) => {
    onClose(true);
  };

  useLayoutEffect(() => {
    const takeTheTourButton = document.getElementById('take-the-tour-btn');
    const exploreOnYourOwnButton = document.getElementById(
      'explore-on-your-own-btn'
    );
    const updateUserName = document.getElementById('update-username');

    if (updateUserName) {
      updateUserName.innerText = ' ' + userName;
    }

    if (takeTheTourButton) {
      takeTheTourButton.addEventListener('click', (e) => {
        onOk(e);
      });
    }

    if (exploreOnYourOwnButton) {
      exploreOnYourOwnButton.addEventListener('click', (e) => {
        onCancel(e);
      });
    }
  });

  return (
    <Modal
      open={showModal}
      footer={null}
      className='first-time-login-popup'
      width={'auto'}
      onCancel={onCancel}
      onOk={onOk}
    >
      <Carousel
        arrows={true}
        style={{ height: '560px', width: '810px' }}
        dots={{ className: 'intro-carousel' }}
        arrowColor='#fe5000'
      >
        {dynamicPageContents.map((item, index) => (
          <div
            key={(index + 1).toString()}
            dangerouslySetInnerHTML={{ __html: item.page_content }}
          />
        ))}
      </Carousel>
    </Modal>
  );
};
