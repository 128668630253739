import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  exportEmployeeTableDataService,
  getEmployeeFilterDataService,
  getEmployeeTableDataService,
} from "../../services/employee";
import { ReduxApiService } from "../../views/enums";
import { IEmployeeFilter, IEmployeeState } from "../../views/interfaces";

export const defaultFilterData: IEmployeeFilter = {
  accounts: [],
  verticals: [],
  practices: [],
  projects: [],
  opportunities: [],
  locations: [],
  legalEntities: [],
  specializations: [],
  roles: [],
  levels: [],
  employeeStatus: [],
};

const initialState: IEmployeeState = {
  loading: 0,
  filterData: null,
  sortData: [],
  tableData: [],
  totalCount: 0,
};

export const getEmployeeFilterData = createAsyncThunk(
  ReduxApiService.getEmployeeFilterData,
  async () => {
    const result = await getEmployeeFilterDataService();
    return result;
  }
);

export const getEmployeeTableData = createAsyncThunk(
  ReduxApiService.getEmployeeTableData,
  async (request: { body: any; params: any }) => {
    const result = await getEmployeeTableDataService(
      request.body,
      request.params
    );
    return result;
  }
);

export const exportEmployeeTableData = createAsyncThunk(
  ReduxApiService.exportEmployeeTableData,
  async (request: { body: any; params?: any }) => {
    const result = await exportEmployeeTableDataService(
      request.body,
      request.params
    );
    return result;
  }
);

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeFilterData.pending, (state, action) => {
        state.loading += 1;
        state.filterData = initialState.filterData;
        state.sortData = initialState.sortData;
      })
      .addCase(getEmployeeFilterData.rejected, (state, action) => {
        state.loading -= 1;
        state.filterData = initialState.filterData;
        state.sortData = initialState.sortData;
      })
      .addCase(getEmployeeFilterData.fulfilled, (state, action) => {
        state.loading -= 1;
        state.filterData = action.payload.filter;
        state.sortData = action.payload.sort;
      })
      .addCase(getEmployeeTableData.pending, (state, action) => {
        state.loading += 1;
        state.tableData = initialState.tableData;
        state.totalCount = initialState.totalCount;
      })
      .addCase(getEmployeeTableData.rejected, (state, action) => {
        state.loading -= 1;
        state.tableData = initialState.tableData;
        state.totalCount = initialState.totalCount;
      })
      .addCase(getEmployeeTableData.fulfilled, (state, action) => {
        state.loading -= 1;
        state.tableData = action.payload.tableData;
        state.totalCount = action.payload.totalCount;
      });
  },
});

export const employeeReducer = employeeSlice.reducer;
