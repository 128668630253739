import React, { CSSProperties } from "react";

import { Modal as AntdModal, ModalProps as AntdModalProps } from "antd";
import "./Modal.css";

export interface ModalProps {
  open: boolean;
  children: React.ReactNode;
  title?: React.ReactNode | string;
  centered?: boolean;
  style?: CSSProperties;
  className?: string;
  onOk: AntdModalProps["onOk"];
  onCancel: AntdModalProps["onCancel"];
  footer: React.ReactNode | null;
  closable?: boolean;
  destroyOnClose?: boolean;
  width?: AntdModalProps["width"];
  styles?: AntdModalProps["styles"];
  maskClosable?: AntdModalProps["maskClosable"];
  getContainer?: AntdModalProps["getContainer"];
}

export const Modal: React.FC<ModalProps> = ({
  title = <></>,
  open = false,
  children,
  centered = true,
  style,
  className,
  onOk = () => {},
  onCancel = () => {},
  footer,
  closable = false,
  destroyOnClose = true,
  width,
  styles,
  maskClosable = false,
  getContainer,
}) => {
  return (
    <AntdModal
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      centered={centered}
      closable={closable}
      style={style}
      className={className}
      destroyOnClose={destroyOnClose}
      width={width}
      styles={styles}
      maskClosable={maskClosable}
      getContainer={getContainer}
    >
      {children}
    </AntdModal>
  );
};
