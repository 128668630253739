import React from "react";
import { ReactComponent as DemandOpenIcon } from "../../../../assets/demand-open.svg";
import { ReactComponent as DownloadReportIcon } from "../../../../assets/downloadReport.svg";
import { SVGIcon } from "../../../../components/SVG-Icon";
import { Tooltip } from "../../../../lib";
import { IReportsCard } from "../../../../views/interfaces";
import "./Report-Card.css";

export interface ReportCardProps {
  data?: IReportsCard;
  getReport?: (url: string) => void;
}

export const ReportCard: React.FC<ReportCardProps> = ({ data, getReport }) => {
  return (
    <div className="report-card-container">
      <div className="report-title">{data?.title}</div>
      <div className="report-details-container">
        {data?.children?.map((item, index) => (
          <div className="report-details" key={index}>
            <div className="report-values">
              <div className="icon-container">
                <SVGIcon SVGElementIcon={DemandOpenIcon} color="#FE5000" />
              </div>
              {item.title}
            </div>
            <Tooltip title="Export Data" placement="bottom">
              <div
                onClick={() => {
                  if (getReport) getReport("openDemandReport");
                }}
              >
                <SVGIcon SVGElementIcon={DownloadReportIcon} />
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};
