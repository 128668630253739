import React, { Fragment, useEffect, useState } from "react";
import { IFilterOption, TFilterDisplay } from "../../views/interfaces";
import { FilterOptionItem } from "../Filter-Option-Item";
import "./Filter-Option.css";

const minAdvancedShortCount: number = 4;
const maxAdvancedShortCount: number = 8;

export interface FilterOptionProps {
  data: IFilterOption[];
  displayType?: TFilterDisplay;
  showCount?: boolean;
  action?: (
    label: any,
    value: string,
    checked: boolean,
    filterLabel: string
  ) => void;
  openFullViewFilter?: () => void;
  filterType?: "group" | "item";
  setDisplayType?: (displayType: TFilterDisplay) => void;
}

export const FilterOption: React.FC<FilterOptionProps> = ({
  data = [],
  displayType = "basic",
  showCount = false,
  action = () => {},
  openFullViewFilter = () => {},
  filterType = "item",
  setDisplayType = () => {},
}) => {
  const [optionCount, setOptionCount] = useState<number>(0);

  const toggleFullViewFilter = () => {
    if (optionCount <= maxAdvancedShortCount) {
      setDisplayType(
        displayType == "advanced-short" ? "advanced-full" : "advanced-short"
      );
    } else {
      openFullViewFilter();
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      let count = data.length;
      if (filterType === "group") {
        count += data.reduce((acc, item) => {
          if (item.children) {
            return acc + item.children.length;
          }
          return acc;
        }, 0);
      }
      setOptionCount(count);
    }
  }, [data, filterType]);

  const onSelectionChange = (
    label: any,
    value: string,
    checked: boolean,
    filterLabel: string
  ) => {
    action(label, value, checked, filterLabel);
  };

  return (
    <Fragment>
      <div className={`filter-option ${displayType}-view`.trim()}>
        {data.map((item, idx: number) => {
          return (
            <Fragment key={idx}>
              <FilterOptionItem
                data={item}
                showCount={showCount}
                action={(label, value, checked) =>
                  onSelectionChange(label, value, checked, item.label)
                }
                addExtraPaddingRight={optionCount > 8}
                filterType={filterType}
              />
              {item.children && item.children.length > 0 && (
                <div className="filter-option-group">
                  {item.children.map((child, _idx: number) => {
                    return (
                      <FilterOptionItem
                        key={`${idx}-${_idx}`}
                        data={child}
                        showCount={showCount}
                        action={(label, value, checked) =>
                          onSelectionChange(label, value, checked, item.label)
                        }
                        addExtraPaddingRight={optionCount > 8}
                        filterType={filterType}
                      />
                    );
                  })}
                </div>
              )}
            </Fragment>
          );
        })}
      </div>

      {["advanced-short", "advanced-full"].includes(displayType) &&
        optionCount > minAdvancedShortCount && (
          <div className="filter-advanced-item-last">
            <p className="text-wrapper-1" onClick={toggleFullViewFilter}>
              {displayType == "advanced-full"
                ? "Show Less"
                : `Show All (${optionCount - minAdvancedShortCount})`}
            </p>
          </div>
        )}
    </Fragment>
  );
};
