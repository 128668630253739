import React, { CSSProperties } from "react";

import AntdSwitch, { SwitchSize } from "antd/es/switch";
import "./Switch.css";

export interface SwitchProps {
  defaultChecked?: boolean;
  disabled?: boolean;
  size?: SwitchSize;
  onChange?: (checked: boolean) => void;
  id?: string;
  className?: string;
  rootClassName?: string;
  style?: CSSProperties;
  value?: boolean;
  defaultValue?: boolean;
}

export const Switch: React.FC<SwitchProps> = ({
  defaultChecked = false,
  disabled = false,
  size = "default",
  onChange = (checked = false) => {},
  id,
  className,
  rootClassName,
  style,
  value = false,
  defaultValue = false,
}) => {
  const onSelectionChange = (checked: boolean) => {
    onChange(checked);
  };
  return (
    <AntdSwitch
      defaultChecked={defaultChecked}
      disabled={disabled}
      onChange={onSelectionChange}
      size={size}
      id={id}
      className={className}
      rootClassName={rootClassName}
      style={style}
      value={value}
      defaultValue={defaultValue}
    />
  );
};
