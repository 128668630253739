import { ApiRoutesConfig, axiosInstance } from ".";
import { EDashboardFeatureCode, EDashBoardModule } from "../views/enums";
import { IDashboardCard } from "../views/interfaces";

export const getActedDemandCardMappingService = (
  data: {
    total?: number;
    riw?: number;
    internal?: number;
    external?: number;
    noStaffing?: number;
    notActed?: number;
    supplyRisk?: number;
    toBeApproved?: number;
  },
  permission: {
    total?: string;
    riw?: string;
    internal?: string;
    external?: string;
    noStaffing?: string;
    notActed?: string;
    supplyRisk?: string;
    toBeApproved?: string;
  } = {
    total: "",
    riw: "",
    internal: "",
    external: "",
    noStaffing: "",
    notActed: "",
    supplyRisk: "",
    toBeApproved: "",
  }
): IDashboardCard => {
  let bottom: any[] = [];
  if (permission.riw || permission.toBeApproved){
    if (permission.riw) {
      bottom.push({
        label: "RIW",
        value: data?.riw || 0,
        extra: permission.internal
          ? {
              label: "Internal",
              value: data?.internal || 0,
              url: "/open-demand",
              filter: {
                origin: EDashBoardModule.DASHBOARD,
                id: EDashboardFeatureCode.OPEN_DEMANDS_INTERNAL_CNT,
              },
              permission: permission.internal,
            }
          : null,
        url: "/open-demand",
        filter: {
          origin: EDashBoardModule.DASHBOARD,
          id: EDashboardFeatureCode.OPEN_DEMANDS_RIW_CNT,
        },
        permission: permission.riw,
      });
      bottom.push({
        label: "External",
        value: data?.external || 0,
        url: "/open-demand",
        filter: {
          origin: EDashBoardModule.DASHBOARD,
          id: EDashboardFeatureCode.OPEN_DEMANDS_EXTERNAL_CNT,
        },
        permission: permission.external,
      });
      if (permission.toBeApproved) {
        bottom.push({
          label: "To Be Approved",
          value: data?.toBeApproved || 0,
          url: "/open-demand",
          filter: {
            origin: EDashBoardModule.DASHBOARD,
            id: EDashboardFeatureCode.OPEN_DEMANDS_TO_BE_APPROVED_CNT,
          },
          permission: permission.toBeApproved,
        });
      } else {
        bottom.push({
          label: "No Staffing",
          value: data?.noStaffing || 0,
          url: "/open-demand",
          filter: {
            origin: EDashBoardModule.DASHBOARD,
            id: EDashboardFeatureCode.OPEN_DEMANDS_NO_STAFFING_CNT,
          },
          permission: permission.noStaffing,
        });
      }
    }
  } else if (permission.notActed || permission.supplyRisk) {
      bottom.push({
        label: "Internal",
        value: data?.internal || 0,
        extra: permission.external
          ? {
              label: "External",
              value: data?.external || 0,
              url: "/open-demand",
              filter: {
                origin: EDashBoardModule.DASHBOARD,
                id: EDashboardFeatureCode.OPEN_DEMANDS_EXTERNAL_CNT,
              },
              permission: permission.external,
            }
          : null,
        url: "/open-demand",
        filter: {
          origin: EDashBoardModule.DASHBOARD,
          id: EDashboardFeatureCode.OPEN_DEMANDS_INTERNAL_CNT,
        },
        permission: permission.internal,
      });
      bottom.push({
        label: "No Staffing",
        value: data?.noStaffing || 0,
        extra: {
          label: "Not Acted",
          value: data?.notActed || 0,
          url: "/open-demand",
          filter: {
            origin: EDashBoardModule.DASHBOARD,
            id: EDashboardFeatureCode.OPEN_DEMANDS_NOT_ACTED_CNT,
          },
          permission: permission.notActed,
        },
        url: "/open-demand",
        filter: {
          origin: EDashBoardModule.DASHBOARD,
          id: EDashboardFeatureCode.OPEN_DEMANDS_NO_STAFFING_CNT,
        },
        permission: permission.noStaffing,
      });
      bottom.push({
        label: "Supply Risk",
        value: data?.supplyRisk || 0,
        url: "/open-demand",
        filter: {
          origin: EDashBoardModule.DASHBOARD,
          id: EDashboardFeatureCode.OPEN_DEMANDS_SUPPLY_RISK_CNT,
        },
        permission: permission.toBeApproved,
      });
    }

  return {
    top: {
      label: "Open Demands",
      value: data?.total || 0,
      url: "/open-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.OPEN_DEMANDS_CARD,
      },
      permission: permission.total,
    },
    bottom,
  };
};

export const getNotActedDemandCardMappingService = (
  data: {
    total?: number;
    overdue?: number;
    totalDraft?: number;
    startIn30Days?: number;
    prjDemand?: number;
    oppDemand?: number;
  },
  permission: {
    total?: string;
    overdue?: string;
    totalDraft?: string;
    startIn30Days?: string;
    prjDemand?: string;
    oppDemand?: string;
  } = {
    total: "",
    overdue: "",
    totalDraft: "",
    startIn30Days: "",
    prjDemand: "",
    oppDemand: "",
  }
): IDashboardCard => {
  let center: any[] = [];
  let bottom: any[] = [];

  if (permission.prjDemand) {
    center.push({
      label: "",
      value: data?.prjDemand || 0,
      url: "/open-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.NOT_ACTED_PRJ_DEMAND_CNT,
      },
      permission: permission.prjDemand,
    });
  }
  if (permission.oppDemand) {
    center.push({
      label: "",
      value: data?.oppDemand || 0,
      url: "/open-demand", //should be changed after mvp1
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.NOT_ACTED_OPP_DEMAND_CNT,
      },
      permission: permission.oppDemand,
    });
  }

  if (permission.overdue) {
    bottom.push({
      label: "External",
      value: data?.overdue || 0,
      url: "/open-demand", //should be changed after mvp1
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.NOT_ACTED_OVERDUE_CNT,
      },
      permission: permission.overdue,
    });
  }
  if (permission.startIn30Days) {
    bottom.push({
      label: "Starting in 30 Days",
      value: data?.startIn30Days || 0,
      url: "/open-demand", //should be changed after mvp1
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.NOT_ACTED_STARTS_IN_30_CNT,
      },
      permission: permission.startIn30Days,
    });
  }
  if (permission.totalDraft) {
    bottom.push({
      label: "Draft Demands",
      value: data?.totalDraft || 0,
      url: "/open-demand", //should be changed after mvp1
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.NOT_ACTED_TOTAL_DRAFT_CNT,
      },
      permission: permission.totalDraft,
    });
  }

  return {
    top: {
      label: "Not Acted",
      value: data?.total || 0,
      url: "/open-demand", //should be changed after mvp1
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.NOT_ACTED_CARD,
      },
      permission: permission.total,
    },
    center,
    bottom,
  };
};

export const getBanchCardMappingService = (
  data: {
    total?: number;
    onsite?: number;
    offshore?: number;
    staffingPlanned?: number;
  },
  permission: {
    total?: string;
    onsite?: string;
    offshore?: string;
    staffingPlanned?: string;
  } = {
    total: "",
    onsite: "",
    offshore: "",
    staffingPlanned: "",
  }
): IDashboardCard => {
  let bottom = [];
  if (permission.onsite) {
    bottom.push({
      label: "Onsite Resource",
      value: data?.onsite || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.BENCH_ONSITE_CNT,
      },
      permission: permission.onsite,
    });
  }
  if (permission.offshore) {
    bottom.push({
      label: "Offshore Resource",
      value: data?.offshore || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.BENCH_OFFSHORE_CNT,
      },
      permission: permission.offshore,
    });
  }
  if (permission.staffingPlanned) {
    bottom.push({
      label: "staffing Planned",
      value: data?.staffingPlanned || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.BENCH_STAFFING_PLANNED_CNT,
      },
      permission: permission.staffingPlanned,
    });
  }
  return {
    top: {
      label: "Bench",
      value: data?.total || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.BENCH_CARD,
      },
      permission: permission.total,
    },
    bottom,
  };
};

export const getAllocationWithIn60DaysCardMappingService = (
  data: {
    total?: number;
    onsite?: number;
    offshore?: number;
    partial?: number;
  },
  permission: {
    total?: string;
    onsite?: string;
    offshore?: string;
    partial?: string;
  } = {
    total: "",
    onsite: "",
    offshore: "",
    partial: "",
  }
): IDashboardCard => {
  let bottom = [];
  if (permission.onsite) {
    bottom.push({
      label: "Onsite Allocation",
      value: data?.onsite || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.ALLOC_ENDS_IN_60_ONSITE_CNT,
      },
      permission: permission.onsite,
    });
  }
  if (permission.offshore) {
    bottom.push({
      label: "Offshore Allocation",
      value: data?.offshore || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.ALLOC_ENDS_IN_60_OFFSHORE_CNT,
      },
      permission: permission.offshore,
    });
  }
  if (permission.partial) {
    bottom.push({
      label: "Partial Allocation",
      value: data?.partial || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.ALLOC_ENDS_IN_60_PARTIAL_CNT,
      },
      permission: permission.partial,
    });
  }

  return {
    top: {
      label: "Allocation Ending in 60 days",
      value: data?.total || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.ALLOC_ENDS_IN_60_CARD,
      },
      permission: permission.total,
    },
    bottom,
  };
};

export const getDraftDemandCardMappingService = (
  data: {
    total?: number;
    overdue?: number;
    startIn30Days?: number;
  },
  permission: {
    total?: string;
    overdue?: string;
    startIn30Days?: string;
  } = {
    total: "",
    overdue: "",
    startIn30Days: "",
  }
): IDashboardCard => {
  let bottom = [];
  if (permission.overdue) {
    bottom.push({
      label: "Overdue Demands",
      value: data?.overdue || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.DRAFT_DEMANDS_OVERDUE_CNT,
      },
      permission: permission.overdue,
    });
  }
  if (permission.startIn30Days) {
    bottom.push({
      label: "Starting in 30 Days",
      value: data?.startIn30Days || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.DRAFT_DEMANDS_STARTS_IN_30_CNT,
      },
      permission: permission.startIn30Days,
    });
  }
  return {
    top: {
      label: "Draft Demands",
      value: data?.total || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.DRAFT_DEMANDS_CARD,
      },

      permission: permission.total,
    },
    bottom,
  };
};

export const getInternalActionCardMappingService = (
  data: {
    total?: number;
    reviewProposal?: number;
    hmInterview?: number;
    clientInterview?: number;
  },
  permission: {
    total?: string;
    reviewProposal?: string;
    hmInterview?: string;
    clientInterview?: string;
  } = {
    total: "",
    reviewProposal: "",
    hmInterview: "",
    clientInterview: "",
  }
): IDashboardCard => {
  let bottom = [];
  if (permission.reviewProposal) {
    bottom.push({
      label: "Review Proposal",
      value: data?.reviewProposal || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.INTERNAL_REVIEW_PROPOSAL_CNT,
      },
      permission: permission.reviewProposal,
    });
  }
  if (permission.hmInterview) {
    bottom.push({
      label: "HM Interview",
      value: data?.hmInterview || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.INTERNAL_HM_INTERVIEW_CNT,
      },
      permission: permission.hmInterview,
    });
  }
  if (permission.clientInterview) {
    bottom.push({
      label: "Client Interview",
      value: data?.clientInterview || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.INTERNAL_CLIENT_INTERVIEW_CNT,
      },
      permission: permission.clientInterview,
    });
  }
  return {
    top: {
      label: "Internal Fulfillment Actions",
      value: data?.total || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.INTERNAL_CARD,
      },
      permission: permission.total,
    },
    bottom,
  };
};

export const getPortfolioCardMappingService = (
  data: {
    total?: number;
    project?: number;
    opportunity?: number;
    allocEndsIn60Days?: number;
  },
  permission: {
    total?: string;
    project?: string;
    opportunity?: string;
    allocEndsIn60Days?: string;
  } = {
    total: "",
    project: "",
    opportunity: "",
    allocEndsIn60Days: "",
  }
): IDashboardCard => {
  let bottom = [];
  if (permission.project) {
    bottom.push({
      label: "Projects",
      value: data?.project || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.PORTFOLIO_PROJECT_CNT,
      },
      permission: permission.project,
    });
  }
  if (permission.opportunity) {
    bottom.push({
      label: "Opportunities",
      value: data?.opportunity || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.PORTFOLIO_OPPORTUNITY_CNT,
      },
      permission: permission.opportunity,
    });
  }
  if (permission.project) {
    bottom.push({
      label: "Alloc ends in...",
      value: data?.allocEndsIn60Days || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.PORTFOLIO_ALLOC_ENDS_IN_60_CNT,
      },
      permission: permission.allocEndsIn60Days,
    });
  }
  return {
    top: {
      label: "My Portfolio",
      value: data?.total || 0,
      url: "/draft-demand",
      filter: {
        origin: EDashBoardModule.DASHBOARD,
        id: EDashboardFeatureCode.PORTFOLIO_CARD,
      },
      permission: permission.total,
    },
    bottom,
  };
};

export const getFilterDataService = async () => {
  try {
    const response = await axiosInstance.get(ApiRoutesConfig.getFilterData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUiFilterItemsService = async (params: any) => {
  try {
    const response = await axiosInstance.get(ApiRoutesConfig.getUiFilterItems, {
      params,
    });
    return response.data.data.filterList;
  } catch (error) {
    throw error;
  }
};

export const getCardDataService = async (body: any, params: any) => {
  try {
    const response = await axiosInstance.post(
      ApiRoutesConfig.getCardData,
      body,
      { params }
    );
    return [
      getActedDemandCardMappingService(
        response.data.data.open,
        response.data.permission?.open
      ),
      getNotActedDemandCardMappingService(
        response.data.data.notActed,
        response.data.permission?.notActed
      ),

      getBanchCardMappingService(
        response.data.data.bench,
        response.data.permission?.bench
      ),
      getAllocationWithIn60DaysCardMappingService(
        response.data.data.allocEndsIn60Days,
        response.data.permission?.allocEndsIn60Days
      ),
      getDraftDemandCardMappingService(
        response.data.data.draft,
        response.data.permission?.draft
      ),
      getInternalActionCardMappingService(
        response.data.data.internalFulfillAction,
        response.data.permission?.internalFulfillAction
      ),
      getPortfolioCardMappingService(
        response.data.data.myPortfolio,
        response.data.permission?.myPortfolio
      ),
    ];
  } catch (error) {
    throw error;
  }
};
