import React, { Fragment, useState } from 'react';
import sucessStoriesBackground from '../../../../assets/success-stories.svg';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { Carousel } from '../../../../lib';
import './Success-Story.css';

export interface SuccessStoryProps {}

export const SuccessStory: React.FC<SuccessStoryProps> = () => {
  const { successStories } = useAppSelector((state) => state.careerPath);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <Fragment>
      {successStories && successStories.length > 0 && (
        <div className='success-story'>
          <p className='success-story-title'>Success Stories</p>
          <div className='success-story-main'>
            <div className='success-story-background'>
              <img src={sucessStoriesBackground} alt='No Image' />
            </div>
            <div className='success-story-foreground'>
              <Carousel
                arrows={true}
                fade={true}
                prevArrowStyle={{
                  position: 'absolute',
                  bottom: '12px',
                  right: '60px',
                  opacity: currentIndex === 0 ? 0.5 : 1,
                  pointerEvents: currentIndex === 0 ? 'none' : 'auto',
                }}
                nextArrowStyle={{
                  position: 'absolute',
                  bottom: '12px',
                  right: '10px',
                  opacity: currentIndex === successStories.length - 1 ? 0.5 : 1,
                  pointerEvents:
                    currentIndex === successStories.length - 1
                      ? 'none'
                      : 'auto',
                }}
                arrowColor='#ffffff'
                arrowWidth={36}
                arrowHeight={36}
                beforeChange={(from, to) => setCurrentIndex(to)}
              >
                {successStories.map((story, index) => (
                  <div
                    className='success-story-carousel'
                    key={(index + 1).toString()}
                  >
                    <div className='success-story-image'>
                      <img src={story?.picture_url} alt='No Image' />
                    </div>
                    <div className='success-story-content'>
                      <p className='success-story-message'>
                        {story?.story_message}
                      </p>
                      <p className='success-story-owner'>
                        <span className='success-story-owner-name'>
                          {story?.employee_name},{' '}
                        </span>
                        {story?.role}
                      </p>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
