import { msalInstance } from '..';
import { loginRequest } from '../modules/Auth/Auth.config';

export const fetchToken = async () => {
  try {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      /*
       * User is not signed in. Throw error or wait for user to login.
       * Do not attempt to log a user in outside of the context of MsalProvider
       */
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: activeAccount || accounts[0],
    });

    return {
      idtoken: response.idToken,
      accessToken: response.accessToken,
    };
  } catch (error) {
    console.error('Token acquisition failed: ', error);
    return null;
  }
};
