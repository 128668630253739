import React from "react";

import "./Skill-Profile.css";

export interface SkillProfileProps {}

export const SkillProfile: React.FC<SkillProfileProps> = () => {
  return <div>Skill Profile</div>;
};

