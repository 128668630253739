import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import { Sidebar } from '../components';
import { RouterUrl } from '../views/enums';

const Error = React.lazy(() =>
  import('../components/Error').then((module) => ({
    default: module.Error,
  }))
);

// const AllEmployees = React.lazy(() =>
//   import("../modules/All-Employees").then((module) => ({
//     default: module.AllEmployees,
//   }))
// );

const Dashboard = React.lazy(() =>
  import('../modules/Dashboard').then((module) => ({
    default: module.Dashboard,
  }))
);

// const DraftDemands = React.lazy(() =>
//   import("../modules/Draft-Demands").then((module) => ({
//     default: module.DraftDemands,
//   }))
// );

const OpenDemands = React.lazy(() =>
  import('../modules/Open-Demands').then((module) => ({
    default: module.OpenDemands,
  }))
);

const Reports = React.lazy(() =>
  import('../modules/Reports').then((module) => ({
    default: module.Reports,
  }))
);

const CareerPath = React.lazy(() =>
  import('../modules/Career-Path').then((module) => ({
    default: module.CareerPath,
  }))
);

const CompentencyDevelopment = React.lazy(() =>
  import('../modules/Compentency-Development').then((module) => ({
    default: module.CompentencyDevelopment,
  }))
);

// const InternalMobility = React.lazy(() =>
//   import('../modules/Internal-Mobility').then((module) => ({
//     default: module.InternalMobility,
//   }))
// );

// const SkillProfile = React.lazy(() =>
//   import("../modules/Skill-Profile").then((module) => ({
//     default: module.SkillProfile,
//   }))
// );

export const router = createBrowserRouter([
  {
    path: '/',
    caseSensitive: true,
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Dashboard />,
        errorElement: <Error />,
      },
      {
        path: RouterUrl.dashboard,
        caseSensitive: true,
        element: <Dashboard />,
        errorElement: <Error />,
      },
      // {
      //   path: "draft-demand",
      //   caseSensitive: true,
      //   element: <DraftDemands />,
      //   errorElement: <Error />,
      // },
      {
        path: RouterUrl.openDemands,
        caseSensitive: true,
        element: <OpenDemands />,
        errorElement: <Error />,
      },
      // {
      //   path: "employee",
      //   caseSensitive: true,
      //   element: <AllEmployees />,
      //   errorElement: <Error />,
      // },
      {
        path: RouterUrl.reports,
        caseSensitive: true,
        element: <Reports />,
        errorElement: <Error />,
      },
      {
        caseSensitive: true,
        path: RouterUrl.careerPath,
        element: <CareerPath />, // Career Path tab
        errorElement: <Error />,
      },
      // {
      //   path: RouterUrl.skillProfile,
      //   caseSensitive: true,
      //   element: <SkillProfile />, // Skill Profile tab
      //   errorElement: <Error />,
      // },
      {
        path: RouterUrl.competencyDevelopment,
        caseSensitive: true,
        element: <CompentencyDevelopment />, // Competency Development tab
        errorElement: <Error />,
      },
      // {
      //   path: RouterUrl.internalMobility,
      //   caseSensitive: true,
      //   element: <InternalMobility />, // Internal Mobility tab
      //   errorElement: <Error />,
      // },
    ],
  },
  {
    path: '*',
    element: (
      <>
        <AuthenticatedTemplate>
          <Sidebar />
          <Error />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Error />
        </UnauthenticatedTemplate>
      </>
    ),
  },
]);
