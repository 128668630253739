import React, { useCallback, useEffect } from 'react';
import { ReactComponent as AceTriangleIcon } from '../../assets/Ace Triangle.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { Button, Carousel } from '../../lib';
import { getLmsCourses } from '../../redux/slice/compentency-development';
import './Compentency-Development.css';

export interface CompentencyDevelopmentProps {}

export const CompentencyDevelopment: React.FC = () => {
  const dispatch = useAppDispatch();
  const { employeeData } = useAppSelector((state) => state.careerPath);
  const { lmsCourses } = useAppSelector(
    (state) => state.compentencyDevelopment
  );

  useEffect(() => {
    if (employeeData && employeeData.level) {
      dispatch(getLmsCourses({ level: employeeData.level }));
    }
  }, [employeeData, dispatch]);

  const openLmsLink = useCallback(
    (link: string | null, open_in_type: string = '_blank') => {
      if (link) {
        window.open(link, open_in_type, 'noopener,noreferrer');
      }
    },
    []
  );

  return (
    <div className='enhance-your-skills'>
      <div className='carousel-container'>
        <Carousel
          autoplay={true}
          arrows={true}
          arrowColor='#ffffff'
          arrowWidth={36}
          arrowHeight={36}
        >
          <div className='carousel-item carousel-item-red' id='ace-framework'>
            <div className='carousel-svg-top-left'>
              <AceTriangleIcon />
            </div>
            <div className='carousel-svg-bottom-right'>
              <AceTriangleIcon />
            </div>
            <div className='ACE-content'>
              <div className='carousel-header'>
                <p className='content-header'>ACE FRAMEWORK</p>
              </div>
              <div className='carousel-body'>
                <p className='content-body-page-1'>
                  The ACE framework is a simple yet powerful model that defines
                  the culture and leadership at Tredence - A common language and
                  behavior exhibited by every Tredencian to act as an Advisor,
                  Coach / Captain, and Entrepreneur.
                </p>
              </div>
            </div>
          </div>
          <div
            className='carousel-item carousel-item-green'
            id='advisor-to-customer'
          >
            <div className='carousel-svg-top-left'>
              <AceTriangleIcon />
            </div>
            <div className='carousel-svg-bottom-right'>
              <AceTriangleIcon />
            </div>
            <div className='Advisor-content'>
              <div className='carousel-header'>
                <p className='content-header'>
                  <span className='font-600'>A - Advisor</span> to the customer
                </p>
              </div>
              <div className='carousel-body'>
                <p className='content-body-page-2'>
                  Create impactful solutions by building high-order expertise
                  and serving our clients as advisors.
                </p>
                <div className='bullet-points'>
                  <ul className='bullet-col-1'>
                    <li>We build high order expertise</li>
                    <li>We create impactful solutions</li>
                  </ul>
                  <ul className='bullet-col-2'>
                    <li>We deliver high-quality services</li>
                    <li>We partner with complete ownership</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='carousel-item carousel-item-red' id='coach-captain'>
            <div className='carousel-svg-top-left'>
              <AceTriangleIcon />
            </div>
            <div className='carousel-svg-bottom-right'>
              <AceTriangleIcon />
            </div>
            <div className='ACE-content'>
              <div className='carousel-header'>
                <p className='content-header'>
                  <span className='font-600'>C - Coach Captain</span> to the
                  Team
                </p>
              </div>
              <div className='carousel-body'>
                <p className='content-body-page-2'>
                  Provide direction and guidance to the team, push for
                  excellence, and lead by example
                </p>
                <div className='bullet-points'>
                  <ul className='bullet-col-1'>
                    <li>We lead from the front </li>
                    <li>We are empathetic and respectful </li>
                    <li>We build effective teams</li>
                  </ul>
                  <ul className='bullet-col-2'>
                    <li>We provide actionable guidance and direction</li>
                    <li>We embrace diversity and inclusion</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className='carousel-item carousel-item-green'
            id='entrepreneur-to-business'
          >
            <div className='carousel-svg-top-left'>
              <AceTriangleIcon />
            </div>
            <div className='carousel-svg-bottom-right'>
              <AceTriangleIcon />
            </div>
            <div className='Advisor-content'>
              <div className='carousel-header'>
                <p className='content-header'>
                  <span className='font-600'>E - Entrepreneur</span> to the
                  Business
                </p>
              </div>
              <div className='carousel-body'>
                <p className='content-body-page-2'>
                  Take calculated risks, remain innovative, possess strong
                  business acumen, and strive for success.
                </p>
                <div className='bullet-points'>
                  <ul className='bullet-col-1'>
                    <li>We are creators</li>
                    <li>We take risks and deliver results</li>
                    <li>We are continuous learners</li>
                  </ul>
                  <ul className='bullet-col-2'>
                    <li>We possess a strong business acumen</li>
                    <li>We are resilient</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
      {lmsCourses.map((details) => (
        <div className='lms-container'>
          <div
            className='image-container'
            dangerouslySetInnerHTML={{ __html: details.imageUrl }}
          >
            {/* <img src={details.imageUrl} alt='No-Image' /> */}
          </div>
          <div className='details-container'>
            <div className='details'>
              <div className='header-container'>
                <p className='text-wrapper-1'>{details.title}</p>
                {details.lms_link && details.lms_link.length > 0 && (
                  <span className='text-wrapper-2'>{details.duration}</span>
                )}
              </div>
              <p className='text-wrapper-3'>{details.description}</p>
            </div>
            <div className='button-container'>
              {details.lms_link && details.lms_link.length > 0 ? (
                <Button
                  onClick={() => {
                    openLmsLink(details.lms_link, details.open_in_type);
                  }}
                  type='primary'
                >
                  Start Learning
                </Button>
              ) : (
                <p className='text-wrapper-4'>Coming Soon...</p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
