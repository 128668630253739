import React from 'react';

import type { CarouselProps as AntdCarouselProps } from 'antd';
import { Carousel as AntdCarousel } from 'antd';
import type { CarouselRef as AntdCarouselRef } from 'antd/es/carousel';
import { ReactComponent as LeftArrow } from '../../assets/carousel-left.svg';
import { ReactComponent as RightArrow } from '../../assets/carousel-right.svg';
import { SVGIcon } from '../../components';
import { SVGIconProps } from '../../components/SVG-Icon';
import './Carousel.css';

export interface CarouselProps {
  children?: React.ReactNode;
  autoplay?: boolean;
  dotPosition?: AntdCarouselProps['dotPosition'];
  afterChange?: AntdCarouselProps['afterChange'];
  infinite?: boolean;
  arrows?: boolean;
  className?: string;
  style?: React.CSSProperties;
  ref?: AntdCarouselRef;
  fade?: boolean;
  dots?: boolean | { className?: string };
  prevArrowStyle?: React.CSSProperties;
  nextArrowStyle?: React.CSSProperties;
  prevArrowIcons?: SVGIconProps['SVGElementIcon'];
  nextArrowIcons?: SVGIconProps['SVGElementIcon'];
  arrowColor?: SVGIconProps['color'];
  arrowHeight?: SVGIconProps['height'];
  arrowWidth?: SVGIconProps['width'];
  beforeChange?: (current: number, next: number) => void;
}

export const Carousel: React.FC<CarouselProps> = ({
  children,
  autoplay = false,
  dotPosition = 'bottom',
  afterChange = () => {},
  infinite = false,
  arrows = false,
  className,
  style,
  fade,
  dots = { className: 'default-carousel' },
  prevArrowStyle = { position: 'absolute', top: '45%', left: 0 },
  nextArrowStyle = { position: 'absolute', top: '45%', right: 0 },
  prevArrowIcons = LeftArrow,
  nextArrowIcons = RightArrow,
  arrowColor = '#ffffff',
  arrowWidth = 36,
  arrowHeight = 36,
  beforeChange,
}) => {
  return (
    <AntdCarousel
      autoplay={autoplay}
      infinite={infinite}
      arrows={arrows}
      dotPosition={dotPosition}
      afterChange={afterChange}
      className={className}
      style={style}
      fade={fade}
      dots={dots}
      prevArrow={
        <SVGIcon
          SVGElementIcon={prevArrowIcons}
          color={arrowColor}
          width={arrowWidth}
          height={arrowHeight}
          rootStyle={prevArrowStyle}
        />
      }
      nextArrow={
        <SVGIcon
          SVGElementIcon={nextArrowIcons}
          color={arrowColor}
          width={arrowWidth}
          height={arrowHeight}
          rootStyle={nextArrowStyle}
        />
      }
      beforeChange={beforeChange}
    >
      {children}
    </AntdCarousel>
  );
};
