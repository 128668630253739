import React, { useId } from 'react';

import { Tooltip as AntdTooltip, TooltipProps as AntdTooltipProps } from 'antd';
import { PresetColorType } from 'antd/es/_util/colors';
import { LiteralUnion } from 'antd/es/_util/type';
import { AdjustOverflow, TooltipPlacement } from 'antd/es/tooltip';
import type { placements as Placements } from 'rc-tooltip/lib/placements';
import './Tooltip.css';

export interface TooltipProps {
  title: string | React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  rootClassName?: string;
  color?: LiteralUnion<PresetColorType>;
  placement?: TooltipPlacement;
  builtinPlacements?: typeof Placements;
  openClassName?: string;
  arrow?:
    | boolean
    | {
        pointAtCenter?: boolean;
      };
  autoAdjustOverflow?: boolean | AdjustOverflow;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  children?: React.ReactNode;
  destroyTooltipOnHide?:
    | boolean
    | {
        keepParent?: boolean;
      };
  open?: boolean;
  overlayClassName?: string;
  trigger?: AntdTooltipProps['trigger'];
  id?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
  title,
  style,
  className,
  rootClassName,
  color,
  placement = 'bottomLeft',
  builtinPlacements,
  openClassName,
  arrow = false,
  autoAdjustOverflow,
  getPopupContainer,
  children,
  destroyTooltipOnHide = true,
  open = false,
  overlayClassName,
  trigger = 'hover',
  id,
}) => {
  const tooltipId = useId();
  return (
    <AntdTooltip
      // open={open}
      id={id ?? tooltipId}
      title={title}
      style={style}
      className={className}
      rootClassName={rootClassName}
      color={color}
      placement={placement}
      builtinPlacements={builtinPlacements}
      openClassName={openClassName}
      arrow={arrow}
      autoAdjustOverflow={autoAdjustOverflow}
      getPopupContainer={getPopupContainer}
      destroyTooltipOnHide={destroyTooltipOnHide}
      overlayClassName={overlayClassName}
      trigger={trigger}
    >
      {children}
    </AntdTooltip>
  );
};
