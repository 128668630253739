import React, { CSSProperties, ReactNode } from "react";

import { Avatar as AntdAvatar, AvatarProps as AntdAvatarProps } from "antd";
import { AvatarSize as AntdAvatarSize } from "antd/es/avatar/AvatarContext";
import "./Avatar.css";

export interface AvatarProps {
  size?: AntdAvatarSize;
  icon?: ReactNode;
  src?: ReactNode;
  shape?: AntdAvatarProps["shape"];
  alt?: string;
  style?: CSSProperties;
  classname?: string;
  children?: string | ReactNode;
  gap?: number;
  onClick?: () => void;
}

export const Avatar: React.FC<AvatarProps> = ({
  icon,
  src,
  size = 64,
  shape = "circle",
  alt = "avatar",
  style,
  classname,
  children,
  gap,
  onClick,
}) => {
  return (
    <AntdAvatar
      size={size}
      shape={shape}
      icon={icon}
      src={src}
      alt={alt}
      style={style}
      className={classname}
      gap={gap}
      onClick={onClick}
    >
      {children}
    </AntdAvatar>
  );
};

