import React from "react";
import { ReactComponent as noAccess } from "../../assets/no_access.svg";
import { SVGIcon } from "../SVG-Icon";
import "./No-Access.css";

export const NoAccess: React.FC = () => {
  return (
    <div className="no-access-container">
      <SVGIcon
        SVGElementIcon={noAccess}
        width={"45vw"}
        height={"70vh"}
        style={{ stroke: "transparent", cursor: "default" }}
      />
      <p className="text-wrapper-1">
        It looks like you've arrived a bit early!
      </p>
      <p className="text-wrapper-1">
        But don’t worry, <span className="text-wrapper-2">tribe</span> is
        growing, and soon there’ll be much more for you!
      </p>
    </div>
  );
};
