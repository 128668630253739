import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getCareerCompassCareerLadderService,
  getCareerCompassEmployeeDataService,
  getCareerCompassPracticeListService,
  getCareerCompassProfileSummaryService,
  getCareerCompassRoleDetailsService,
  getCareerCompassStretchRoleService,
  getCareerCompassSuccessStoriesService,
  getDynamicPageContentsService,
  updateEmployeeUserPreferenceService,
} from '../../services/career-path';
import { ReduxApiService } from '../../views/enums';
import { ICareerPath } from '../../views/interfaces';

const initialState: ICareerPath = {
  loading: 0,
  practiceTitle: '',
  employeeData: null,
  dynamicPageContents: [],
  practiceGroup: [],
  successStories: [],
  careerLadder: { nodeList: [], edgeList: [] },
  stretchRole: { nodeList: [], edgeList: [] },
  profileSummary: false,
  roleDetails: {
    competencies: [],
    knowledgeExperience: [],
    roleBasicInfo: {
      job_role_name: '',
      level: [],
      practice: [],
      specialization: [],
    },
  },
  introCompleted: true,
  tourCompleted: true,
  updatePreferenceStatus: false,
};

export const setPracticeTitle = createAction(
  'setPracticeTitle',
  (args: string) => {
    return {
      payload: {
        practiceTitle: args,
      },
    };
  }
);

export const getCareerCompassEmployeeData = createAsyncThunk(
  ReduxApiService.getCareerCompassEmployeeData,
  async () => {
    const result = await getCareerCompassEmployeeDataService();
    return result;
  }
);

export const getDynamicPageContents = createAsyncThunk(
  ReduxApiService.getDynamicPageContents,
  async () => {
    const result = await getDynamicPageContentsService();
    return result;
  }
);

export const getCareerCompassPracticeList = createAsyncThunk(
  ReduxApiService.getCareerCompassPracticeList,
  async () => {
    const result = await getCareerCompassPracticeListService();
    return result;
  }
);

export const getCareerCompassSuccessStories = createAsyncThunk(
  ReduxApiService.getCareerCompassSuccessStories,
  async () => {
    const result = await getCareerCompassSuccessStoriesService();
    return result;
  }
);

export const getCareerCompassCareerLadder = createAsyncThunk(
  ReduxApiService.getCareerCompassCareerLadder,
  async (args: any) => {
    const result = await getCareerCompassCareerLadderService(args);
    return result;
  }
);

export const getCareerCompassStretchRole = createAsyncThunk(
  ReduxApiService.getCareerCompassStretchRole,
  async (args: any) => {
    const result = await getCareerCompassStretchRoleService(args);
    return result;
  }
);

export const getCareerCompassRoleDetails = createAsyncThunk(
  ReduxApiService.getCareerCompassRoleDetails,
  async (body: { roleId: string ; practice: string }) => {
    const result = await getCareerCompassRoleDetailsService(body);
    return result;
  }
);

export const getCareerCompassProfileSummary = createAsyncThunk(
  ReduxApiService.getCareerCompassProfileSummary,
  async (body: { profile_summary: string }) => {
    const result = await getCareerCompassProfileSummaryService(body);
    return result;
  }
);

export const updateEmployeeUserPreference = createAsyncThunk(
  ReduxApiService.updateEmployeeUserPreference,
  async (args: { body: any }) => {
    const result = await updateEmployeeUserPreferenceService(args.body);
    return result;
  }
);

export const getCareerCampusEmployeeData = createAsyncThunk(
  ReduxApiService.getCareerCompassEmployeeData,
  async () => {
    const result = await getCareerCompassEmployeeDataService();
    return result;
  }
);

const careerPathSlice = createSlice({
  name: 'career-path',
  initialState,
  reducers: {
    setIntroCompleted: (state) => {
      state.introCompleted = true;
    },
    resetIntroCompleted: (state) => {
      state.introCompleted = false;
    },
    setTourCompleted: (state) => {
      state.tourCompleted = true;
    },
    resetTourCompleted: (state) => {
      state.tourCompleted = false;
    },
    resetUpdatePreferenceStatus: (state) => {
      state.updatePreferenceStatus = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setPracticeTitle, (state, action) => {
        state.practiceTitle = action.payload.practiceTitle;
      })
      .addCase(getCareerCompassEmployeeData.pending, (state, action) => {
        state.loading += 1;
        state.employeeData = initialState.employeeData;
      })
      .addCase(getCareerCompassEmployeeData.rejected, (state, action) => {
        state.loading -= 1;
        state.employeeData = initialState.employeeData;
      })
      .addCase(getCareerCompassEmployeeData.fulfilled, (state, action) => {
        state.loading -= 1;
        state.employeeData = action.payload;
      })
      .addCase(getDynamicPageContents.pending, (state, action) => {
        state.loading += 1;
        state.dynamicPageContents = initialState.dynamicPageContents;
      })
      .addCase(getDynamicPageContents.rejected, (state, action) => {
        state.loading -= 1;
        state.dynamicPageContents = initialState.dynamicPageContents;
      })
      .addCase(getDynamicPageContents.fulfilled, (state, action) => {
        state.loading -= 1;
        state.dynamicPageContents = action.payload;
      })
      .addCase(getCareerCompassPracticeList.pending, (state, action) => {
        state.loading += 1;
        state.practiceGroup = initialState.practiceGroup;
      })
      .addCase(getCareerCompassPracticeList.rejected, (state, action) => {
        state.loading -= 1;
        state.practiceGroup = initialState.practiceGroup;
      })
      .addCase(getCareerCompassPracticeList.fulfilled, (state, action) => {
        state.loading -= 1;
        state.practiceGroup = action.payload;
      })
      .addCase(getCareerCompassSuccessStories.pending, (state, action) => {
        state.loading += 1;
        state.successStories = initialState.successStories;
      })
      .addCase(getCareerCompassSuccessStories.rejected, (state, action) => {
        state.loading -= 1;
        state.successStories = initialState.successStories;
      })
      .addCase(getCareerCompassSuccessStories.fulfilled, (state, action) => {
        state.loading -= 1;
        state.successStories = action.payload;
      })
      .addCase(getCareerCompassCareerLadder.pending, (state, action) => {
        state.loading += 1;
        state.careerLadder = { nodeList: [], edgeList: [] };
      })
      .addCase(getCareerCompassCareerLadder.rejected, (state, action) => {
        state.loading -= 1;
        state.careerLadder = { nodeList: [], edgeList: [] };
      })
      .addCase(getCareerCompassCareerLadder.fulfilled, (state, action) => {
        state.loading -= 1;
        state.careerLadder = action.payload;
      })
      .addCase(getCareerCompassStretchRole.pending, (state, action) => {
        state.loading += 1;
        state.stretchRole = { nodeList: [], edgeList: [] };
      })
      .addCase(getCareerCompassStretchRole.rejected, (state, action) => {
        state.loading -= 1;
        state.stretchRole = { nodeList: [], edgeList: [] };
      })
      .addCase(getCareerCompassStretchRole.fulfilled, (state, action) => {
        state.loading -= 1;
        state.stretchRole = action.payload;
      })
      .addCase(getCareerCompassRoleDetails.pending, (state, action) => {
        state.loading += 1;
        state.roleDetails = initialState.roleDetails;
      })
      .addCase(getCareerCompassRoleDetails.rejected, (state, action) => {
        state.loading -= 1;
        state.roleDetails = initialState.roleDetails;
      })
      .addCase(getCareerCompassRoleDetails.fulfilled, (state, action) => {
        state.loading -= 1;
        state.roleDetails = action.payload;
      })
      .addCase(getCareerCompassProfileSummary.pending, (state, action) => {
        state.loading += 1;
        state.profileSummary = initialState.profileSummary;
      })
      .addCase(getCareerCompassProfileSummary.rejected, (state, action) => {
        state.loading -= 1;
        state.profileSummary = initialState.profileSummary;
      })
      .addCase(getCareerCompassProfileSummary.fulfilled, (state, action) => {
        state.loading -= 1;
        state.profileSummary = true;
      })
      .addCase(updateEmployeeUserPreference.pending, (state, action) => {
        state.loading += 1;
        state.updatePreferenceStatus = initialState.updatePreferenceStatus;
      })
      .addCase(updateEmployeeUserPreference.rejected, (state, action) => {
        state.loading -= 1;
        state.updatePreferenceStatus = initialState.updatePreferenceStatus;
      })
      .addCase(updateEmployeeUserPreference.fulfilled, (state, action) => {
        state.loading -= 1;
        state.updatePreferenceStatus = true;
      });
  },
});

export const careerPathReducer = careerPathSlice.reducer;
export const {
  setIntroCompleted,
  resetIntroCompleted,
  setTourCompleted,
  resetTourCompleted,
  resetUpdatePreferenceStatus,
} = careerPathSlice.actions;
