import React from 'react';

import { ReactComponent as CareerLadderPlaceholder } from '../../../../assets/career-ladder-placeholder.svg';
import { ReactComponent as StretchRoleComponent } from '../../../../assets/link.svg';
import { SVGIcon } from '../../../../components';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { FlowProvider } from '../../../../lib/Flow';
import { IReactFlow } from '../../../../views/interfaces';
import './Stretch-Roles.css';

export interface StretchRolesProps {
  flowData: IReactFlow;
  handleNodeClick: (data?: any) => void;
}

export const StretchRoles: React.FC<StretchRolesProps> = ({
  flowData,
  handleNodeClick,
}) => {
  const { loading } = useAppSelector((state) => state.careerPath);
  return (
    <div className='stretch-roles'>
      <FlowProvider
        showControls={true}
        background={{ bgColor: '#FEF1EC' }}
        nodeData={flowData.nodeList}
        edgeData={flowData.edgeList}
        handleNodeClick={handleNodeClick}
        treeDirection='LR'
        edgeType='simplebezier'
        edgeAnimated={true}
        nodeStyle={{ height: 56, width: 225 }}
        edgeStyle={{ stroke: '#308C78', strokeWidth: 2 }}
        edgeLayout={{
          markerEnd: 'arrow-end',
        }}
        controlStyles={{ left: 10, bottom: 50 }}
        panel={{
          position: 'bottom-left',
          children: flowData.nodeList.length > 0 && (
            <div className='custom-panel'>
              <div className='eligible-roles'>
                <SVGIcon
                  SVGElementIcon={StretchRoleComponent}
                  color='#fe5000'
                  height={18}
                  width={18}
                />
                <p>lateral paths</p>
              </div>
            </div>
          ),
        }}
        placeholder={
          loading == 0 && (
            <div
              style={{
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '48px',
              }}
            >
              <div>
                <SVGIcon
                  SVGElementIcon={CareerLadderPlaceholder}
                  color='unset'
                  rootStyle={{ width: '100%', height: '100%' }}
                />
              </div>
              <div
                style={{
                  height: '58px',
                  width: '472px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p>The Lateral path is still being crafted.</p>
                <p>Stay curious, and continue shaping your own career path.</p>
              </div>
            </div>
          )
        }
      />
    </div>
  );
};
