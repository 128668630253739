import { useEffect, useState } from 'react';
import { ICareerCampusEmployeeData } from '../../views/interfaces';
import './Sidebar-Profile.css';

export interface SidebarProfileProps {
  profileDetails: ICareerCampusEmployeeData;
}

export const SidebarProfile: React.FC<SidebarProfileProps> = ({
  profileDetails,
}) => {
  const [data, setData] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    const data = [
      { label: 'Practice', value: profileDetails.practice ?? '' },
      { label: 'Vertical', value: profileDetails.vertical ?? '' },
      { label: 'Role Name', value: profileDetails.role ?? '' },
    ];
    setData(data);
  }, [profileDetails]);

  return (
    <div className='sidebar-user-profile-details-container'>
      <div className='sidebar-user-profile-details'>
        {data.map((item, index) => (
          <div
            className='sidebar-user-profile-data'
            key={(index + 1).toString()}
          >
            <p className='text-wrapper-1'>{item.label}:</p>
            <p className='text-wrapper-2'>{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
