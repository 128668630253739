import { ApiRoutesConfig, axiosInstance } from ".";
import { getFilterOptions } from "../utils";

export const getDraftTableDataService = async (body: any, params: any) => {
  try {
    const response = await axiosInstance.post(
      ApiRoutesConfig.getDraftTableData,
      body,
      {
        params,
      }
    );
    return {
      ...response.data.data,
      tableData: response.data.data.tableData.map((item: any) => {
        return {
          ...item,
          key:
            item.id.demandId && item.id.demandId.length > 0
              ? item.id.demandId
              : item.id.defreqId,
        };
      }),
    };
  } catch (error) {
    throw error;
  }
};

export const getDraftFilterDataService = async () => {
  try {
    const response = await axiosInstance.get(
      ApiRoutesConfig.getDraftFilterData
    );
    for (const [key, value] of Object.entries(response.data.data.filter)) {
      response.data.data.filter[key] = getFilterOptions(value);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const exportDraftTableDataService = async (body: any, params: any) => {
  try {
    const response = await axiosInstance.post(
      ApiRoutesConfig.exportDraftTableData,
      body,
      {
        params,
        responseType: "blob",
      }
    );
    if (response && response.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `draft_demands.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      return "success";
    }
    throw response;
  } catch (error) {
    throw error;
  }
};
