import React, { useCallback, useState } from "react";
import { ReactComponent as keyResponsibility } from "../../../../assets/key_responsibilities_puzzle.svg";
import { SVGIcon } from "../../../../components";
import { AntdTabsProps, Tabs } from "../../../../lib/Tabs";

import { IKnowledgeExperience } from "../../../../views/interfaces";
import "./Key-Responsibilities.css";

export interface KeyResponsibilitiesProps {
  data: IKnowledgeExperience[];
}

export const KeyResponsibilities: React.FC<KeyResponsibilitiesProps> = ({
  data,
}) => {
  const [activeTab, setActiveTab] = useState<string>("0");

  const tabItems = useCallback(() => {
    return data[0]?.details?.map((item: any, index: number) => {
      return {
        key: index.toString(),
        label: <div className="tab-label">{item.title}</div>,
        children: (
          <div className="tab-details-container">
            <ul>
              {item.description?.map((desc: string, i: number) => (
                <li key={i}>{desc}</li>
              ))}
            </ul>
            <div className="svg-container">
              <SVGIcon
                SVGElementIcon={keyResponsibility}
                width={85}
                height={92}
                style={{ stroke: "transparent" }}
              />
            </div>
          </div>
        ),
      };
    }) as AntdTabsProps["items"];
  }, [data]);

  return (
    <div className="key-responsibilities-container">
      <p className="text-wrapper-1">Key Responsibilities</p>
      <Tabs
        items={tabItems()}
        activeTab={activeTab}
        setActiveTab={(key) => setActiveTab(key)}
        className="key-responsibilities-tabs"
      />
    </div>
  );
};
