import React, {
  CSSProperties,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Dagre from '@dagrejs/dagre';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  BackgroundProps,
  Connection,
  ControlProps,
  Controls,
  Edge,
  EdgeChange,
  EdgeMarkerType,
  Handle,
  MarkerType,
  Node,
  NodeChange,
  NodeTypes,
  Panel,
  PanelPosition,
  Position,
  ProOptions,
  ReactFlow,
  ReactFlowProvider,
  useReactFlow,
  ViewportPortal,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { ReactComponent as TrackInfo } from '../../assets/info.svg';
import { ReactComponent as stretchRole } from '../../assets/link.svg';
import locationPin from '../../assets/location-pin.gif';
import { SVGIcon } from '../../components';
import { Tooltip } from '../Tooltip';
import './Flow.css';

export type { Edge, Node } from '@xyflow/react';

export interface NodeData extends Record<string, any> {
  id: string;
  label: string;
  nodeStyle: CSSProperties;
  handles: {
    id: string;
    position: Position;
    type: 'source' | 'target';
    style: CSSProperties;
  }[];
  extraLabel?: string;
  trackName?: string;
  showTrack?: boolean;
  currentRole?: boolean;
  feederRole?: boolean;
  eligibleRole?: boolean;
  showCurrentRole?: boolean;
  showMultipleHandles?: boolean;
  treeDirection: 'TB' | 'BT' | 'RL' | 'LR';
  showLateralIcon?: boolean;
  trackDescription?: string;
  handleNode: (node: any) => void;
  practice?: string;
}

export interface EdgeData extends Record<string, any> {
  sourceHandle: {
    position: Position;
    style: CSSProperties;
  };
  targetHandle: {
    position: Position;
    style: CSSProperties;
  };
}

export interface CustomNodeProps {
  id: string;
  data: NodeData;
  isConnectable: boolean;
}

const CustomNode = ({ id, data, isConnectable }: CustomNodeProps) => {
  const {
    label,
    handles,
    nodeStyle,
    trackName,
    currentRole,
    feederRole,
    eligibleRole,
    extraLabel,
    showCurrentRole,
    treeDirection,
    showMultipleHandles,
    showTrack,
    showLateralIcon,
    trackDescription,
    handleNode,
    practice,
  } = data;
  return (
    <Fragment>
      <div
        className='custom-node-with-trackName'
        style={{
          position: 'relative',
        }}
      >
        {showCurrentRole && currentRole && (
          <div
            className='current-role-indicator'
            style={{
              position: 'absolute',
              top: '-50px',
              left: '-26px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <Tooltip title='You are here' placement='left' arrow={true}>
              <img src={locationPin} alt='current role' width={55} />
            </Tooltip>
          </div>
        )}
        <div
          className='custom-node'
          style={nodeStyle}
          onClick={() => handleNode(data)}
        >
          {extraLabel && (
            <p style={{ fontWeight: 600, lineHeight: 1 }}>
              <Tooltip title={extraLabel}>
                <span>{extraLabel}</span>
              </Tooltip>
            </p>
          )}
          <p
            style={{
              width: '100%',
              margin: 0,
              lineHeight: 1.1,
              textAlign: extraLabel ? 'left' : 'center',
              display: 'flex',
              justifyContent: showLateralIcon ? 'space-between' : 'center',
              alignItems: 'center',
            }}
          >
            <Tooltip title={label}>
              <span
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                }}
              >
                {label}
              </span>
            </Tooltip>
            {showLateralIcon && (
              <SVGIcon
                SVGElementIcon={stretchRole}
                style={{ stroke: 'transparent' }}
              />
            )}
          </p>
        </div>
        {trackName && showTrack && (
          <p className='track-name'>
            {trackName}
            <Tooltip
              title={trackDescription}
              overlayClassName='track-description'
            >
              <span>
                <SVGIcon SVGElementIcon={TrackInfo} color='#fe5000' />
              </span>
            </Tooltip>
          </p>
        )}
      </div>
      {handles.length > 0 &&
        handles.map((handle, index) => {
          const offsetHandle = {
            ...handle.style,
            ...(showTrack &&
            (handle.position === Position.Left ||
              handle.position === Position.Right)
              ? { top: '30%' }
              : {}),
          };
          return (
            <Handle
              key={(index + 1).toString()}
              type={handle.type}
              position={handle.position}
              id={handle.id}
              style={offsetHandle}
              isConnectable={isConnectable}
            />
          );
        })}
    </Fragment>
  );
};

export interface FlowProps {
  nodeData: Node<NodeData>[];
  edgeData: Edge<EdgeData>[];
  handleNodeClick: (node: any) => void;
  treeDirection: 'TB' | 'BT' | 'LR' | 'RL';
  edgeType: 'default' | 'straight' | 'step' | 'smoothstep' | 'simplebezier';
  edgeAnimated?: boolean;
  multipleHandles?: boolean;
  zoomOnScroll?: boolean;
  draggable?: boolean;
  nodesDraggable?: boolean;
  preventScrolling?: boolean;
  proOptions?: ProOptions;
  style?: CSSProperties;
  className?: string;
  panel?: {
    position: PanelPosition;
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
  };
  background?: BackgroundProps;
  enableDagre?: boolean;
  showCurrentRole?: boolean;
  nodeType?: string;
  nodeStyle?: CSSProperties;
  edgeStyle?: CSSProperties;
  handleStyle?: CSSProperties;
  edgeLayout?: {
    markerEnd?: EdgeMarkerType;
    markerStart?: EdgeMarkerType;
    interactionWidth?: number;
  };
  placeholder?: ReactNode;
  children?: ReactNode;
  pathOptions?: {
    borderRadius: number;
  };
  otherPractice?: boolean;
  controls?: ControlProps;
  showControls?: boolean;
  controlStyles?: CSSProperties;
}

export const FlowProvider: React.FC<FlowProps> = (props) => {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
      {props.showControls && (
        <Controls
          {...props.controls}
          position='top-right'
          orientation='horizontal'
          showInteractive={false}
          style={{ background: '#ffffff63', top: 12, right: 12, zIndex: 1 }}
        />
      )}
    </ReactFlowProvider>
  );
};

export const Flow: React.FC<FlowProps> = ({
  nodeData = [],
  edgeData = [],
  handleNodeClick,
  treeDirection = 'BT',
  edgeType = 'smoothstep',
  edgeAnimated = false,
  multipleHandles = false,
  zoomOnScroll = false,
  draggable = false,
  nodesDraggable = false,
  preventScrolling = false,
  proOptions = { hideAttribution: true },
  className,
  style,
  panel = {
    position: 'bottom-left',
    children: <></>,
    className,
    style,
  },
  background,
  enableDagre = false,
  showCurrentRole = false,
  nodeType = 'customNode',
  nodeStyle = { width: 200, height: 40 },
  edgeStyle = {},
  handleStyle = { background: 'transparent', border: 'none' },
  edgeLayout = {
    markerEnd: {
      type: MarkerType.Arrow,
      color: '#FF9665',
      width: 20,
      height: 20,
      markerUnits: 'strokeWidth',
      orient: 'auto',
      strokeWidth: 1,
    },
    interactionWidth: 2,
  },
  placeholder = <></>,
  children,
  pathOptions = {
    borderRadius: 10,
  },
  otherPractice = false,
}) => {
  const defaultNodeStyles: CSSProperties = useMemo(() => {
    return {
      width: 200,
      height: 40,
      padding:
        treeDirection === 'LR' || treeDirection === 'RL'
          ? '10px 16px'
          : '12px 16px',
      position: 'relative',
      textWrap: 'nowrap',
      overflow: 'hidden',
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      borderRadius: '6px',
      borderColor: '#FE5000',
      border: '1px solid #fe5000',
      background: '#FFEBE3',
      color: '#000000',
      fontSize: '14px',
      fontWeight: '400',
      boxShadow: '4.923px 4.923px 11.486px 0px rgba(0, 0, 0, 0.04)',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems:
        treeDirection === 'LR' || treeDirection === 'RL'
          ? 'flex-start'
          : 'center',
      gap: '4px',
    };
  }, [treeDirection]);
  const otherPracticeRef = useRef<boolean>(otherPractice);
  const styleNodes = { ...defaultNodeStyles, ...nodeStyle };
  const { fitView } = useReactFlow();
  const [nodes, setNodes] = useState<Node<NodeData>[]>([]);
  const [edges, setEdges] = useState<Edge<EdgeData>[]>([]);
  const nodeStyleRef = useRef<CSSProperties>(styleNodes);
  const edgeStyleRef = useRef<CSSProperties>(edgeStyle);
  const handleStyleRef = useRef<CSSProperties>(handleStyle);
  const edgeLayoutRef = useRef<FlowProps['edgeLayout']>(edgeLayout);
  const treeDirectionRef = useRef<FlowProps['treeDirection']>(treeDirection);
  const showCurrentRoleRef = useRef<boolean>(showCurrentRole);
  const multipleHandlesRef = useRef<boolean>(multipleHandles);
  const edgeAnimatedRef = useRef<boolean>(edgeAnimated);
  const enableDagreRef = useRef<boolean>(enableDagre);
  const pathOptionsRef = useRef<FlowProps['pathOptions']>(pathOptions);
  const edgeTypeRef = useRef<FlowProps['edgeType']>(edgeType);
  const nodeTypeRef = useRef<FlowProps['nodeType']>(nodeType);

  const getLayoutedElements = useCallback(
    (nodes: Node<NodeData>[], edges: Edge<EdgeData>[]) => {
      const nodeHeight =
        typeof nodeStyleRef.current.height == 'string'
          ? parseInt(nodeStyleRef.current.height)
          : nodeStyleRef.current.height;
      const nodeWidth =
        typeof nodeStyleRef.current.width == 'string'
          ? parseInt(nodeStyleRef.current.width)
          : nodeStyleRef.current.width;

      const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
      g.setGraph({
        rankdir: enableDagreRef.current ? treeDirectionRef.current : undefined,
      });

      edges.forEach((edge) => g.setEdge(edge.source, edge.target));
      nodes.forEach((node) =>
        g.setNode(node.id, {
          ...node,
          width: nodeWidth,
          height: nodeHeight,
        })
      );

      Dagre.layout(g);

      const newEdges: Edge<EdgeData>[] = edges.map((edge) => {
        return {
          ...edge,
          data: edge.data as EdgeData,
          type: edge.type ? edge.type : edgeTypeRef.current,
          animated: edge.animated ? edge.animated : edgeAnimatedRef.current,
          sourceHandle: edge.sourceHandle
            ? edge.sourceHandle
            : `s${edge.source}t${edge.target}`,
          targetHandle: edge.targetHandle
            ? edge.targetHandle
            : `t${edge.target}s${edge.source}`,
          markerEnd: edge.markerEnd
            ? edge.markerEnd
            : edgeLayoutRef.current?.markerEnd,
          markerStart: edge.markerStart
            ? edge.markerStart
            : edgeLayoutRef.current?.markerStart,
          interactionWidth: edge.interactionWidth
            ? edge.interactionWidth
            : edgeLayoutRef.current?.interactionWidth,
          style: edge.style ? edge.style : edgeStyleRef.current,
          pathOptions: pathOptionsRef.current,
        };
      });

      const newNodes: Node<NodeData>[] = nodes.map((node) => {
        const handles: CustomNodeProps['data']['handles'] = [];
        newEdges.forEach((edge) => {
          if (edge.source === node.id && edge?.sourceHandle) {
            handles.push({
              id: edge.sourceHandle,
              position: edge?.data?.sourceHandle?.position
                ? (edge?.data?.sourceHandle?.position as Position)
                : treeDirectionRef.current == 'TB'
                ? Position.Bottom
                : treeDirectionRef.current == 'BT'
                ? Position.Top
                : treeDirectionRef.current == 'RL'
                ? Position.Left
                : Position.Right,
              type: 'source',
              style: edge?.data?.sourceHandle?.style
                ? edge?.data?.sourceHandle?.style
                : handleStyleRef.current,
            });
          }
          if (edge.target === node.id && edge?.targetHandle) {
            handles.push({
              id: edge.targetHandle,
              position: edge?.data?.targetHandle?.position
                ? (edge?.data?.targetHandle?.position as Position)
                : treeDirectionRef.current == 'TB'
                ? Position.Top
                : treeDirectionRef.current == 'BT'
                ? Position.Bottom
                : treeDirectionRef.current == 'RL'
                ? Position.Right
                : Position.Left,
              type: 'target',
              style: edge?.data?.sourceHandle?.style
                ? edge?.data?.sourceHandle?.style
                : handleStyleRef.current,
            });
          }
        });
        const position = enableDagreRef.current
          ? g.node(node.id)
          : node.position;
        let x = enableDagreRef.current
          ? position.x - (nodeWidth ? nodeWidth : 200) / 2
          : position.x;
        let y = enableDagreRef.current
          ? position.y - (nodeHeight ? nodeHeight : 40) / 2
          : position.y;
        if (enableDagreRef.current && node.data.currentRole) {
          if (treeDirectionRef.current == 'BT') y += 100;
          else if (treeDirectionRef.current == 'TB') y -= 100;
          else if (treeDirectionRef.current == 'RL') x += 100;
          else if (treeDirectionRef.current == 'LR') x -= 100;
        }

        return {
          ...node,
          data: {
            handles,
            id: node.id,
            label: node.data.label,
            extraLabel: node.data.extraLabel,
            trackName: node.data.trackName,
            currentRole: node.data.currentRole,
            eligibleRole: node.data.eligibleRole,
            nodeStyle: {
              ...nodeStyleRef.current,
              background: node.data.currentRole
                ? '#fe5000'
                : node.data.eligibleRole && !otherPracticeRef.current
                ? '#24A148'
                : node.data.feederRole
                ? '#FFDCCC'
                : nodeStyleRef.current.background,
              border: node.data.currentRole
                ? '1px solid #fe5000'
                : node.data.eligibleRole && !otherPracticeRef.current
                ? '1px solid #308C78'
                : node.data.feederRole
                ? '1px solid #FFEBE3'
                : otherPracticeRef.current && !node.data.eligibleRole
                ? '1px solid  transparent'
                : nodeStyleRef.current.border,
              color:
                node.data.currentRole ||
                (node.data.eligibleRole && !otherPracticeRef.current)
                  ? '#FFFFFF'
                  : node.data.feederRole
                  ? '#FF7332'
                  : nodeStyleRef.current.color,
              fontWeight: node.data.currentRole
                ? '600'
                : nodeStyleRef.current.fontWeight,
              ...node.data.nodeStyle,
            },
            treeDirection: treeDirectionRef.current,
            showCurrentRole: showCurrentRoleRef.current,
            edgeAnimated: edgeAnimatedRef.current,
            showMultipleHandles: multipleHandlesRef.current,
            showTrack: node.data.showTrack,
            showLateralIcon: otherPracticeRef.current && node.data.eligibleRole,
            trackDescription: node.data.trackDescription,
            handleNode: handleNodeClick,
            practice: node.data.practice,
          } as NodeData,
          position: { x, y },
          zIndex: node.data.currentRole ? 1 : 0,
          type: nodeTypeRef.current,
        };
      });

      setNodes([...newNodes]);
      setEdges([...newEdges]);
    },
    [handleNodeClick]
  );

  useEffect(() => {
    const nodePositionSet = new Set<string>();
    nodeData.forEach((node) => {
      nodePositionSet.add(`${node.position.x}:${node.position.y}`);
    });
    if (nodePositionSet.size != nodeData.length) {
      enableDagreRef.current = true;
    }
    otherPracticeRef.current = otherPractice;
    getLayoutedElements(nodeData, edgeData);
  }, [nodeData, edgeData, getLayoutedElements, otherPractice]);

  const updateFitView = useCallback(() => {
    window.requestAnimationFrame(() => {
      fitView();
    });
  }, [fitView]);

  useEffect(() => {
    if (nodes.length > 0 && edges.length > 0) {
      updateFitView();
    }
  }, [nodes, edges, updateFitView]);

  useEffect(() => {
    window.addEventListener('resize', updateFitView);
    return () => window.removeEventListener('resize', updateFitView);
  }, [updateFitView]);

  const nodeTypes = useMemo(() => {
    return {
      customNode: CustomNode,
    } as NodeTypes;
  }, []);

  const onConnect = useCallback(
    (connection: Connection) => setEdges((edge) => addEdge(connection, edge)),
    [setEdges]
  );

  const onNodesChange = useCallback(
    (changes: NodeChange<Node<NodeData>>[]) =>
      setNodes((node) => applyNodeChanges(changes, node)),
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange<Edge<EdgeData>>[]) =>
      setEdges((edge) => applyEdgeChanges(changes, edge)),
    [setEdges]
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      proOptions={proOptions}
      nodeTypes={nodeTypeRef.current == 'customNode' ? nodeTypes : undefined}
      zoomOnScroll={zoomOnScroll}
      draggable={draggable}
      nodesDraggable={nodesDraggable}
      preventScrolling={preventScrolling}
      className={className}
      style={style}
      fitView
    >
      <Background {...background} />
      {(nodes.length == 0 || edges.length == 0) && placeholder}
      {panel && (
        <Panel
          position={panel.position}
          className={panel.className}
          style={style}
        >
          {panel.children}
        </Panel>
      )}
      {children && <ViewportPortal>{children}</ViewportPortal>}
    </ReactFlow>
  );
};
