import React, { Fragment, ReactNode, useEffect } from "react";

import AntdBadge from "antd/es/badge";
import "./Badge.css";

export interface BadgeProps {
  count: number | string | ReactNode;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const Badge: React.FC<BadgeProps> = ({
  count = 0,
  className,
  style,
  children,
}) => {
  const [showBadge, setShowBadge] = React.useState<boolean>(false);

  useEffect(() => {
    if (typeof count === "number" && count > 0) {
      setShowBadge(true);
    } else if (typeof count === "string" && count !== "") {
      setShowBadge(true);
    } else {
      setShowBadge(true);
    }
  }, [count]);

  return (
    <Fragment>
      {showBadge ? (
        <AntdBadge count={count} className={className} style={style}>
          {children}
        </AntdBadge>
      ) : (
        <>{children}</>
      )}
    </Fragment>
  );
};
