import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  exportDraftTableDataService,
  getDraftFilterDataService,
  getDraftTableDataService,
} from "../../services/draft-demand";
import { ReduxApiService } from "../../views/enums";
import { IDraftDemandFilter, IDraftDemandState } from "../../views/interfaces";

export const defaultFilterData: IDraftDemandFilter = {
  account: [],
  vertical: [],
  practice: [],
  projectOpportunity: [],
  location: [],
  legalEntity: [],
};

const initialState: IDraftDemandState = {
  loading: 0,
  filterData: defaultFilterData,
  sortData: [],
  tableData: [],
  totalCount: 0,
  reloadDraftDemand: false,
};

export const getDraftFilterData = createAsyncThunk(
  ReduxApiService.getDraftFilterData,
  async () => {
    const result = await getDraftFilterDataService();
    return result;
  }
);

export const getDraftTableData = createAsyncThunk(
  ReduxApiService.getDraftTableData,
  async (request: { body: any; params: any }) => {
    const result = await getDraftTableDataService(request.body, request.params);
    return result;
  }
);

export const exportDraftTableData = createAsyncThunk(
  ReduxApiService.exportDraftTableData,
  async (request: { body: any; params?: any }) => {
    const result = await exportDraftTableDataService(
      request.body,
      request.params
    );
    return result;
  }
);

const draftDemandSlice = createSlice({
  name: "draftDemand",
  initialState,
  reducers: {
    setReloadDraftDemand(state, action) {
      state.reloadDraftDemand = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDraftFilterData.pending, (state, action) => {
        state.loading += 1;
        state.filterData = defaultFilterData;
        state.sortData = [];
      })
      .addCase(getDraftFilterData.rejected, (state, action) => {
        state.loading -= 1;
        state.filterData = defaultFilterData;
        state.sortData = [];
      })
      .addCase(getDraftFilterData.fulfilled, (state, action) => {
        state.loading -= 1;
        state.filterData = action.payload?.data.filter ?? null;
        state.sortData = action.payload?.data.sort ?? [];
      })
      .addCase(getDraftTableData.pending, (state, action) => {
        state.loading += 1;
        state.tableData = [];
        state.totalCount = 0;
      })
      .addCase(getDraftTableData.rejected, (state, action) => {
        state.loading -= 1;
        state.tableData = [];
        state.totalCount = 0;
      })
      .addCase(getDraftTableData.fulfilled, (state, action) => {
        state.loading -= 1;
        state.tableData = action.payload ? action.payload.tableData : [];
        state.totalCount = action.payload ? action.payload.totalCount : 0;
      });
  },
});

export const { setReloadDraftDemand } = draftDemandSlice.actions;
export const draftDemandReducer = draftDemandSlice.reducer;
