export const IS_PRODUCTION = process.env.NODE_ENV == 'production';
export const SERVER_TYPE = process.env.REACT_APP_SERVER_TYPE!;
export const ADD_PREFIX_IN_URL =
  process.env.REACT_APP_ADD_PREFIX_IN_URL! == 'true';
export const AUTH_URL = process.env.REACT_APP_AUTH_URL!;
export const TRIBE_URL = process.env.REACT_APP_TRIBE_URL!;
export const CAREER_COMPASS_URL = process.env.REACT_APP_CAREER_COMPASS_URL!;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID!;
export const TENENT_ID = process.env.REACT_APP_TENANT_ID!;
export const MaintenanceMode =
  process.env.REACT_APP_MAINTENANCE_MODE! == 'true';
export const MaintenanceMessage = process.env.REACT_APP_MAINTENANCE_MESSAGE!;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET!;
export const TOKEN_REFRESH_TIME =
  parseInt(process.env.REACT_APP_TOKEN_REFRESH_TIME!) || 2700000;
