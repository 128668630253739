import axios from 'axios';
import {
  ADD_PREFIX_IN_URL,
  CAREER_COMPASS_URL,
  SERVER_TYPE,
  TRIBE_URL,
} from '../environment';
import { decodeOutput } from '../utils/decodeAPIData';
import { processCryptoFields } from '../utils/encryptionUtils';
import { fetchToken } from '../utils/fetch-token';
import { ReduxApiService } from '../views/enums';

export const getBackendUrl = (url: string) => {
  if (ADD_PREFIX_IN_URL) {
    const urlArray = url.split('/');
    if (SERVER_TYPE !== 'prod') {
      urlArray.splice(3, 0, SERVER_TYPE);
    }
    url = urlArray.join('/');
  }
  return url;
};

export const axiosInstance = axios.create({
  baseURL: getBackendUrl(TRIBE_URL),
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config: any) => {
    const token = await fetchToken();
    if (!token) {
      throw new Error('Token not found');
    }
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token.idtoken}`,
      },
    };
  },
  (error) => {
    throw error;
  }
);

axiosInstance.interceptors.response.use(
  async (response: any) => {
    return response;
  },
  (error) => {
    throw error;
  }
);

export const axiosCareerCompassInstance = axios.create({
  baseURL: getBackendUrl(CAREER_COMPASS_URL),
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosCareerCompassInstance.interceptors.request.use(
  async (config: any) => {
    const token = await fetchToken();
    if (!token) {
      throw new Error('Token not found');
    }
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token.idtoken}`,
      },
    };
  },
  (error) => {
    throw error;
  }
);

axiosCareerCompassInstance.interceptors.response.use(
  async (response: any) => {
    let modifiedResponse;

    if (process.env.REACT_APP_ENABLE_CRYPTO_ON_REQ_RES === 'true') {
      const entity: string = response.config.url.replace(/^\//, '');
      const encryptionFields: { [key: string]: string[] } = JSON.parse(
        process.env.REACT_APP_ENCRYPTION_ENTITY_AND_DATA || '{}'
      );
      const cryptoEntities: string[] = Object.keys(encryptionFields);

      if (cryptoEntities.includes(entity)) {
        const entityFields = encryptionFields[entity];

        modifiedResponse = processCryptoFields(
          response.data,
          entityFields,
          'decrypt'
        );
      }
    }

    if (process.env.REACT_APP_ENABLE_XSS_PROTECTION === 'true') {
      modifiedResponse = decodeOutput(modifiedResponse || response.data);
    }

    return {
      ...response,
      data: modifiedResponse || response.data,
    };
  },
  (error) => {
    throw error;
  }
);

export const ApiRoutesConfig = {
  [ReduxApiService.getFilterData]: '/filter/dashboard',
  [ReduxApiService.getDraftFilterData]: '/filter/draft-demand',
  [ReduxApiService.getOpenFilterData]: '/filter/open-demand',
  [ReduxApiService.getEmployeeFilterData]: '/filter/employee',
  [ReduxApiService.getCardData]: '/dashboard/data',
  [ReduxApiService.getDraftTableData]: '/demand/draft/data',
  [ReduxApiService.exportDraftTableData]: '/demand/draft/report',
  [ReduxApiService.getOpenTableData]: '/demand/open/data',
  [ReduxApiService.exportOpenTableData]: '/demand/open/report',
  [ReduxApiService.getEmployeeTableData]: '/employee/data',
  [ReduxApiService.exportEmployeeTableData]: '/employee/report',
  [ReduxApiService.bulkFulfillmentUpdate]:
    '/demands-creation/bulk-fulfillment-update',
  [ReduxApiService.getDemandCreationData]: '/demands-creation/form-input-data',
  [ReduxApiService.saveSubmitDemandCreationForm]:
    '/demands-creation/save-submit-form',
  [ReduxApiService.getDemandHistory]: '/demands-creation/demand-history',
  [ReduxApiService.cancelDemandForm]: '/demands-creation/cancel-form',
  [ReduxApiService.convertDemandForm]: '/demands-creation/convert-form',
  [ReduxApiService.getEmployeeForProposal]: '/demands-creation/fetch-employee',
  [ReduxApiService.proposeSelectedEmployee]: '/demands-creation/propose',
  [ReduxApiService.getActiveProposals]: '/demands-creation/active-proposals',
  [ReduxApiService.getRejectionReasons]: '/demands-creation/rejection-reasons',
  [ReduxApiService.getCancellationReasons]:
    '/demands-creation/cancellation-reasons',
  [ReduxApiService.updateActiveProposalStatus]:
    '/demands-creation/update-proposal',
  [ReduxApiService.getProposalHistory]: '/demands-creation/proposal-history',
  [ReduxApiService.getExternalFulfillment]:
    '/demands-creation/external-fulfillment',
  [ReduxApiService.getUiFilterItems]: '/filter/ui-components',
  [ReduxApiService.demandApproveOrReject]:
    '/demands-creation/bulk-demand-approve',
  [ReduxApiService.openDemandReport]: '/reports/open-demand',
  [ReduxApiService.createJdUsingAI]: '/jd/create',
  [ReduxApiService.getRmgReasons]: '/demands-creation/rmg-reasons',
  [ReduxApiService.closeByRmg]: '/demands-creation/close-demand',
  [ReduxApiService.getEmployeeProfile]: '/employee/profile',
  [ReduxApiService.sendEmail]: '/email/send',
  [ReduxApiService.onHoldOrRelease]: '/demands-creation/hold-release',
  [ReduxApiService.getDynamicPageContents]: '/dynamic-page-contents',
  [ReduxApiService.getCareerCompassEmployeeData]: '/emp/profile',
  [ReduxApiService.getCareerCompassPracticeList]: '/practice',
  [ReduxApiService.getCareerCompassHrList]: '/emp/hr-list',
  [ReduxApiService.getCareerCompassSuccessStories]: '/success-stories',
  [ReduxApiService.getCareerCompassCareerLadder]: '/career-ladder',
  [ReduxApiService.getCareerCompassStretchRole]: '/cross-practice',
  [ReduxApiService.getCareerCompassRoleDetails]: '/role/details',
  [ReduxApiService.getCareerCompassProfileSummary]: '/emp/profile-summary',
  [ReduxApiService.updateEmployeeUserPreference]: 'emp/user-preference',
  [ReduxApiService.getLmsCourses]: '/lms-details',
};
