import React, { useMemo } from 'react';

import { ReactComponent as CompetencyTableIcon } from '../../../../assets/competency-table.svg';
import { ReactComponent as Knowledge } from '../../../../assets/knowledge_competency.svg';
import { SVGIcon } from '../../../../components';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { Tooltip } from '../../../../lib';
import { RootState } from '../../../../redux/store';
import { CompetencyTable } from '../Competency-Table';
import { KeyResponsibilities } from '../Key-Responsibilities';
import './Role-Details.css';

export const RoleDetails: React.FC = () => {
  const { roleDetails } = useAppSelector(
    (state: RootState) => state.careerPath
  );

  const { knowledgeExperience, competencies, roleBasicInfo } = roleDetails;

  const roleDetailsData = useMemo(() => {
    const knowledgeData = knowledgeExperience?.find(
      (item) => item.type === 'Knowledge and competencies'
    );
    const keyResponsibilitiesData = knowledgeExperience?.find(
      (item) => item.type === 'Key Responsibilities'
    );

    const maxVisibleItems = 5;
    const specializations = roleBasicInfo?.specialization || [];
    const visibleSpecializations = specializations.slice(0, maxVisibleItems);
    const hiddenSpecializations = specializations.slice(maxVisibleItems);

    const roleValues = [
      { label: 'Job role name', value: roleBasicInfo?.job_role_name },
      { label: 'Practice', value: roleBasicInfo?.practice.join(', ') },
      {
        label: 'Specialization',
        value: (
          <div className='specialization-container'>
            {visibleSpecializations.map((item, index) => (
              <div key={index} className='specialization-item'>
                {item}
              </div>
            ))}
            {hiddenSpecializations.length > 0 && (
              <Tooltip
                open={true}
                title={hiddenSpecializations.map((item, index) => (
                  <div key={index} className='specialization-item'>
                    {item}
                  </div>
                ))}
                arrow={true}
                placement='rightBottom'
                overlayClassName='tooltip-specialization-items'
              >
                <div className='specialization-item'>
                  +{hiddenSpecializations.length} more
                </div>
              </Tooltip>
            )}
          </div>
        ),
      },
      { label: 'Grade Range', value: roleBasicInfo?.level },
    ];
    return {
      knowledgeData,
      keyResponsibilitiesData,
      roleValues,
    };
  }, [knowledgeExperience, roleBasicInfo]);

  return (
    <div className='role-details-container'>
      <div className='role-basic-info'>
        {roleDetailsData.roleValues.map((item, index) => (
          <div className='role-header-item' key={index}>
            <p>{item.label}</p>
            <p style={{ fontWeight: 600 }}>{item.value}</p>
          </div>
        ))}
      </div>
      <KeyResponsibilities
        data={
          roleDetailsData.keyResponsibilitiesData
            ? [roleDetailsData.keyResponsibilitiesData]
            : []
        }
      />
      <div className='knowledge-competencies-container'>
        <SVGIcon
          SVGElementIcon={Knowledge}
          style={{ stroke: 'transparent' }}
          width='inherit'
          height='inherit'
        />
        <div className='content'>
          <p className='text-wrapper-1'>Knowledge and Competencies</p>
          <div className='details-container'>
            {roleDetailsData.knowledgeData?.details.map((item: any) => (
              <div className='details' key={item.title}>
                <p className='text-wrapper-2'>{item.title}</p>
                <p className='text-wrapper-3'>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='competencies-container'>
        <p className='text-wrapper-1'>Competencies</p>
        <p className='text-wrapper-2'>
          Behavioral competency required to perform the role.
        </p>
        <div className='competency-body'>
          <div className='competency-table'>
            <CompetencyTable data={competencies} />
          </div>
          <div className='competency-image'>
            <SVGIcon
              SVGElementIcon={CompetencyTableIcon}
              style={{ stroke: 'transparent' }}
              width='inherit'
              height='inherit'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
