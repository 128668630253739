import React, { useEffect, useState } from "react";
import cancel from "../../../../assets/cancel.gif";
import success from "../../../../assets/success.gif";
import warning from "../../../../assets/warning.gif";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import { getDemandHistory } from "../../../../redux/slice/demand";
import {
  EDemandStatus,
  EDemandSubStatusExternal,
  EDemandSubStatusInternal,
  EFulFillmentStatus,
} from "../../../../views/enums";
import { IDemandMeta } from "../../../../views/interfaces";
import { DemandHistoryItem } from "../Demand-History-Item";
import "./Demand-History.css";

const displayContent = {
  NoProposal:
    "Currently, there are no proposed candidates for this demand. Please proceed with necessary actions.",
  Internal: "The demand has been fulfilled Internally",
  External: "The demand has been fulfilled Externally",
  Cancelled: "This demand has been cancelled",
};

export interface DemandHistoryProps {
  meta: IDemandMeta;
}

export const DemandHistory: React.FC<DemandHistoryProps> = ({ meta }) => {
  const [content, setContent] = useState<string>("");

  const dispatch = useAppDispatch();
  const { demandHistory } = useAppSelector((state) => state.demand);
  useEffect(() => {
    if (meta.defreqId) {
      dispatch(getDemandHistory({ defreqId: meta.defreqId }));
    }
    const { status, fulfillmentType, subStatus } = meta.demandStatus;

    const showContent = (
      status: string,
      fulfillmentType: string,
      subStatus: string
    ) => {
      switch (status) {
        case EDemandStatus.open:
          if (
            fulfillmentType === EFulFillmentStatus.Internal &&
            subStatus === EDemandSubStatusInternal.No_Proposal
          ) {
            return displayContent.NoProposal;
          }
          break;
        case EDemandStatus.closed:
          if (
            fulfillmentType === EFulFillmentStatus.Internal &&
            subStatus === EDemandSubStatusInternal.Internally_Identified
          ) {
            return displayContent.Internal;
          }
          if (
            fulfillmentType === EFulFillmentStatus.External &&
            subStatus === EDemandSubStatusExternal.Hired
          ) {
            return displayContent.External;
          }
          break;
        case EDemandStatus.cancelled:
          return displayContent.Cancelled;
        default:
          return null;
      }
    };

    const content = showContent(status, fulfillmentType, subStatus);
    if (content) {
      setContent(content);
    }
  }, [meta.defreqId, dispatch, meta.demandStatus]);

  const detailsClass = () => {
    if (
      meta.demandStatus.status === EDemandStatus.open &&
      meta.demandStatus.fulfillmentType === EFulFillmentStatus.Internal &&
      meta.demandStatus.subStatus === EDemandSubStatusInternal.No_Proposal
    ) {
      return {
        class: "demandOpenNoProposal",
        border: "8px solid #FFFAEB",
        backgroundColor: "#FCF2CF",
        imgSrc: warning,
      };
    } else if (
      meta.demandStatus.status === EDemandStatus.closed &&
      meta.demandStatus.fulfillmentType === EFulFillmentStatus.Internal &&
      meta.demandStatus.subStatus === EDemandSubStatusExternal.Pending_Approval
    ) {
      return {
        class: "demandInternalIdentified",
        border: "8px solid #EAF6ED",
        backgroundColor: "#24A148",
        imgSrc: success,
      };
    } else if (
      meta.demandStatus.status === EDemandStatus.closed &&
      meta.demandStatus.fulfillmentType === EFulFillmentStatus.External &&
      meta.demandStatus.subStatus === EDemandSubStatusExternal.Hired
    ) {
      return {
        class: "demandExternalHired",
        border: "8px solid #EAF6ED",
        backgroundColor: "#24A148",
        imgSrc: success,
      };
    } else if (meta.demandStatus.status === EDemandStatus.cancelled) {
      return {
        class: "demandCancelled",
        border: "8px solid #FBE8E9",
        backgroundColor: "#E01F29",
        imgSrc: cancel,
      };
    } else {
      return { class: "", border: "", backgroundColor: "", imgSrc: "" };
    }
  };

  const detailStyles = detailsClass();
  const showDetailsContainer = () => detailStyles.class !== "";

  return (
    <div className="demand-history-container">
      {showDetailsContainer() && (
        <div className={`demand-history-details ${detailStyles.class}`}>
          <div className="demand-history-details-container">
            <div
              className="demand-history-details-indicator"
              style={{
                border: detailStyles.border,
                backgroundColor: detailStyles.backgroundColor,
              }}
            >
              <img
                src={detailStyles.imgSrc}
                width={24}
                height={24}
                alt="No Img"
              />
            </div>
            <div className="demand-history-details-content">{content}</div>
          </div>
        </div>
      )}
      <div className="demand-history">
        {demandHistory.map((item) => (
          <DemandHistoryItem title={item.message} timestamp={item.time} />
        ))}
      </div>
    </div>
  );
};
