import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { Tour, TourProps } from '../../../../lib/Tour';
import { resetRouteBack } from '../../../../redux/slice/app';
import {
  setIntroCompleted,
  setTourCompleted,
  updateEmployeeUserPreference,
} from '../../../../redux/slice/career-path';
import { EPreference } from '../../../../views/enums';
import './Career-Tour.css';

export interface CareerTourProps {}

const enum StepTitles {
  EXPLORE_CAREER_PATHS = 'Explore Career Paths',
  CAREER_LADDER = 'Career Ladder',
  STRETCH_ROLES = 'Lateral Path',
  ENHANCE_YOUR_SKILLS = 'Enhance Your Skills',
  DISCOVER_INTERNAL_MOBILITY = 'Discover Internal Mobility',
}

export const CareerTour: React.FC<CareerTourProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { routeBack } = useAppSelector((state) => state.app);
  const { employeeData, tourCompleted } = useAppSelector(
    (state) => state.careerPath
  );
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>([]);
  const [gap, setGap] = useState<{ offset?: number | [number, number] }>({
    offset: [10, 10],
  });

  const stepTitleId = useMemo(() => {
    return [
      { id: 'career-compass-tab-1', title: StepTitles.EXPLORE_CAREER_PATHS },
      { id: 'career-ladder-button', title: StepTitles.CAREER_LADDER },
      { id: 'stretch-roles-button', title: StepTitles.STRETCH_ROLES },
      { id: 'career-compass-tab-3', title: StepTitles.ENHANCE_YOUR_SKILLS },
      {
        id: 'career-compass-tab-4',
        title: StepTitles.DISCOVER_INTERNAL_MOBILITY,
      },
    ] as { id: string; title: StepTitles }[];
  }, []);

  const tourStepsMemo = useMemo(() => {
    return [
      {
        title: StepTitles.EXPLORE_CAREER_PATHS,
        description:
          'Welcome to Career Paths! Visualize your career journey. Explore roles, advancement options, and alternative paths within the organization.',
        target: null,
      },
      {
        title: StepTitles.CAREER_LADDER,
        description:
          'See a clear progression from your role to potential future positions. Click any role for details on responsibilities and competencies.',
        target: null,
      },
      {
        title: StepTitles.STRETCH_ROLES,
        description:
          'Explore lateral moves and roles outside your current practice to diversify your skills.',
        target: null,
      },
      {
        title: StepTitles.ENHANCE_YOUR_SKILLS,
        description:
          'Find personalized resources to build skills for your next career move. Complete recommended courses to advance your growth.',
        target: null,
      },
      // {
      //   title: StepTitles.DISCOVER_INTERNAL_MOBILITY,
      //   description:
      //     'Explore open positions within the company and find roles that match your career goals.. Take the next step in your career right here.',
      //   target: null,
      // },
    ] as TourProps['steps'];
  }, []);

  const handleStepChange = useCallback((current: number) => {
    if (current === 1 || current === 2) {
      setGap({ offset: [15, 0] });
    } else {
      setGap({ offset: [10, 10] });
    }
  }, []);

  useEffect(() => {
    if (!tourCompleted) {
      const tourStepsWithTarget = tourStepsMemo?.map((step) => {
        const elementId =
          stepTitleId.find((item) => item.title === step.title)?.id ?? null;

        return {
          ...step,
          target: elementId ? document.getElementById(elementId) : null,
        };
      });
      setTourSteps(tourStepsWithTarget);
    }
  }, [tourCompleted, tourStepsMemo, stepTitleId]);

  const updateUserPreference = () => {
    const firstLoginCompleted =
      employeeData?.preferences?.find(
        (item) => item.preference_name === EPreference.FIRST_LOGIN_COMPLETED
      )?.value == 'true';
    if (employeeData && !firstLoginCompleted) {
      dispatch(
        updateEmployeeUserPreference({
          body: {
            preference_name: EPreference.FIRST_LOGIN_COMPLETED,
            type: 'boolean',
            value: 'true',
            employee_id: employeeData?.employee_id,
          },
        })
      );
    }
    const uiGuideCompleted =
      employeeData?.preferences?.find(
        (item) => item.preference_name === EPreference.UI_GUIDE_COMPLETED
      )?.value == 'true';
    if (employeeData && !uiGuideCompleted) {
      dispatch(
        updateEmployeeUserPreference({
          body: {
            preference_name: EPreference.UI_GUIDE_COMPLETED,
            type: 'boolean',
            value: 'true',
            employee_id: employeeData?.employee_id,
          },
        })
      );
    }
    dispatch(setIntroCompleted());
    dispatch(setTourCompleted());
  };

  const handleTourClose = () => {
    updateUserPreference();
    if (routeBack.status) {
      navigate(routeBack.url);
      dispatch(resetRouteBack());
    }
  };

  return (
    <Tour
      open={!tourCompleted}
      steps={tourSteps}
      arrow={true}
      gap={gap}
      onChange={handleStepChange}
      onFinish={handleTourClose}
      nextButtonLastLabel='Navigate your Career Now'
    />
  );
};
