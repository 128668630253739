import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEmployeeProfileService } from '../../services/app';
import { removeLocalStorage } from '../../utils/local-storage';
import {
  getSessionStorage,
  setSessionStorage,
} from '../../utils/session-storage';
import { EModuleCode, ReduxApiService } from '../../views/enums';
import {
  IApp,
  IEmployeeProfile,
  IGraph,
  IMaintenance,
  IMsg,
  INotify,
} from '../../views/interfaces';

const appData = getSessionStorage('app');
const avatarData: string = getSessionStorage('avatar');
const graphData: IGraph = getSessionStorage('graph');
const maintenanceData: IMaintenance = getSessionStorage('maintenance');

const initialState: IApp = {
  loading: 0,
  logout: false,
  internalServerError: false,
  sidebarCollapsed: appData?.sidebarCollapsed || false,
  headerTitle: appData?.headerTitle || 'Tribe',
  activeModuleCode: EModuleCode.M_CAREER_COMPASS,
  profile: <IEmployeeProfile>{},
  maintenanceMode: {
    enabled: maintenanceData?.enabled ?? false,
    scheduled: maintenanceData?.scheduled ?? false,
    startDate: maintenanceData?.startDate ?? null,
    endDate: maintenanceData?.endDate ?? null,
    message: maintenanceData?.message ?? null,
  },
  graph: {
    '@odata.context': graphData?.['@odata.context'] || '',
    businessPhones: graphData?.businessPhones || [],
    displayName: graphData?.displayName || '',
    givenName: graphData?.givenName || '',
    jobTitle: graphData?.jobTitle || '',
    mail: graphData?.mail || '',
    mobilePhone: graphData?.mobilePhone || null,
    officeLocation: graphData?.officeLocation || null,
    preferredLanguage: graphData?.preferredLanguage || null,
    surname: graphData?.surname || '',
    userPrincipalName: graphData?.userPrincipalName || '',
    id: graphData?.id || '',
  },
  notify: {
    placement: 'bottomRight',
    duration: 4,
    show: false,
    type: 'info',
    message: '',
    description: { content: '' },
    key: 0,
  },
  msg: {
    show: false,
    type: 'success',
    content: '',
    duration: 2,
    key: 0,
  },
  avatar: avatarData || '',
  routeBack: {
    status: false,
    url: '/',
  },
};

export const setMaintenanceMode = createAction(
  'setMaintenanceMode',
  (args: IMaintenance) => {
    return {
      payload: args,
    };
  }
);

export const setSidebarCollapsedMode = createAction(
  'setSidebarCollapsedMode',
  (args: boolean) => {
    return {
      payload: {
        sidebarCollapsed: args,
      },
    };
  }
);

export const setHeaderTitle = createAction('setHeaderTitle', (args: string) => {
  return {
    payload: {
      headerTitle: args,
    },
  };
});

export const setActiveModuleCode = createAction(
  'setActiveModuleCode',
  (args: keyof typeof EModuleCode) => {
    return {
      payload: {
        activeModuleCode: args,
      },
    };
  }
);

export const setAvatar = createAction('setAvatar', (args: string) => {
  return {
    payload: args,
  };
});

export const setLoading = createAction('setLoading', (args: boolean) => {
  return {
    payload: args,
  };
});

export const setLogout = createAction('setLogout', (args: boolean) => {
  return {
    payload: args,
  };
});

export const setNotification = createAction(
  'setNotification',
  (args: INotify) => {
    return {
      payload: args,
    };
  }
);

export const resetNotification = createAction('resetNotification');

export const setMessage = createAction('setMessage', (args: IMsg) => {
  return {
    payload: args,
  };
});

export const setInternalServerError = createAction(
  'setInternalServerError',
  (args: boolean) => {
    return {
      payload: args,
    };
  }
);

export const setGraph = createAction('setGraph', (args: IGraph) => {
  return {
    payload: args,
  };
});

export const getEmployeeProfile = createAsyncThunk(
  ReduxApiService.getEmployeeProfile,
  async () => {
    const result = await getEmployeeProfileService();
    return result;
  }
);

export const resetMessage = createAction('resetMessage');

export const resetMaintenanceMode = createAction('resetMaintenanceMode');

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setRouteBack: (state, action) => {
      state.routeBack = {
        status: action.payload.status,
        url: action.payload.url,
      };
    },
    resetRouteBack: (state) => {
      state.routeBack = {
        status: initialState.routeBack.status,
        url: initialState.routeBack.url,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSidebarCollapsedMode, (state, action) => {
        state.sidebarCollapsed = action.payload.sidebarCollapsed;
      })
      .addCase(setHeaderTitle, (state, action) => {
        state.headerTitle = action.payload.headerTitle;
      })
      .addCase(setAvatar, (state, action) => {
        state.avatar = action.payload;

        setSessionStorage('avatar', action.payload);
      })
      .addCase(setLoading, (state, action) => {
        state.loading = action.payload
          ? state.loading + 1
          : state.loading <= 0
          ? 0
          : state.loading - 1;
      })
      .addCase(setLogout, (state, action) => {
        state.logout = action.payload;
      })
      .addCase(setActiveModuleCode, (state, action) => {
        state.activeModuleCode = action.payload.activeModuleCode;
      })
      .addCase(setInternalServerError, (state, action) => {
        state.internalServerError = action.payload;
      })
      .addCase(setMaintenanceMode, (state, action) => {
        state.maintenanceMode.enabled = action.payload.enabled;
        state.maintenanceMode.scheduled = action.payload.scheduled;
        state.maintenanceMode.startDate = action.payload.startDate;
        state.maintenanceMode.endDate = action.payload.endDate;
        state.maintenanceMode.message = action.payload.message;

        setSessionStorage('maintenance', action.payload);
      })
      .addCase(resetMaintenanceMode, (state, action) => {
        state.maintenanceMode.enabled = false;
        state.maintenanceMode.scheduled = false;
        state.maintenanceMode.startDate = null;
        state.maintenanceMode.endDate = null;
        state.maintenanceMode.message = null;

        removeLocalStorage('maintenance');
      })
      .addCase(setNotification, (state, action) => {
        state.notify.show = action.payload.show;
        state.notify.type = action.payload.type
          ? action.payload.type
          : initialState.notify.type;
        state.notify.message = action.payload.message;
        state.notify.description = action.payload.description;
        state.notify.placement = action.payload.placement
          ? action.payload.placement
          : initialState.notify.placement;
        state.notify.duration =
          action.payload.duration || action.payload.duration === 0
            ? action.payload.duration
            : initialState.notify.duration;
        state.notify.key = action.payload.key;
      })
      .addCase(resetNotification, (state, action) => {
        state.notify.show = initialState.notify.show;
        state.notify.type = initialState.notify.type;
        state.notify.message = initialState.notify.message;
        state.notify.description = initialState.notify.description;
        state.notify.placement = initialState.notify.placement;
        state.notify.duration = initialState.notify.duration;
        state.notify.key = initialState.notify.key;
      })
      .addCase(setMessage, (state, action) => {
        state.msg.show = action.payload.show;
        state.msg.type = action.payload.type
          ? action.payload.type
          : initialState.msg.type;
        state.msg.content = action.payload.content;
        state.msg.duration = action.payload.duration
          ? action.payload.duration
          : initialState.msg.duration;
        state.msg.key = action.payload.key;
      })
      .addCase(resetMessage, (state, action) => {
        state.msg.show = initialState.msg.show;
        state.msg.type = initialState.msg.type;
        state.msg.content = initialState.msg.content;
        state.msg.duration = initialState.msg.duration;
        state.msg.key = initialState.msg.key;
      })
      .addCase(setGraph, (state, action) => {
        state.graph['@odata.context'] = action.payload['@odata.context'];
        state.graph.businessPhones = action.payload.businessPhones;
        state.graph.displayName = action.payload.displayName;
        state.graph.givenName = action.payload.givenName;
        state.graph.jobTitle = action.payload.jobTitle;
        state.graph.mail = action.payload.mail;
        state.graph.mobilePhone = action.payload.mobilePhone;
        state.graph.officeLocation = action.payload.officeLocation;
        state.graph.preferredLanguage = action.payload.preferredLanguage;
        state.graph.surname = action.payload.surname;
        state.graph.userPrincipalName = action.payload.userPrincipalName;
        state.graph.id = action.payload.id;

        setSessionStorage('graph', action.payload);
      })
      .addCase(getEmployeeProfile.pending, (state, action) => {
        state.loading += 1;
        state.profile = initialState.profile;
      })
      .addCase(getEmployeeProfile.rejected, (state, action) => {
        state.loading -= 1;
        state.profile = initialState.profile;
      })
      .addCase(getEmployeeProfile.fulfilled, (state, action) => {
        state.loading -= 1;
        state.profile = action.payload;
      });
  },
});

export const { setRouteBack, resetRouteBack } = appSlice.actions;
export const appReducer = appSlice.reducer;
