import React from "react";

import type { MenuProps as AntdMenuProps } from "antd";
import AntdDropdown, {
  DropdownProps as AntdDropdownProps,
} from "antd/es/dropdown/dropdown";
import { ItemType as AntdItemType } from "antd/es/menu/interface";
import "./Dropdown.css";

export type { AntdItemType, AntdMenuProps };

export interface DropdownProps {
  menu: AntdMenuProps;
  trigger?: AntdDropdownProps["trigger"];
  onOpenChange?: AntdDropdownProps["onOpenChange"];
  open?: boolean;
  destroyPopupOnHide?: boolean;
  dropdownRender?: (originNode: React.ReactNode) => React.ReactNode;
  rootClassName?: string;
  className?: string;
  children?: React.ReactNode;
  placement?: AntdDropdownProps["placement"];
}

export const Dropdown: React.FC<DropdownProps> = ({
  menu,
  trigger = ["hover"],
  onOpenChange,
  open,
  destroyPopupOnHide = true,
  dropdownRender,
  rootClassName,
  className,
  children,
  placement = "bottomLeft",
}) => {
  return (
    <AntdDropdown
      trigger={trigger}
      onOpenChange={onOpenChange}
      open={open}
      destroyPopupOnHide={destroyPopupOnHide}
      dropdownRender={dropdownRender}
      rootClassName={rootClassName}
      className={className}
      menu={menu}
      placement={placement}
    >
      {children}
    </AntdDropdown>
  );
};
