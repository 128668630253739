import React, { useEffect } from 'react';

import {
  Tour as AntdTour,
  TourProps as AntdTourProps,
  TourStepProps as AntdTourStepProps,
} from 'antd';
import { ReactComponent as LeftIcon } from '../../assets/left.svg';
import { ReactComponent as RightIcon } from '../../assets/right.svg';
import { SVGIcon, SVGIconProps } from '../../components/SVG-Icon';
import './Tour.css';

export interface TourProps {
  open: AntdTourProps['open'];
  steps: AntdTourProps['steps'];
  type?: AntdTourProps['type'];
  onClose?: AntdTourProps['onClose'];
  onFinish?: AntdTourProps['onFinish'];
  mask?: AntdTourProps['mask'];
  gap?: AntdTourProps['gap'];
  indicatorsRender?: AntdTourProps['indicatorsRender'];
  arrow?: AntdTourProps['arrow'];
  placement?: AntdTourProps['placement'];
  getPopupContainer?: AntdTourProps['getPopupContainer'];
  closeIcon?: AntdTourProps['closeIcon'];
  closable?: AntdTourProps['closable'];
  onChange?: (current: number) => void;
  prevButtonLabel?: string;
  nextButtonLabel?: string;
  nextButtonLastLabel?: string;
  prevButtonIcon?: SVGIconProps;
  nextButtonIcon?: SVGIconProps;
}

export const Tour: React.FC<TourProps> = ({
  open = false,
  steps = [],
  type = 'primary',
  onClose = () => {},
  onFinish = () => {},
  mask = true,
  gap = { radius: 8 },
  indicatorsRender,
  arrow = {
    pointAtCenter: false,
  },
  placement,
  getPopupContainer,
  closeIcon,
  closable = false,
  onChange,
  prevButtonLabel = 'Previous',
  nextButtonLabel = 'Next',
  nextButtonLastLabel = 'Finish',
  prevButtonIcon = {
    SVGElementIcon: LeftIcon,
    width: 20,
    height: 20,
    style: { stroke: '#ffffff' },
  },
  nextButtonIcon = {
    SVGElementIcon: RightIcon,
    width: 20,
    height: 20,
    style: { stroke: '#ffffff' },
  },
}) => {
  const [newSteps, setNewSteps] = React.useState<AntdTourStepProps[]>([]);
  useEffect(() => {
    setNewSteps(
      steps.map((step, index) => {
        return {
          ...step,
          prevButtonProps: {
            children:
              index === 0 ? undefined : (
                <div className='tour-preview-button'>
                  <SVGIcon {...prevButtonIcon} />
                  <p>{prevButtonLabel}</p>
                </div>
              ),
          },
          nextButtonProps: {
            children: (
              <div className='tour-next-button'>
                <p>
                  {index === steps.length - 1 && nextButtonLastLabel
                    ? nextButtonLastLabel
                    : nextButtonLabel}
                </p>
                <SVGIcon {...nextButtonIcon} />
              </div>
            ),
          },
        };
      })
    );
  }, [
    steps,
    nextButtonIcon,
    nextButtonLabel,
    nextButtonLastLabel,
    prevButtonIcon,
    prevButtonLabel,
  ]);
  return (
    <AntdTour
      open={open}
      steps={newSteps}
      onClose={onClose}
      onFinish={onFinish}
      type={type}
      mask={mask}
      gap={gap}
      indicatorsRender={indicatorsRender}
      arrow={arrow}
      placement={placement}
      getPopupContainer={getPopupContainer}
      closeIcon={closeIcon}
      closable={closable}
      onChange={onChange}
    />
  );
};
