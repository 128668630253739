import React, { useEffect, useState } from 'react';

import {
  AntdTableProps,
  defaultPagination,
  Table,
  TableParams,
} from '../../../../lib/Table';
import { ICompetencies, ICompetencyTable } from '../../../../views/interfaces';
import './Competency-Table.css';

interface CompetenciesTableProps {
  data: ICompetencies[];
}
export const CompetencyTable: React.FC<CompetenciesTableProps> = ({ data }) => {
  const [tableData, setTableData] = useState<ICompetencies[]>([]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: defaultPagination,
  });
  const columns: AntdTableProps<ICompetencyTable>['columns'] = [
    {
      key: '1',
      title: 'Competency Category',
      dataIndex: 'competency_category',
    },
    {
      key: '2',
      title: 'Competency Name',
      dataIndex: 'competency_name',
    },
    {
      key: '3',
      title: 'Proficiency Level',
      dataIndex: 'proficiency_level',
    },
  ];

  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <Table
      dataSource={tableData}
      columns={columns}
      tableParams={tableParams}
      setTableParams={setTableParams}
      totalCount={data?.length}
      showPagination={false}
    />
  );
};
