import React, { useState } from 'react';

import dayjs from 'dayjs';
import {
  AntdTableProps,
  Table,
  TableParams,
  defaultPagination,
} from '../../../../lib/Table';
import { Tag } from '../../../../lib/Tag';
import { Tooltip } from '../../../../lib/Tooltip';
import { DateFormat_DDMMMYYYY } from '../../../../views/constants';
import { IExternalFulfillmentTable } from '../../../../views/interfaces';
import './External-Fulfillment.css';

export interface ExternalFulfillmentProps {
  data: IExternalFulfillmentTable[];
}

export const ExternalFulfillment: React.FC<ExternalFulfillmentProps> = ({
  data,
}) => {
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: defaultPagination,
  });

  const tableColumns: AntdTableProps<IExternalFulfillmentTable>['columns'] = [
    {
      title: 'Candidate Name',
      dataIndex: 'candidateName',
      key: 'candidateName',
      sorter: false,
      width: '25%',
      render: (candidateName) => (
        <Tooltip
          title={candidateName}
          placement='bottomRight'
          arrow={{ pointAtCenter: true }}
        >
          <span>{candidateName}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Candidate Status',
      dataIndex: 'stage',
      key: 'stage',
      sorter: false,
      width: '25%',
      render: (candidateStatus) => (
        <Tag
          color='#ECFDF3'
          textColor='#027A48'
          style={{ padding: '2px 8px 2px 6px' }}
        >
          <span>{candidateStatus}</span>
        </Tag>
      ),
    },
    {
      title: 'TA SPOC',
      dataIndex: 'recruiterEmailId',
      key: 'recruiterEmailId',
      sorter: false,
      width: '35%',
      render: (taSpoc: string[]) => (
        <span style={{ fontWeight: 600 }}>
          {!taSpoc || taSpoc.length == 0 ? (
            '-'
          ) : taSpoc.length == 1 ? (
            taSpoc[0]
          ) : (
            <>
              {taSpoc[0]}{' '}
              <Tooltip
                title={taSpoc.slice(1, taSpoc.length - 1).join(', ')}
                placement='bottomRight'
              >
                <span
                  style={{
                    fontWeight: 600,
                    marginLeft: '4px',
                    padding: '2px 8px',
                    background: '#FFEBE3',
                    borderRadius: '4px',
                    color: '#fe5000',
                  }}
                >
                  +{taSpoc.length - 1}
                </span>
              </Tooltip>
            </>
          )}
        </span>
      ),
    },
    {
      title: 'Expected DOJ',
      dataIndex: 'expectedDoj',
      key: 'expectedDoj',
      sorter: false,
      width: '15%',
      render: (expectedDoj) => {
        return (
          <span>
            {expectedDoj
              ? dayjs(expectedDoj).format(DateFormat_DDMMMYYYY)
              : '-'}
          </span>
        );
      },
    },
  ];

  return (
    <div className='external-fulfillment-table'>
      <Table
        columns={tableColumns}
        dataSource={data}
        tableParams={tableParams}
        setTableParams={setTableParams}
        totalCount={data.length}
        showPagination={false}
      />
    </div>
  );
};
