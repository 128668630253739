import { useEffect } from 'react';
import { TOKEN_REFRESH_TIME } from '../environment';
import { fetchToken } from '../utils/fetch-token';

const useTokenRefresh = () => {
  useEffect(() => {
    const refreshTokenInterval = setInterval(async () => {
      console.log('Refreshing token...');
      const token = await fetchToken();
      if (token) {
        console.log('Token refreshed.');
        // Optional: save the token to your state management
      }
    }, TOKEN_REFRESH_TIME); // Refresh every 45 minutes (2700000)

    return () => clearInterval(refreshTokenInterval); // Clean up on unmount
  }, []);
};

export default useTokenRefresh;
