import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_KEY || "");
const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_IV || "");

/**
 * Encrypts a given string using AES-256-CBC algorithm.
 * @param {string} text - The text to encrypt.
 * @returns {string} - The encrypted string.
 */
export const encrypt = (text: string) => {
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return `${encrypted.ciphertext.toString(CryptoJS.enc.Hex)}:${iv.toString()}`;
};

/**
 * Decrypts a given string using AES-256-CBC algorithm.
 * @param {string} encryptedText - The text to decrypt.
 * @returns {string} - The decrypted string.
 */
export const decrypt = (encryptedText: string) => {
  const [encryptedHex, ivHex] = encryptedText.split(":");
  const encrypted = CryptoJS.enc.Hex.parse(encryptedHex);
  const encryptedBase64 = CryptoJS.enc.Base64.stringify(encrypted);

  const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key, {
    iv: CryptoJS.enc.Hex.parse(ivHex),
    mode: CryptoJS.mode.CBC,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

/**
 * Recursively processes fields for encryption or decryption.
 * @param {any} data - The data to process (object or array).
 * @param {string[]} fields - The fields to encrypt or decrypt.
 * @param {'encrypt' | 'decrypt'} operation - Operation to process the field (encryption or decryption).
 * @returns {any} - Processed data.
 */
export const processCryptoFields = (
  data: any,
  fields: string[],
  operation: "encrypt" | "decrypt"
): any => {
  if (Array.isArray(data)) {
    return data.map((item) => processCryptoFields(item, fields, operation));
  }

  if (typeof data === "object" && data && Object.keys(data).length > 0) {
    const processedData = { ...data };
    Object.keys(processedData).forEach((key) => {
      if (fields.includes(key) && typeof processedData[key] === "string") {
        processedData[key] =
          operation === "encrypt"
            ? encrypt(processedData[key])
            : decrypt(processedData[key]);
      } else if (typeof processedData[key] === "object") {
        processedData[key] = processCryptoFields(
          processedData[key],
          fields,
          operation
        );
      }
    });
    return processedData;
  }

  return data; // If not an object or array, return as is (skip other types)
};
