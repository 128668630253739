import React, { Dispatch, Fragment } from "react";

import {
  type TableProps as AntdTableProps,
  type GetProp,
  type PaginationProps,
} from "antd";
import AntdTable from "antd/es/table";
import {
  SortOrder as AntdSortOrder,
  SorterResult as AntdSorterResult,
} from "antd/es/table/interface";
import { ReactComponent as ArrowDownIconComponent } from "../../assets/arrow-down.svg";
import { ReactComponent as ArrowUpIconComponent } from "../../assets/arrow-up.svg";
import { ReactComponent as DownIconComponent } from "../../assets/down.svg";
import { ReactComponent as UpIconComponent } from "../../assets/up.svg";
import { SVGIcon } from "../../components";
import { isObjectEmpty } from "../../utils";
import "./Table.css";

export type { AntdSortOrder, AntdSorterResult, AntdTableProps };
export type SizeType = AntdTableProps["size"];
export type ColumnsType<T extends object> = GetProp<
  AntdTableProps<T>,
  "columns"
>;
export type TablePaginationConfig = Exclude<
  GetProp<AntdTableProps, "pagination">,
  boolean
>;

export interface TableParams {
  pagination?: TablePaginationConfig;
  sortOrder?: AntdSortOrder;
  pageSizeChange?: boolean;
}

export const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
};

const ShowTotal: PaginationProps["showTotal"] = (
  total: number,
  range: [number, number]
) => (
  <p>
    Showing{" "}
    <span style={{ color: "#FE5000" }}>
      {range[0]} - {range[1]}
    </span>{" "}
    of {total} items
  </p>
);

const CustomSortIcon = ({ sortOrder }: any) => (
  <SVGIcon
    SVGElementIcon={
      sortOrder == "ascend"
        ? ArrowDownIconComponent
        : sortOrder == "descend"
        ? ArrowUpIconComponent
        : ArrowDownIconComponent
    }
    width={16}
    height={16}
    color="#929497"
    colorActive="#fe5000"
    active={["ascend", "descend"].includes(sortOrder)}
  />
);

const CustomExpandIcon = ({ expanded, record, onClick }: any) => (
  <Fragment>
    {typeof record.children == "undefined" ||
    record.children.length == 0 ? undefined : (
      <SVGIcon
        SVGElementIcon={expanded ? UpIconComponent : DownIconComponent}
        color="#404041"
        onClick={onClick}
      />
    )}
  </Fragment>
);

export interface TableProps {
  columns: ColumnsType<any>;
  dataSource: any[];
  tableParams: TableParams;
  setTableParams: Dispatch<React.SetStateAction<TableParams>>;
  size?: SizeType;
  loading?: boolean;
  enableCheckbox?: boolean;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: Dispatch<React.SetStateAction<React.Key[]>>;
  serverSidePagination?: boolean;
  totalCount: number;
  pageSizeOptions?: number[];
  hideOnSinglePage?: boolean;
  showSorterTooltip?: boolean;
  expand?: boolean;
  scroll?: AntdTableProps["scroll"];
  showPagination?: boolean;
}

export const Table: React.FC<TableProps> = ({
  columns = [],
  dataSource = [],
  tableParams = {
    pagination: defaultPagination,
    sortOrder: "ascend",
  } as TableParams,
  setTableParams,
  enableCheckbox = false,
  selectedRowKeys = [],
  setSelectedRowKeys,
  size = "small",
  serverSidePagination = true,
  loading = false,
  totalCount,
  pageSizeOptions = [10, 25, 50, 100],
  hideOnSinglePage = false,
  showSorterTooltip = false,
  expand = false,
  scroll = undefined,
  showPagination = true,
}) => {
  columns = columns.map((item) => {
    return {
      ...item,
      sorter: item.sorter == undefined ? false : item.sorter,
      defaultSortOrder: "ascend",
      sortOrder: tableParams.sortOrder,
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
      sortIcon: ({ sortOrder }: any) => (
        <CustomSortIcon sortOrder={sortOrder} />
      ),
    };
  });

  if (expand) {
    columns.push((AntdTable.EXPAND_COLUMN = { sorter: false, width: 24 }));
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (setSelectedRowKeys) {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: !record?.bulkAction.hasAccess,
    }),
  };

  const handleTableChange: AntdTableProps["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortOrder = Array.isArray(sorter)
      ? sorter[0]?.order ?? "ascend"
      : sorter?.order ?? "ascend";
    if (
      pagination.current !== tableParams.pagination?.current ||
      pagination.pageSize !== tableParams.pagination?.pageSize ||
      !isObjectEmpty(sorter)
    ) {
      if (serverSidePagination) {
        setTableParams({
          pagination,
          sortOrder: sortOrder,
          pageSizeChange:
            pagination.pageSize !== tableParams.pagination?.pageSize,
        });
      }

      if (setSelectedRowKeys) {
        setSelectedRowKeys([]);
      }
    }
  };

  return (
    <div className="custom-table">
      <AntdTable
        rowSelection={enableCheckbox ? rowSelection : undefined}
        rowKey={(record) => record.key}
        columns={columns}
        dataSource={dataSource}
        size={size}
        pagination={
          showPagination
            ? {
                ...tableParams.pagination,
                size: "small",
                hideOnSinglePage: hideOnSinglePage,
                pageSizeOptions: pageSizeOptions,
                showSizeChanger: true,
                total: totalCount,
                showLessItems: true,
                showTotal: ShowTotal,
              }
            : false
        }
        loading={loading}
        onChange={handleTableChange}
        showSorterTooltip={showSorterTooltip}
        expandable={
          expand
            ? {
                expandIcon: ({ expanded, record, onExpand }) => (
                  <CustomExpandIcon
                    expanded={expanded}
                    record={record}
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                      onExpand(record, e)
                    }
                  />
                ),
                expandIconColumnIndex: columns.length - 1,
              }
            : undefined
        }
        scroll={scroll}
      />
    </div>
  );
};
