import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ReactComponent as TalkToExpertIcon } from '../../../../assets/chat-arrow.svg';
import { ReactComponent as ContactExpertHover } from '../../../../assets/contact-expert-hover.svg';
import { ReactComponent as ContactExpert } from '../../../../assets/contact-expert.svg';
import { ReactComponent as MicrosoftTeamsLogoComponent } from '../../../../assets/microsoft-teams-logo.svg';
import talkToExpert from '../../../../assets/tolk-to-expert.gif';
import { SVGIcon } from '../../../../components';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { Avatar, Badge, Tooltip } from '../../../../lib';
import { IPracticeExpert } from '../../../../views/interfaces';
import './Practice-Expert.css';

export interface PracticeExpertProps {}

export const PracticeExpert: React.FC<PracticeExpertProps> = () => {
  const [hoverIcon, setHoverIcon] = useState<number | null>(null);
  const [expertList, setExpertList] = useState<IPracticeExpert[]>([]);
  const expertButtonRef = useRef<HTMLDivElement>(null);
  const clickCountRef = useRef<number>(0);
  const [showGif, setShowGif] = useState<boolean>(true);

  const { practiceTitle, practiceGroup, careerLadder } = useAppSelector(
    (state) => state.careerPath
  );

  useEffect(() => {
    if (practiceGroup.length > 0 && practiceTitle) {
      const activePractice = practiceGroup
        .flatMap((group) =>
          Array.isArray(group.practices) ? group.practices : []
        )
        .find((practice) => practice.name === practiceTitle);
      if (activePractice) {
        setExpertList(activePractice.experts);
      }
    }
  }, [practiceGroup, practiceTitle]);

  const openTeams = useCallback((email: string) => {
    const teamsLink = `https://teams.microsoft.com/l/chat/0/0?users=${email}`;
    window.open(teamsLink, '_blank');
  }, []);

  useEffect(() => {
    if (expertList.length > 0 && clickCountRef.current < 2) {
      const firstTimeout = setTimeout(() => {
        if (expertButtonRef.current) {
          expertButtonRef.current.click();
          clickCountRef.current += 1;
        }
        if (clickCountRef.current < 2) {
          const secondTimeout = setTimeout(() => {
            if (expertButtonRef.current) {
              expertButtonRef.current.click();
              clickCountRef.current += 1;
              setShowGif(false);
            }
          }, 10000); // 10 seconds in milliseconds
          return () => clearTimeout(secondTimeout);
        }
      }, 10000); // 10 seconds in milliseconds
      return () => clearTimeout(firstTimeout);
    }
  }, [expertList]);

  return (
    <Fragment>
      {expertList.length > 0 && careerLadder.nodeList.length > 0 && (
        <Tooltip
          open={true}
          placement='leftBottom'
          arrow={true}
          title={
            <div className='chat-with-expert'>
              <p>Want to know more? Talk to the {practiceTitle} Expert</p>
              <div className='chat-with-expert-list-container'>
                {expertList.map((expert, index) => {
                  return (
                    <div
                      className='chat-with-expert-list'
                      key={index.toString()}
                    >
                      <div className='hr-img'>
                        <Badge
                          count={
                            <SVGIcon
                              SVGElementIcon={MicrosoftTeamsLogoComponent}
                              color=''
                              rootStyle={{
                                color: '#ffffff',
                                position: 'absolute',
                                left: '16px',
                                bottom: '-12px',
                              }}
                            />
                          }
                          style={{ position: 'relative' }}
                        >
                          <Avatar
                            size={'large'}
                            icon={
                              expert.src ? (
                                <img src={expert.src} alt='No Icon' />
                              ) : undefined
                            }
                            style={{
                              background:
                                'linear-gradient(106.82deg, #983000 0.59%, #fe5000 84.81%)',
                              color: '#ffffff',
                            }}
                          >
                            {expert.name
                              .split(' ')
                              .filter(
                                (_, index, arr) =>
                                  index === 0 || index === arr.length - 1
                              )
                              .map((name) => name.trim().charAt(0))
                              .join('')}
                          </Avatar>
                        </Badge>
                      </div>
                      <div className='hr-name-role-chat'>
                        <div className='hr-name-role'>
                          <p className='hr-name'>{expert.name}</p>
                          <Tooltip title={expert.role}>
                            <p className='hr-role'>{expert.role}</p>
                          </Tooltip>
                        </div>
                        <div
                          className='hr-chat'
                          onMouseEnter={() => {
                            setHoverIcon(index);
                          }}
                          onMouseLeave={() => {
                            setHoverIcon(null);
                          }}
                        >
                          <SVGIcon
                            SVGElementIcon={
                              hoverIcon === index
                                ? ContactExpertHover
                                : ContactExpert
                            }
                            color='#000000'
                            colorActive='#fe5000'
                            style={{ stroke: 'transparent' }}
                            onClick={() => openTeams(expert.email)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }
          trigger={['click', 'hover']}
          rootClassName='chat-with-expert-tooltip'
        >
          <span className='chat-with-expert-float-button' ref={expertButtonRef}>
            <Avatar
              icon={
                showGif ? (
                  <img src={talkToExpert} alt='No Icon' height={30} />
                ) : (
                  <SVGIcon
                    SVGElementIcon={TalkToExpertIcon}
                    color='#fe5000'
                    style={{ fill: '#fe5000', stroke: 'transparent' }}
                    height={18}
                    width={18}
                  />
                )
              }
              size={40}
              style={{
                backgroundColor: '#ffffff',
                boxShadow:
                  '2px -2px 4px 0px #4040411a, -2px 2px 4px 0px #4040411a',
              }}
            />
          </span>
        </Tooltip>
      )}
    </Fragment>
  );
};
