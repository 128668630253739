export enum RouterUrl {
  dashboard = 'dashboard',
  openDemands = 'open-demand',
  draftDemands = 'draft-demand',
  allEmployees = 'all-employees',
  projectsOpportunities = 'projects-opportunities',
  reports = 'reports',
  careerCompass = 'career-compass',
  careerPath = 'explore-career-paths',
  skillProfile = 'skill-profile',
  competencyDevelopment = 'enhance-your-skills',
  internalMobility = 'internal-mobility',
}

export enum EModuleCode {
  M_DASHBOARD = 'M_DASHBOARD',
  M_OPEN_DEMANDS = 'M_OPEN_DEMANDS',
  M_DRAFT_DEMANDS = 'M_DRAFT_DEMANDS',
  M_EMPLOYEE = 'M_EMPLOYEE',
  M_REPORTS = 'M_REPORTS',
  M_CREATE_DEMAND = 'M_CREATE_DEMAND',
  M_OPPORTUNITY_PROJECT = 'M_OPPORTUNITY_PROJECT',
  M_CAREER_COMPASS = 'M_CAREER_COMPASS',
  M_LOGOUT = 'M_LOGOUT',
}

export enum ReduxApiService {
  getCardData = 'getCardData',
  getFilterData = 'getFilterData',
  getDraftFilterData = 'getDraftFilterData',
  getDraftTableData = 'getDraftTableData',
  exportDraftTableData = 'exportDraftTableData',
  getOpenFilterData = 'getOpenFilterData',
  getOpenTableData = 'getOpenTableData',
  exportOpenTableData = 'exportOpenTableData',
  getEmployeeFilterData = 'getEmployeeFilterData',
  getEmployeeTableData = 'getEmployeeTableData',
  exportEmployeeTableData = 'exportEmployeeTableData',
  bulkFulfillmentUpdate = 'bulkFulfillmentUpdate',
  getDemandCreationData = 'getDemandCreationData',
  saveSubmitDemandCreationForm = 'saveSubmitDemandCreationForm',
  cancelDemandForm = 'cancelDemandForm',
  convertDemandForm = 'convertDemandForm',
  getDemandHistory = 'getDemandHistory',
  getEmployeeForProposal = 'getEmployeeForProposal',
  proposeSelectedEmployee = 'proposeSelectedEmployee',
  getActiveProposals = 'getActiveProposals',
  getRejectionReasons = 'getRejectionReasons',
  getCancellationReasons = 'getCancellationReasons',
  updateActiveProposalStatus = 'updateActiveProposalStatus',
  getProposalHistory = 'getProposalHistory',
  getExternalFulfillment = 'getExternalFulfillment',
  getUiFilterItems = 'getUiFilterItems',
  demandApproveOrReject = 'demandApproveOrReject',
  openDemandReport = 'openDemandReport',
  createJdUsingAI = 'createJdUsingAI',
  getRmgReasons = 'getRmgReasons',
  closeByRmg = 'closeByRmg',
  getEmployeeProfile = 'getEmployeeProfile',
  sendEmail = 'sendEmail',
  onHoldOrRelease = 'onHoldOrRelease',
  getDynamicPageContents = 'getDynamicPageContents',
  getCareerCompassEmployeeData = 'getCareerCompassEmployeeData',
  getCareerCompassPracticeList = 'getCareerCompassPracticeList',
  getCareerCompassHrList = 'getCareerCompassHrList',
  getCareerCompassSuccessStories = 'getCareerCompassSuccessStories',
  getCareerCompassCareerLadder = 'getCareerCompassCareerLadder',
  getCareerCompassStretchRole = 'getCareerCompassStretchRole',
  getCareerCompassRoleDetails = 'getCareerCompassRoleDetails',
  getCareerCompassProfileSummary = 'getCareerCompassProfileSummary',
  updateEmployeeUserPreference = 'updateEmployeeUserPreference',
  getLmsCourses = 'getLmsCourses',
}

export enum SortByColumn {
  level = 'level',
  practice = 'practice_name',
  location = 'location_name',
  startDate = 'start_date',
  vertical = 'vertical_name',
  demandStatus = 'Demand Status',
  supplyRisk = 'Supply Risk',
  specialization = 'Specialization',
  ageing = 'Ageing',
}

export enum Filters {
  account = 'Account',
  projectOpportunity = 'Project/Opportunity',
  practice = 'Practice',
  location = 'Location',
  vertical = 'Vertical / Sub-Vertical',
  demandStatus = 'Demand Status',
  supplyRisk = 'Supply Risk',
  specialization = 'Specialization',
  legalEntity = 'Legal Entity',
  level = 'Level',
  role = 'Role',
  positionStartDate = 'Position Start Date',
  positionEndDate = 'Position End Date',
}

export enum EFulFillmentStatus {
  Internal = 'Internal',
  External = 'External',
  No_Staffing = 'No Staffing',
  Not_Acted = 'Not Acted',
}

export enum EFulFillmentAction {
  withdraw = 'withdraw',
  move = 'move',
  select = 'select',
  reject = 'reject',
  propose = 'propose',
}

export enum EProposalStatus {
  MoveToHMInterview = 'Move to HM Interview',
  WithdrawProposal = 'Withdraw Proposal',
  MoveToClientInterview = 'Move to Client Interview',
  Rejected = 'Rejected',
  RequestForRIW = 'Request for RIW',
  MoveToRIW = 'Move to RIW',
}

export enum EDemandStatus {
  closed = 'closed',
  open = 'open',
  cancelled = 'cancelled',
  draft = 'draft',
}

export enum EDemandSubStatusInternal {
  No_Proposal = 'No Proposal',
  HM_Screening = 'HM Screening',
  HM_Interview = 'HM Interview',
  Client_interview = 'Client Interview',
  Pending_RIW = 'Pending RIW',
  Internally_Identified = 'Internally Identified',
  On_Hold = 'On Hold',
}

export enum EDemandSubStatusExternal {
  Pending_Approval = 'Pending Approval',
  Applied = 'Applied',
  Shortlisted = 'Shortlisted',
  Interview_1 = 'Interview 1',
  Interview_2 = 'Interview 2',
  Interview_3 = 'Interview 3',
  Interview_4 = 'Interview 4',
  Interview_5 = 'Interview 5',
  Interview_6 = 'Interview 6',
  HR_Stage = 'HR Stage',
  Hired = 'Hired',
  On_Hold = 'On Hold',
  Pre_Offer = 'Pre-Offer',
  Offer_Released = 'Offer Released',
  Offer_Accepted = 'Offer Accepted',
  Pre_Boarding = 'Pre-Boarding',
  Ready_to_hire = 'Ready-to-hire',
  Job_Created = 'Job Created',
}

export enum EEmployementEmployeeCampus {
  employee = 'employee',
  campus = 'campus',
  intern = 'intern',
  contractor = 'contractor',
}
export enum ProposalStatus {
  MoveToHMInterview = 'Move to HM Interview',
  WithdrawProposal = 'Withdraw Proposal',
  MoveToClientInterview = 'Move to Client Interview',
  Rejected = 'Rejected',
  RequestForRIW = 'Request for RIW',
  MoveToRIW = 'Move to RIW',
}

export enum EDatePickerType {
  SpecificDate = 'Specific Date',
  DateRange = 'Date Range',
}

export enum ECloseDemandType {
  rmg = 'rmg',
  auto = 'auto',
}

export enum EDashboardFeatureCode {
  NOT_ACTED_CARD = 'f-not-acted-demand-card',
  NOT_ACTED_OVERDUE_CNT = 'f-not-acted-overdue-cnt',
  NOT_ACTED_STARTS_IN_30_CNT = 'f-demand-starts-in-30-cnt',
  NOT_ACTED_TOTAL_DRAFT_CNT = 'f-total-draft-demand-cnt',
  NOT_ACTED_PRJ_DEMAND_CNT = 'f-not-acted-prj-demand-cnt',
  NOT_ACTED_OPP_DEMAND_CNT = 'f-not-acted-opp-demand-cnt',
  OPEN_DEMANDS_CARD = 'f-open-demand-card',
  OPEN_DEMANDS_RIW_CNT = 'f-open-riw-cnt',
  OPEN_DEMANDS_INTERNAL_CNT = 'f-open-internal-cnt',
  OPEN_DEMANDS_EXTERNAL_CNT = 'f-open-external-cnt',
  OPEN_DEMANDS_NO_STAFFING_CNT = 'f-no-staffing-cnt',
  OPEN_DEMANDS_TO_BE_APPROVED_CNT = 'f-to-be-approved-cnt',
  OPEN_DEMANDS_SUPPLY_RISK_CNT = 'f-supply-risk-cnt',
  OPEN_DEMANDS_NOT_ACTED_CNT = 'f-open-not-acted-cnt',
  BENCH_CARD = 'f-bench-card',
  BENCH_ONSITE_CNT = 'f-bench-onsite-cnt',
  BENCH_OFFSHORE_CNT = 'f-bench-offshore-cnt',
  BENCH_STAFFING_PLANNED_CNT = 'f-bench-staffing-planned-cnt',
  ALLOC_ENDS_IN_60_CARD = 'f-allocation-ends-in-60',
  ALLOC_ENDS_IN_60_ONSITE_CNT = 'f-onsite-allocation-cnt',
  ALLOC_ENDS_IN_60_OFFSHORE_CNT = 'f-offshore-allocation-cnt',
  ALLOC_ENDS_IN_60_PARTIAL_CNT = 'f-partial-allocation-cnt',
  DRAFT_DEMANDS_CARD = 'f-draft-demand-card',
  DRAFT_DEMANDS_OVERDUE_CNT = 'f-overdue-draft-demand-cnt',
  DRAFT_DEMANDS_STARTS_IN_30_CNT = 'f-draft-starts-in-30-cnt',
  INTERNAL_CARD = 'f-internal-actions-card',
  INTERNAL_REVIEW_PROPOSAL_CNT = 'f-review-proposal-cnt',
  INTERNAL_HM_INTERVIEW_CNT = 'f-hm-interview-cnt',
  INTERNAL_CLIENT_INTERVIEW_CNT = 'f-client-interview-cnt',
  PORTFOLIO_CARD = 'f-my-portfolio-card',
  PORTFOLIO_PROJECT_CNT = 'f-portfolio-prj-demand-cnt',
  PORTFOLIO_OPPORTUNITY_CNT = 'f-portfolio-opp-demand-cnt',
  PORTFOLIO_ALLOC_ENDS_IN_60_CNT = 'f-portfolio-alloc-ends-in-60-cnt',
}
export enum EDashBoardModule {
  DASHBOARD = 'M_DASHBOARD',
  OPEN_DEMANDS = 'M_OPEN_DEMANDS',
  DRAFT_DEMANDS = 'M_DRAFT_DEMANDS',
  ALL_EMPLOYEES = 'M_ALL_EMPLOYEES',
  PROJECTS_OPPORTUNITIES = 'M_OPP_PRJ',
  REPORTS = 'M_REPORTS',
  NOT_ACTED = 'M_NOT_ACTED',
}

export enum EPermissionType {
  Write = 'write',
  Read = 'read',
  Not_Permitted = '',
}

//Need to extend after mvp1
export enum EFilterCode {
  DASHBOARD_VERTICAL = 'DASH_VERT',
  DASHBOARD_PRACTICE = 'DASH_PRC',
  DASHBOARD_ACCOUNT = 'DASH_ACC',
  DASHBOARD_SUBVERTICAL = 'DASH_SUB_VERT',
}

export enum DemandFeatureCode {
  createDemand = 'f-create-open-demand',
  bulkActionApprove = 'f-bulk-approve-action',
  bulkActionReject = 'f-bulk-reject-action',
  bulkActionInternal = 'f-bulk-action-internal',
  bulkActionExternal = 'f-bulk-action-external',
  bulkActionNoStaffing = 'f-bulk-action-no-staffing',
  downloadOpenDemandReport = 'f-open-demand-report-download',
  cancelDemand = 'f-cancel-demand',
  closeDemandByRmg = 'f-close-demand-by-rmg',
  editDemand = 'f-edit-save-demand',
  holdDemand = 'f-hold-demand',
  releaseHoldDemand = 'f-release-hold-demand',
  rejectDemand = 'f-reject-demand',
  approveDemand = 'f-approve-demand',
}

export enum EFlowTypes {
  STRETCH_ROLES = 'Lateral Path',
  CARRER_LADDER = 'Career Ladder',
}

export enum EPreference {
  FIRST_LOGIN_COMPLETED = 'FIRST_LOGIN_COMPLETED',
  UI_GUIDE_COMPLETED = 'UI_GUIDE_COMPLETED',
}
