import { ApiRoutesConfig, axiosCareerCompassInstance } from '.';
import { IDynamicPageContent } from '../views/interfaces';

export const getCareerCompassEmployeeDataService = async () => {
  try {
    const response = await axiosCareerCompassInstance.get(
      ApiRoutesConfig.getCareerCompassEmployeeData
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getDynamicPageContentsService = async () => {
  try {
    const response = await axiosCareerCompassInstance.get(
      ApiRoutesConfig.getDynamicPageContents
    );
    const parser = new DOMParser();
    return response.data.data.map((item: IDynamicPageContent) => {
      return {
        ...item,
        page_content: parser.parseFromString(item.page_content, 'text/html')
          .body.innerHTML,
      };
    });
  } catch (error) {
    throw error;
  }
};

export const getCareerCompassPracticeListService = async () => {
  try {
    const response = await axiosCareerCompassInstance.get(
      ApiRoutesConfig.getCareerCompassPracticeList
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getCareerCompassSuccessStoriesService = async () => {
  try {
    const response = await axiosCareerCompassInstance.get(
      ApiRoutesConfig.getCareerCompassSuccessStories
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getCareerCompassCareerLadderService = async (params: any) => {
  try {
    const response = await axiosCareerCompassInstance.get(
      ApiRoutesConfig.getCareerCompassCareerLadder,
      {
        params,
      }
    );
    if (response.status === 204) {
      return { nodeList: [], edgeList: [] };
    }
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getCareerCompassStretchRoleService = async (params: any) => {
  try {
    const response = await axiosCareerCompassInstance.get(
      ApiRoutesConfig.getCareerCompassStretchRole,
      {
        params,
      }
    );
    if (response.status === 204) {
      return { nodeList: [], edgeList: [] };
    }
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getCareerCompassRoleDetailsService = async (body: any) => {
  try {
    const response = await axiosCareerCompassInstance.post(
      ApiRoutesConfig.getCareerCompassRoleDetails,
      body
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getCareerCompassProfileSummaryService = async (body: any) => {
  try {
    const response = await axiosCareerCompassInstance.put(
      ApiRoutesConfig.getCareerCompassProfileSummary,
      body
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const updateEmployeeUserPreferenceService = async (body: any) => {
  try {
    const response = await axiosCareerCompassInstance.post(
      ApiRoutesConfig.updateEmployeeUserPreference,
      body
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
