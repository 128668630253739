import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getLmsCoursesService } from '../../services/compentency-development';
import { ReduxApiService } from '../../views/enums';
import { ICompentencyDevelopment } from '../../views/interfaces';

const initialState: ICompentencyDevelopment = {
  loading: 0,
  lmsCourses: [],
};

export const getLmsCourses = createAsyncThunk(
  ReduxApiService.getLmsCourses,
  async (params: any) => {
    const result = await getLmsCoursesService(params);
    return result;
  }
);

const compentencyDevelopmentSlice = createSlice({
  name: 'career-path',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLmsCourses.pending, (state, action) => {
        state.loading += 1;
        state.lmsCourses = initialState.lmsCourses;
      })
      .addCase(getLmsCourses.rejected, (state, action) => {
        state.loading -= 1;
        state.lmsCourses = initialState.lmsCourses;
      })
      .addCase(getLmsCourses.fulfilled, (state, action) => {
        state.loading -= 1;
        state.lmsCourses = action.payload;
      });
  },
});

export const compentencyDevelopmentReducer =
  compentencyDevelopmentSlice.reducer;
