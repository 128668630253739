import React, { CSSProperties, useId } from "react";

import type { CheckboxOptionType, RadioChangeEvent } from "antd";
import {
  Radio as AntdRadio,
  RadioGroupProps as AntdRadioGroupProps,
} from "antd";
import "./Radio.css";

export interface RadioProps {
  options: CheckboxOptionType<any>[];
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  style?: CSSProperties;
  id?: string;
  defaultValue?: string;
  disabled?: boolean;
  optionType?: AntdRadioGroupProps["optionType"];
  buttonStyle?: AntdRadioGroupProps["buttonStyle"];
  block?: boolean;
}

export const Radio: React.FC<RadioProps> = ({
  options,
  value,
  onChange = (value) => {},
  className,
  style,
  id,
  defaultValue,
  disabled = false,
  optionType = "default",
  buttonStyle = "solid",
  block = false,
}) => {
  const radioId = useId();
  const onSelectionChange = (e: RadioChangeEvent) => {
    onChange(e.target.value);
  };

  return (
    <AntdRadio.Group
      id={id ?? radioId}
      block={block}
      optionType={optionType}
      buttonStyle={buttonStyle}
      onChange={onSelectionChange}
      value={value}
      className={className}
      style={style}
      defaultValue={defaultValue}
      disabled={disabled}
      options={options}
    ></AntdRadio.Group>
  );
};
