import React, { Fragment, FunctionComponent } from "react";
import { useAppSelector } from "../../hooks/redux-hooks";
import { Tooltip } from "../../lib";
import { SidebarIcon } from "../Sidebar-Icon";
import "./Sidebar-Menu-Item.css";

export interface SidebarMenuItemProps {
  code: string;
  title: string;
  activeMenuCode: string;
  SVGIconElement?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  src?: string;
  isSvgIcon?: boolean;
  avatarInitials?: string;
  onClick?: (code: string) => void;
}

export const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
  code,
  title,
  activeMenuCode,
  SVGIconElement,
  src,
  isSvgIcon = true,
  avatarInitials = "",
  onClick = () => {},
}: SidebarMenuItemProps) => {
  const { sidebarCollapsed } = useAppSelector((state) => state.app);

  return (
    <div
      className={`sidebar-menu-item ${
        code == activeMenuCode ? "sidebar-menu-item-active" : ""
      } ${!sidebarCollapsed ? "sidebar-menu-item-max-width" : ""}`.trim()}
      onClick={() => onClick(code)}
    >
      <div
        className={`frame ${sidebarCollapsed ? "frame-only-icon" : ""}`.trim()}
      >
        {sidebarCollapsed ? (
          <Tooltip title={title} placement="right">
            <span>
              <SidebarIcon
                isSvgIcon={isSvgIcon}
                isActive={code == activeMenuCode}
                SVGIconElement={SVGIconElement}
                src={src}
                avatarInitials={avatarInitials}
              />
            </span>
          </Tooltip>
        ) : (
          <Fragment>
            <SidebarIcon
              isSvgIcon={isSvgIcon}
              isActive={code == activeMenuCode}
              SVGIconElement={SVGIconElement}
              src={src}
              avatarInitials={avatarInitials}
            />
            <div className="heading">
              <span className="text-wrapper-1">{title}</span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};
